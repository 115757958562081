import React from 'react'
import Pagination from '../Pagination/Pagination'
import DateRangePicker from '../DateRangePicker/DateRangePicker'
import './ListdocSignTable.css'

import { useTranslateContext } from '../../hooks';

/**
 * Ce Composant envellope les lignes d'un tableau 
 * 
 * @param {*} children 
 * @returns TableWrapper
 * 
 */
export default function TableWrapper({ onApplySearch, children, setPage, currentPage, maxPage, setFromDate, setToDate, onApplyDateFilter }) {
    const { translate } = useTranslateContext();
    return (
        <div id="kt_content_container" className="d-flex flex-column-fluid align-items-start container-xxl">
            <div className="content flex-row-fluid" id="kt_content">
                <div className="card card-flush pb-8">
                    <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                        <div className="card-title">
                            <div className="d-flex align-items-center position-relative my-1 table-filter">
                                <input type="text" onChange={(e)=> onApplySearch(e)} data-kt-ecommerce-product-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={translate.admin.rechercherUnDocument} />
                                <DateRangePicker setFromDate={setFromDate} setToDate={setToDate} onApplyDateFilter={onApplyDateFilter} />
                            </div>
                        </div>
                    </div>

                    <div className="card-body table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5" id="kt_ecommerce_products_table">
                            <thead>
                                <tr className="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
                                    <th className="w-10px pe-2">
                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                            <input className="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_ecommerce_products_table .form-check-input" value="1" />
                                        </div>
                                    </th>
                                    <th className="min-w-200px">Document</th>
                                    <th className="text-end min-w-100px">Status</th>
                                    <th className="text-end min-w-70px">Actions</th>
                                    <th className="text-end min-w-70px">{translate.admin.nom}</th>
                                    <th className="text-end min-w-70px">Date</th>
                                    <th className="text-end min-w-70px">{translate.admin.motif}</th>
                                </tr>
                            </thead>

                            <tbody className="fw-bold text-gray-600">
                                {children}
                            </tbody>
                        </table>
                    </div>
                    <Pagination setPage={setPage} currentPage={currentPage ?? 1} maxPage={maxPage <= 3 ? 3 : maxPage} />
                </div>
            </div>
        </div>
    )
}
