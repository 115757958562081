import React, { useState } from 'react';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import './style.css';
import { useTranslateContext } from '../../hooks';

export default function Modal({ showModal, setShowModal, error, type, fxt }) {
  const { translate } = useTranslateContext();
  const [checked, setChecked] = useState(false);

  return type == 'basic' ? (
    <div
      id="myModal"
      style={{ display: showModal ? 'block' : 'none' }}
      className="modal"
    >
      <div className="modal-content">
        <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>
        <button className="okbtn" onClick={() => setShowModal(false)}>
          Ok
        </button>{' '}
        <br />
      </div>
    </div>
  ) : (
    <div
      id="myModal"
      style={{ display: showModal ? 'block' : 'none' }}
      className="modal"
    >
      <div className="modal-content">
        <IoMdCloseCircleOutline
          className="top-close-icon"
          onClick={() => setShowModal(false)}
        />
        <p className="txt-condition">
          {translate.admin.enSignantDocumentAttesteAvoirLuComprisEtAcceptLes}{' '}
          <a href="#">
            {translate.admin.conditionsRelativesSignatureElectronique}
          </a>
          <input
            className="condition-checkbox"
            defaultChecked={checked}
            onChange={() => setChecked(!checked)}
            type="checkbox"
          />
        </p>
        <p className="txt-alert">{error}</p>

        <div className="btn-area">
          <button
            className={!checked ? 'okbtn unfocus' : 'okbtn'}
            disabled={!checked ? true : false}
            onClick={() => {
              setShowModal(false);
              fxt();
            }}
          >
            {translate.admin.oui}
          </button>

          <button
            className={!checked ? 'close unfocus' : 'close'}
            disabled={!checked ? true : false}
            onClick={() => setShowModal(false)}
          >
            {translate.admin.non}
          </button>
        </div>
      </div>
    </div>
  );
}
