import React, {useEffect} from 'react' ;
import './App.css';
import Routing from './routes/Routing';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import store from './store';
import { Provider } from 'react-redux';
import Pusher from 'pusher-js/with-encryption';
import { toast } from "react-toastify";

Pusher.logToConsole = true;

var pusher = new Pusher('f7b308d866cc1cc3b1ec', {
    cluster: 'eu'
});

var channel = pusher.subscribe('public');

channel.bind('info', function(data) {
  toast.success(data.message, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });      
});

const App = () => {
  
  return(
    <>
    <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <Routing />
          <div style={{ width: '290px' }} id='signature-preview_xstep'></div>
        </BrowserRouter>    
      </Provider>
    </>
  );
}

export default App ;
