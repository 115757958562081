import React, { useEffect } from 'react'
import CustomPie from '../Charts/CustomPie'
import { DATA_DOCUMENT } from '../../services/mock'
import { useDispatch, useSelector } from 'react-redux';
import { getStatistic, userSelector } from '../../services/reduxSlices/UserSlice';

import { useTranslateContext } from '../../hooks';

/**
 * 
 * Composant présent sur le dashbord 
 * Une Card présentant les informations sur le Total documents
 * @returns Card1
 * 
 */
export default function Card1() {

    const { translate } = useTranslateContext();

    const dispatch = useDispatch()

    const { stats } = useSelector(userSelector)

    useEffect(() => {
        dispatch(getStatistic())
    }, [])

    function formatChartData(data) {
        var chart_data = {
            labels: [translate.admin.refuser, translate.admin.signer, translate.admin.attente, translate.admin.encours],
            datasets: [
                {
                    label: "Pie",
                    data: [data.refuse.count, data.signe.count, data.en_attente.count, data.en_cours.count],
                    fill: true,
                    backgroundColor: [
                        '#F1416C',
                        'rgb(75, 192, 192)',
                        'rgb(237,125,49)',
                        '#009EF7'
                    ]
                }
            ],
        }

        return chart_data
    }

    let option_pie = {
        responsive: true,
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: "Total documents",
                position: "top"
            }
        }
    }

    const getTotalNb = (data) =>{
        const result = data.en_attente.count + data.en_cours.count + data.refuse.count + data.signe.count
        return result
    }
    
    return (
        stats ?
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
                <div className="card card-flush h-md-60 mb-5 mb-xl-10">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{getTotalNb(stats)}</span>
                            </div>
                            <span className="text-gray-400 pt-1 fw-bold fs-6">Total documents</span>
                        </div>
                    </div>
                    <div className="card-body pt-2 pb-4 d-flex align-items-center">
                        <div className="d-flex flex-center me-5">
                            <div id="kt_card_widget_4_chart" style={{ minWidth: '70px', minHeight: '70px' }} data-kt-size="70" data-kt-line="11">
                                <CustomPie data={formatChartData(stats)} options={option_pie} />
                            </div>
                        </div>
                        <div className="d-flex flex-column content-justify-center w-100">
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                                <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.refuse}</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end">{stats.refuse.count}</div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: 'rgba(75, 192, 192)' }}></div>
                                <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.signe}</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end">{stats.signe.count}</div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center">
                                <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: 'rgb(237,125,49)' }}></div>
                                <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.attente}</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end">{stats.en_attente.count}</div>
                            </div>
                            <div className="d-flex fs-6 fw-bold align-items-center my-3">
                                <div className="bullet w-8px h-6px rounded-2 me-3 bg-primary"></div>
                                <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.encours}</div>
                                <div className="fw-boldest text-gray-700 text-xxl-end">{stats.en_cours.count}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card card-flush h-md-50 mb-xl-10">
                    <div className="card-header pt-5">
                        <div className="card-title d-flex flex-column">
                            <div className="d-flex align-items-center">
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">News</span>
                            </div>
                            <span className="text-gray-400 pt-1 fw-bold fs-6"></span>
                        </div>
                    </div>
                    <div className="card-body d-flex align-items-end pt-0">

                    </div>
                </div>
            </div> :
             <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
             <div className="card card-flush h-md-60 mb-5 mb-xl-10">
                 <div className="card-header pt-5">
                     <div className="card-title d-flex flex-column">
                         <div className="d-flex align-items-center">
                             <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">{0}</span>
                         </div>
                         <span className="text-gray-400 pt-1 fw-bold fs-6">Total documents</span>
                     </div>
                 </div>
                 <div className="card-body pt-2 pb-4 d-flex align-items-center">
                     <div className="d-flex flex-center me-5">
                         <div id="kt_card_widget_4_chart" style={{ minWidth: '70px', minHeight: '70px' }} data-kt-size="70" data-kt-line="11">
                             <CustomPie data={DATA_DOCUMENT} options={option_pie} />
                         </div>
                     </div>
                     <div className="d-flex flex-column content-justify-center w-100">
                         <div className="d-flex fs-6 fw-bold align-items-center">
                             <div className="bullet w-8px h-6px rounded-2 bg-danger me-3"></div>
                             <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.refuse}</div>
                             <div className="fw-boldest text-gray-700 text-xxl-end">{0}</div>
                         </div>
                         <div className="d-flex fs-6 fw-bold align-items-center my-3">
                             <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: 'rgba(75, 192, 192)' }}></div>
                             <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.signe}</div>
                             <div className="fw-boldest text-gray-700 text-xxl-end">{0}</div>
                         </div>
                         <div className="d-flex fs-6 fw-bold align-items-center">
                             <div className="bullet w-8px h-6px rounded-2 me-3" style={{ backgroundColor: 'rgb(237,125,49)' }}></div>
                             <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.attente}</div>
                             <div className="fw-boldest text-gray-700 text-xxl-end">{0}</div>
                         </div>
                         <div className="d-flex fs-6 fw-bold align-items-center my-3">
                             <div className="bullet w-8px h-6px rounded-2 me-3 bg-primary"></div>
                             <div className="text-gray-500 flex-grow-1 me-4">{translate.admin.encours}</div>
                             <div className="fw-boldest text-gray-700 text-xxl-end">{0}</div>
                         </div>
                     </div>
                 </div>
             </div>
             <div className="card card-flush h-md-50 mb-xl-10">
                 <div className="card-header pt-5">
                     <div className="card-title d-flex flex-column">
                         <div className="d-flex align-items-center">
                             <span className="fs-2hx fw-bolder text-dark me-2 lh-1 ls-n2">News</span>
                         </div>
                         <span className="text-gray-400 pt-1 fw-bold fs-6"></span>
                     </div>
                 </div>
                 <div className="card-body d-flex align-items-end pt-0">

                 </div>
             </div>
         </div>
    )
}
