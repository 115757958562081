import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-toastify";
import { Document, Page, pdfjs } from 'react-pdf';
import { FaTools } from 'react-icons/fa';
import { ImQrcode } from 'react-icons/im';
import { MdDone } from 'react-icons/md';
import { BsPenFill } from 'react-icons/bs';
import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { AiFillMinusSquare } from 'react-icons/ai';
import { RiUser2Fill } from 'react-icons/ri';
import { FaRegEdit } from 'react-icons/fa';
import Modal from '../../components/Modal/Modal';
import square from '../../public/asset/img/square.png';
import qrcode from '../../public/asset/img/qr_code_.png';
import PreviewDoc from './PreviewDoc';
import { useSelector } from 'react-redux';
import { docListSelector } from '../../services/reduxSlices/DocListSlice';
import { signatureSelector } from '../../services/reduxSlices/SignatureSlice';
import LoaderDocs from '../../components/Modal/LoaderDocs/LoaderDocs';
import ApplyModal from '../../components/Modal/ApplyModal/ApplyModal';
import Cookies from 'js-cookie';
import './style.css';

import { useParamsDocument, useTranslateContext } from '../../hooks';

/**
 * Page principale pour effectuer une signature
 * @returns ViewPDF
 */
const pixelToMillimeter = (value) => value * 0.2645833333;

const formatDocument = async (document) => {

  const formData = new FormData();
  formData.append('document', document);

  const token = Cookies.get('token');

  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
  };

  const reponse = await fetch(
    'https://dkbsign.com/new_api_dkbsign/public/api/signatures/formater',
    requestOptions
  );
  if (!reponse.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await reponse.json();
  return result;
}
export default function ViewPDF() {

  const { translate, langTranslate } = useTranslateContext();
  const {
    allModalOpen,
    toggleFunction,
    setAllModalOpen,
    getMessageAlert,
    alertMsg,
    setIsAll,
    isAll
  } = useParamsDocument()

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const docList = useSelector(docListSelector);
  const signSel = useSelector(signatureSelector);

  const [loader, setLoader] = useState(false);
  var signataire = signSel.signataire;
  var urgence = signSel.urgence;

  const [pdfSize, setPdfSize] = useState({})
  const [urlDoc, setUrlDoc] = useState('');
  const [signataireName, setSignataireName] = useState(null);
  const [imageSign, setImageSign] = useState('');
  const [error, setError] = useState('');
  const [boxList, setBoxList] = useState([]);
  const [type, setType] = useState('basic');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [curNumber, setCurNumber] = useState(1);
  const [isSwicth, setIsSwitch] = useState(false);
  const [currentFile, setCurrentFile] = useState(docList[0]);
  const [DOCUMENT, SETDOCUMENT] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [imgCode, setImgCode] = useState({ uri: null, type: '', page: null });
  const [data, setData] = useState([]);
  const [isSigned, setIsSigned] = useState([]);
  const [coordSign, setCoordSign] = useState({
    x: 0,
    y: 0,
    display: 'none',
  });
  const docRef = useRef();
  const qrcodeRef = useRef();
  const boxsign = useRef();
  const signataireRef = useRef();
  const pdfRef = useRef();
  const canvasSignRef = useRef();

  useEffect(() => {
    formatDocument(currentFile)
      .then((res) => setUrlDoc(res.message))
      .catch((err) => console.log(err));
  }, [currentFile]);

  const getDocumentType = () => {
    if (pdfSize.width) {
      if (pdfSize.width > 850) return "landscape"
      else return "portrait"
    }
    return "portrait"
  }

  /** * UseEffect exécuté au moment du chargement de la page. * Il sert a inscrit du contenu dans le canvas selon sont type de choix. */
  useEffect(() => {
    setTimeout(() => {
      var canvasWidth = 200;
      var canvasHeight = 100;

      var c = document.getElementById('signature-canvas');
      c.width = canvasWidth;
      c.height = canvasHeight;

      var ctx = c.getContext('2d');

      var image = signSel.previewText;
      var base_image = new Image();
      base_image.src = image;
      base_image.onload = function () {
        ctx.drawImage(
          base_image,
          0,
          0,
          200,
          100
        )
      }

      setImageSign(c.toDataURL());
    }, 1000);
  }, []);

  /**
   * UseEffect exécuté au moment du chargement de la page.
   * Ecoute les modification sur l'état [imgCode].
   * Charge le lien CDN pour utiliser une librairie PDF.
   * Ajoute un evenement sur le document.
   */
  useEffect(() => {
    document.querySelectorAll('.modal-backdrop').forEach((el) => el.remove());
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    docRef.current.addEventListener('mousemove', (e) => {
      if (!imgCode.uri) return null;
      getCoordinateDoc(e);
    });
  }, [imgCode]);

  /**
   * UseEffect exécuté au moment du chargement de la page.
   * Ecoute les modification sur l'état [pageNumber, isSwicth].
   * Chargé de gérer l'affiche de signature, qrcode ... ,
   * selon la page ou le document sur le quel l'élément a été déposé
   */
  useEffect(() => {
    var elem = DOCUMENT.filter((it) => currentFile.name === it.link.name);
    elem[0] && setCurNumber(elem[0].id);

    var signatureSel = document.querySelectorAll('.signaturesel');
    var qrcodeSel = document.querySelectorAll('.qrcodesel');

    if (signatureSel) {
      for (let i = 0; i < signatureSel.length; i++) {
        const curFile = signatureSel[i].getAttribute('currentfile');

        if (
          curFile !== currentFile.name ||
          signatureSel[i].title !== String(pageNumber)
        ) {
          signatureSel[i].style.display = 'none';
        } else {
          signatureSel[i].style.display = 'block';
        }
      }
      isSwicth && setIsSwitch(!isSwicth);
    }

    if (qrcodeSel) {
      for (let i = 0; i < qrcodeSel.length; i++) {
        const curFile = qrcodeSel[i].getAttribute('currentfile');

        if (
          curFile !== currentFile.name ||
          qrcodeSel[i].title !== String(pageNumber)
        ) {
          qrcodeSel[i].style.display = 'none';
        } else {
          qrcodeSel[i].style.display = 'block';
        }
      }
      isSwicth && setIsSwitch(!isSwicth);
    }

    signataire.map((it) => {
      var squareSel = document.querySelectorAll(`.square${it.id}sel`);
      if (squareSel) {
        for (let i = 0; i < squareSel.length; i++) {
          const curFile = squareSel[i].getAttribute('currentfile');

          if (
            curFile !== currentFile.name ||
            squareSel[i].title !== String(pageNumber)
          ) {
            squareSel[i].style.display = 'none';
          } else {
            squareSel[i].style.display = 'block';
          }
        }
        isSwicth && setIsSwitch(!isSwicth);
      }
    });
  }, [pageNumber, isSwicth]);

  /**
 * Fonction appelé pour placer la mark sur tout les documents et revenir sur le premier document
 */
  function modifyArray() {
    var pdf__Document = document.getElementsByClassName('react-pdf__Document')[0]

    if (DOCUMENT.length === 0) {
      return;
    }

    const firstElement = DOCUMENT[0];

    for (let i = 1; i < DOCUMENT.length; i++) {
      DOCUMENT[i] = {
        ...DOCUMENT[i], // conserve les propriétés `id`, `link`, et `className`
        nbSignature: firstElement.nbSignature,
        typeSign: [...firstElement.typeSign] // crée une copie du tableau typeSign
      };

      var elementSignature = document.getElementsByClassName('signaturesel')
      if (elementSignature.length) {
        const cloneSignature = elementSignature[0].cloneNode(true)

        cloneSignature.ondragend = (event) => endDragFx(event);
        cloneSignature.addEventListener('click', function init(e) {
          if (cloneSignature.getAttribute('mousemove') === 'true') {
            cloneSignature.removeEventListener('mousemove', init, false);
            cloneSignature.setAttribute('mousemove', 'false');
          } else {
            cloneSignature.setAttribute('mousemove', 'true');
          }
        });
        cloneSignature.setAttribute('currentFile', DOCUMENT[i].link.name);
        cloneSignature.childNodes[1].onclick = () => removeMarker({ type: 'signature' }, DOCUMENT, DOCUMENT[i].link);

        var stri = DOCUMENT[i].link.name + 'signature';
        stri = stri.replace('.', '');
        cloneSignature.id = stri;

        pdf__Document.appendChild(cloneSignature)
      }

      var elementQrcode = document.getElementsByClassName('qrcodesel')
      if (elementQrcode.length) {
        const cloneQrCode = elementQrcode[0].cloneNode(true)

        cloneQrCode.ondragend = (event) => endDragFx(event);
        cloneQrCode.addEventListener('click', function init(e) {
          if (cloneQrCode.getAttribute('mousemove') === 'true') {
            cloneQrCode.removeEventListener('mousemove', init, false);
            cloneQrCode.setAttribute('mousemove', 'false');
          } else {
            cloneQrCode.setAttribute('mousemove', 'true');
          }
        });
        cloneQrCode.setAttribute('currentFile', DOCUMENT[i].link.name);
        cloneQrCode.childNodes[1].onclick = () => removeMarker({ type: 'qrcode' }, DOCUMENT, DOCUMENT[i].link);

        var stri = DOCUMENT[i].link.name + 'qrcode';
        stri = stri.replace('.', '');
        cloneQrCode.id = stri;

        pdf__Document.appendChild(cloneQrCode)
      }

      var signataire = document.getElementsByClassName(`square${i}sel`)
      if (signataire.length) {
        const cloneSignataire = signataire[0].cloneNode(true)

        cloneSignataire.ondragend = (event) => endDragFx(event);
        cloneSignataire.addEventListener('click', function init(e) {
          if (cloneSignataire.getAttribute('mousemove') === 'true') {
            cloneSignataire.removeEventListener('mousemove', init, false);
            cloneSignataire.setAttribute('mousemove', 'false');
          } else {
            cloneSignataire.setAttribute('mousemove', 'true');
          }
        });
        cloneSignataire.setAttribute('currentFile', DOCUMENT[i].link.name);
        cloneSignataire.childNodes[1].onclick = () => removeMarker({ type: `square${i}` }, DOCUMENT, DOCUMENT[i].link);

        var stri = DOCUMENT[i].link.name + `square${i}`;
        stri = stri.replace('.', '');
        cloneSignataire.id = stri;

        pdf__Document.appendChild(cloneSignataire)
      }

      setImgCode({ uri: null, type: '' });
    }
  }

  useEffect(() => {
    if (isAll) {
      modifyArray()
      setIsAll(false)
    }
  }, [DOCUMENT])

  /**
   * Fonction appelé l'orsqu'on change de document dans la liste
   */
  const switchDocument = (link) => {
    var qr = document.getElementById('exit-qrcode')
    var square = document.getElementById('exit-square')

    if (qr) qr.remove()

    if (square) square.remove()
    setIsSwitch(true)
    setCurrentFile(link)

    var newLsDoc = DOCUMENT.map(doc => {
      if (doc.link == link)
        return ({ id: doc.id, 'link': doc.link, 'className': 'active', 'nbSignature': doc.nbSignature, 'typeSign': doc.typeSign })
      else
        return ({ id: doc.id, 'link': doc.link, 'className': '', 'nbSignature': doc.nbSignature, 'typeSign': doc.typeSign })
    })

    SETDOCUMENT(newLsDoc)
  }

  /**
   * Fonction appelé pour affecter le nombre de page total d'un document au state setNumPages
   * @param {numPages} param0
   */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  /**
   * Fonction appelé pour récupérer les coordonnées (x,y),
   * au moment ou la souris survole le document
   * @param {*} e
   */
  const getCoordinateDoc = (e) => {
    var rect = docRef.current.getBoundingClientRect();
    const display = 'block';

    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;

    if (x > 0.88 * rect.width) {
      x = 0.88 * rect.width;
      setCoordSign({ x, y, display });
    } else {
      setCoordSign({ x, y, display });
    }

    if (y > 0.9 * rect.height) {
      y = 0.9 * rect.height;
      setCoordSign({ x, y, display });
    } else {
      setCoordSign({ x, y, display });

    }
  }

  /**
   * Fonction appelé l'orsqu'une signature est sélectionné
   */
  const selectSign = () => {
    var c = document.getElementById('signature-canvas');

    setImgCode({ uri: c.toDataURL(), type: 'signature', page: pageNumber });
    const sign = canvasSignRef.current;

    const qrc = qrcodeRef.current;
    qrc && qrc.classList.remove('active-tools');
    sign.classList.add('active-tools');
  };

  /**
   * Fonction appelé l'orsqu'un QrCode est sélectionné
   */
  const selectQrCode = () => {
    setImgCode({ uri: qrcode, type: 'qrcode' });
    const qrc = qrcodeRef.current;

    const sign = canvasSignRef.current;
    sign.classList.remove('active-tools');
    qrc && qrc.classList.add('active-tools');
  };

  /**
   * Fonction appelé l'orsqu'un signataire est sélectionné
   * @param {*} e
   */
  const selectZone = (e) => {
    console.log(e.target);
    setImgCode({ uri: square, type: `square${e.target.id}` });

    var ls_signature = document.getElementsByClassName('lst-signataire');

    for (let i = 0; i < ls_signature[0].children.length; i++) {
      ls_signature[0].children[i].classList.remove('active-tools');
    }

    e.target.classList.add('active-tools');
  };

  /**
   * Fonction appelé l'orsqu'un element est supprimé du document
   * @param {*} uri
   * @param {*} doc
   */
  function removeMarker(uri, doc, current) {
    var newIsSigned = isSigned.filter((it) => it.type !== uri.type);

    if (uri.type === 'qrcode') {
      var stri = current.name + 'qrcode';
      stri = stri.replace('.', '');
      var resizer_br = document.getElementById(stri);
      resizer_br.remove();
    } else if (uri.type === 'signature') {
      var stri = current.name + 'signature';
      stri = stri.replace('.', '');
      var resizer_br = document.getElementById(stri);
      resizer_br.remove();
    } else if (uri.type.includes('square')) {
      signataire.map((it) => {
        var stri = current.name + `square${it.id}`;

        if (`square${it.id}` === uri.type) {
          stri = stri.replace('.', '');
          var resizer_br = document.getElementById(stri);

          resizer_br && resizer_br.remove();
        }
      });
    }

    var newLsDoc = doc.map((doc) => {
      if (doc.link.name === current.name)
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature - 1,
          typeSign: doc.typeSign.filter((it) => it !== uri.type),
        };
      else
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
    });

    SETDOCUMENT(newLsDoc);
    setIsSigned(newIsSigned);
  }

  /**
   * Fonction appelé l'orsqu'un element est déplacé sur le document
   * @param {*} event
   */
  const endDragFx = (event) => {
    var rect = docRef.current.getBoundingClientRect();

    console.log('test', event);

    var x, y;

    if (navigator.maxTouchPoints === 1) {
      // Le site est en format mobile
      x = event.changedTouches[0].clientX - rect.left;
      y = event.changedTouches[0].clientY - rect.top;
    } else {
      // Le site est en format desktop
      x = event.clientX - rect.left;
      y = event.clientY - rect.top;
    }

    if (x > 0.88 * rect.width) {
      x = 0.88 * rect.width;

      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    } else {
      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    }

    if (y > 0.9 * rect.height) {
      y = 0.9 * rect.height;

      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    } else {
      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    }
  };

  /**
   * Fonction appelé l'orsqu'un element est déposé sur le document
   */

  const onDump = () => {
    if (docList.length > 1) {
      if (docList[0].name === currentFile.name) {
        getMessageAlert(imgCode.type)
        setAllModalOpen(true)
      } else placeMark()
    } else placeMark()
  }

  const placeMark = () => {
    const div = document.createElement('div');
    div.style = `top:${coordSign.y}px;left:${coordSign.x}px;display:${coordSign.display}`;

    if (navigator.maxTouchPoints === 1) {
      // Le site est en format mobile
      div.addEventListener('touchend', (event) => endDragFx(event));
    } else {
      // Le site est en format desktop
      div.ondragend = (event) => endDragFx(event);
    }

    div.classList.add('area-doc-sign');
    div.classList.add(imgCode.type + 'sel');

    div.title = pageNumber;
    div.setAttribute('currentFile', currentFile.name);

    var stri = currentFile.name + imgCode.type;
    stri = stri.replace('.', '');
    div.id = stri;

    const qrc = qrcodeRef.current;
    const sign = canvasSignRef.current;

    sign && sign.classList.remove('active-tools');
    qrc && qrc.classList.remove('active-tools');

    var newLsDoc = DOCUMENT.map((doc) => {
      if (doc.link.name === currentFile.name)
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature + 1,
          typeSign: [...doc.typeSign, imgCode.type],
        };
      else
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
    });

    SETDOCUMENT(newLsDoc);

    div.addEventListener('click', function init(e) {
      if (div.getAttribute('mousemove') === 'true') {
        div.removeEventListener('mousemove', init, false);
        div.setAttribute('mousemove', 'false');
      } else {
        div.setAttribute('mousemove', 'true');
      }
    });

    div.className = div.className + ' resizable';

    var resizer_br = document.createElement('div');
    resizer_br.className = 'resizer-br';

    var exit_ic = document.createElement('div');
    exit_ic.innerText = 'X';
    exit_ic.className = 'exit-ic';
    exit_ic.onclick = () => removeMarker(imgCode, newLsDoc, currentFile);

    var text_p = document.createElement('p');
    text_p.innerText =
      signataireName && imgCode.type.includes('square') ? signataireName : '';
    text_p.style = 'position: absolute; top: 50%; left: 20%;';

    div.appendChild(resizer_br);
    div.appendChild(exit_ic);
    div.appendChild(text_p);

    resizer_br.addEventListener('mousedown', initDrag, false);

    const img = document.createElement('img');
    img.style = 'width:auto;height:auto';
    img.classList.add('.pic');
    img.src = imgCode.uri;

    div.appendChild(img);
    setBoxList((prevState) => [...prevState, div]);

    boxsign.current.parentNode.appendChild(div);
    setImgCode({ uri: null, type: '' });
    setIsSigned((prev) => [...prev, imgCode]);

    var startX, startY, startWidth, startHeight;

    function initDrag(e) {
      startX = e.clientX;
      startY = e.clientY;
      startWidth = parseInt(
        document.defaultView.getComputedStyle(div).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(div).height,
        10
      );
      document.documentElement.addEventListener('mousemove', doDrag, false);
      document.documentElement.addEventListener('mouseup', stopDrag, false);
    }

    function doDrag(e) {
      var x = startWidth + e.clientX - startX;
      var y = startHeight + e.clientY - startY;
      if (x < 50 || y < 50 || x > 100 || y > 100) return;

      div.style.width = x + 'px';
      div.style.height = y + 'px';
      img.style.width = x + 'px';
      img.style.height = y + 'px';
    }

    function stopDrag(e) {
      document.documentElement.removeEventListener('mousemove', doDrag, false);
      document.documentElement.removeEventListener('mouseup', stopDrag, false);
    }
  };

  /**
   * Fonction appelé pour effectuer des vérifications sur l'état des document
   */
  const checkDocs = () => {
    setShowModal(true);
    var elemToSend = [];

    console.log(DOCUMENT)
    DOCUMENT.map((el) => {
      var temp = {
        document: el.link,
        element: [],
      };

      el.typeSign.map((it) => {
        var obj = {};
        var element = document.getElementsByClassName(it + 'sel');
        Object.values(element);

        var curdoc = Object.values(element).filter((it) => {
          if (it.getAttribute('currentfile') === el.link.name) {
            return it;
          }
        });

        obj[it] = curdoc;
        temp.element.push(obj);
      });
      elemToSend.push(temp);
    });

    setData(elemToSend);
    var docNotSigned = signSel.qrCode
      ? DOCUMENT.filter(
        (it) =>
          !(
            it.typeSign.includes('signature') &&
            it.typeSign.includes('qrcode')
          )
      )
      : DOCUMENT.filter((it) => !it.typeSign.includes('signature'));

    docNotSigned = signSel.HaveSignataire
      ? DOCUMENT.filter((it) => {
        var typeSign = it.typeSign.filter(
          (t) => t !== 'signature' && t !== 'qrcode'
        );

        if (typeSign.length === 0) return it;
        if (typeSign.length !== signSel.signataire.length) return it;
      })
      : docNotSigned;

    if (docNotSigned.length === 0) {
      setMessage(translate.admin.etreVousSurAvoirLu);
      setType('confirm');
    } else if (docNotSigned.length === 1) {
      setMessage(
        `${translate.admin.leDocument} ${docNotSigned[0].link.name} ${translate.admin.nApasEteSigne}`
      );
      setType('basic');
    } else {
      var names = [];
      console.log('else', docNotSigned);
      docNotSigned.map((it) => names.push(it.link.name));

      setMessage(
        `${translate.admin.leDocument}s ${names.toString()} ${translate.admin.nOntPasEteCorrectementRenseignes
        }`
      );
      setType('basic');
    }
  };

  /**
   * Fonction appelé pour envoyer des informations a l'API
   */
  const sendData = async () => {

    const name = JSON.parse(Cookies.get('user')).nom + ' ' + JSON.parse(Cookies.get('user')).prenoms

    try {
      if (signSel.HaveSignataire) {
        var i_docs = 0
        data.map(async (docs, key) => {
          i_docs++
          var sgaires = []

          signataire.map((sgn) => {

            if (signSel.mode === 'signer_faire_signer') {

              var x =
                docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.left) :
                  docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.left) :
                    docs.element[2][`square${sgn.id}`] ? parseFloat(docs.element[2][`square${sgn.id}`][0].style.left) :
                      docs.element[3][`square${sgn.id}`] ? parseFloat(docs.element[3][`square${sgn.id}`][0].style.left) :
                        docs.element[4][`square${sgn.id}`] ? parseFloat(docs.element[4][`square${sgn.id}`][0].style.left) :
                          docs.element[5][`square${sgn.id}`] ? parseFloat(docs.element[5][`square${sgn.id}`][0].style.left) :
                            docs.element[6][`square${sgn.id}`] ? parseFloat(docs.element[6][`square${sgn.id}`][0].style.left) :
                              parseFloat(docs.element[7][`square${sgn.id}`][0].style.left)

              var y =
                docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.top) :
                  docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.top) :
                    docs.element[2][`square${sgn.id}`] ? parseFloat(docs.element[2][`square${sgn.id}`][0].style.top) :
                      docs.element[3][`square${sgn.id}`] ? parseFloat(docs.element[3][`square${sgn.id}`][0].style.top) :
                        docs.element[4][`square${sgn.id}`] ? parseFloat(docs.element[4][`square${sgn.id}`][0].style.top) :
                          docs.element[5][`square${sgn.id}`] ? parseFloat(docs.element[5][`square${sgn.id}`][0].style.top) :
                            docs.element[6][`square${sgn.id}`] ? parseFloat(docs.element[6][`square${sgn.id}`][0].style.top) :
                              parseFloat(docs.element[7][`square${sgn.id}`][0].style.top)

              var page =
                docs.element[0][`square${sgn.id}`] ? docs.element[0][`square${sgn.id}`][0].title :
                  docs.element[1][`square${sgn.id}`] ? docs.element[1][`square${sgn.id}`][0].title :
                    docs.element[2][`square${sgn.id}`] ? docs.element[2][`square${sgn.id}`][0].title :
                      docs.element[3][`square${sgn.id}`] ? docs.element[3][`square${sgn.id}`][0].title :
                        docs.element[4][`square${sgn.id}`] ? docs.element[4][`square${sgn.id}`][0].title :
                          docs.element[5][`square${sgn.id}`] ? docs.element[5][`square${sgn.id}`][0].title :
                            docs.element[6][`square${sgn.id}`] ? docs.element[6][`square${sgn.id}`][0].title :
                              docs.element[7][`square${sgn.id}`][0].title

              if (signSel.HaveOrder) {
                sgaires.push({
                  email: sgn.email,
                  telephone: sgn.telephone,
                  ordre: sgn.id,
                  nom: sgn.nom,
                  deadline: sgn.deadline,
                  page: page,
                  code_auth: String(signSel.authCode),
                  position: {
                    x: x,
                    y: y
                  }
                })
              } else {
                sgaires.push({
                  email: sgn.email,
                  telephone: sgn.telephone,
                  ordre: sgn.id,
                  nom: sgn.nom,
                  deadline: sgn.deadline,
                  page: page,
                  code_auth: String(signSel.authCode),
                  position: {
                    x: x,
                    y: y
                  }
                })
              }
            } else {

              var x =
                docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.left) :
                  docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.left) :
                    docs.element[2][`square${sgn.id}`] ? parseFloat(docs.element[2][`square${sgn.id}`][0].style.left) :
                      docs.element[3][`square${sgn.id}`] ? parseFloat(docs.element[3][`square${sgn.id}`][0].style.left) :
                        docs.element[4][`square${sgn.id}`] ? parseFloat(docs.element[4][`square${sgn.id}`][0].style.left) :
                          docs.element[5][`square${sgn.id}`] ? parseFloat(docs.element[5][`square${sgn.id}`][0].style.left) :
                            docs.element[6][`square${sgn.id}`] ? parseFloat(docs.element[6][`square${sgn.id}`][0].style.left) :
                              docs.element[7][`square${sgn.id}`][0].style.left

              var y =
                docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.top) :
                  docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.top) :
                    docs.element[2][`square${sgn.id}`] ? parseFloat(docs.element[2][`square${sgn.id}`][0].style.top) :
                      docs.element[3][`square${sgn.id}`] ? parseFloat(docs.element[3][`square${sgn.id}`][0].style.top) :
                        docs.element[4][`square${sgn.id}`] ? parseFloat(docs.element[4][`square${sgn.id}`][0].style.top) :
                          docs.element[5][`square${sgn.id}`] ? parseFloat(docs.element[5][`square${sgn.id}`][0].style.top) :
                            docs.element[6][`square${sgn.id}`] ? parseFloat(docs.element[6][`square${sgn.id}`][0].style.top) :
                              docs.element[7][`square${sgn.id}`][0].style.top

              var page =
                docs.element[0][`square${sgn.id}`] ? docs.element[0][`square${sgn.id}`][0].title :
                  docs.element[1][`square${sgn.id}`] ? docs.element[1][`square${sgn.id}`][0].title :
                    docs.element[2][`square${sgn.id}`] ? docs.element[2][`square${sgn.id}`][0].title :
                      docs.element[3][`square${sgn.id}`] ? docs.element[3][`square${sgn.id}`][0].title :
                        docs.element[4][`square${sgn.id}`] ? docs.element[4][`square${sgn.id}`][0].title :
                          docs.element[5][`square${sgn.id}`] ? docs.element[5][`square${sgn.id}`][0].title :
                            docs.element[6][`square${sgn.id}`] ? docs.element[6][`square${sgn.id}`][0].title :
                              docs.element[7][`square${sgn.id}`][0].title

              if (signSel.HaveOrder) {
                sgaires.push({
                  email: sgn.email,
                  telephone: sgn.telephone,
                  ordre: sgn.id,
                  nom: sgn.nom,
                  deadline: sgn.deadline,
                  page: page,
                  code_auth: String(signSel.authCode),
                  position: {
                    x: x,
                    y: y
                  },
                })
              } else {
                sgaires.push({
                  email: sgn.email,
                  telephone: sgn.telephone,
                  ordre: sgn.id,
                  nom: sgn.nom,
                  deadline: sgn.deadline,
                  page: page,
                  code_auth: String(signSel.authCode),
                  position: {
                    x: x,
                    y: y
                  },
                })
              }
            }
          })

          if (signSel.mode === 'signer' || signSel.mode === 'signer_faire_signer') {
            var canvas = document.getElementById("signature-canvas");
            var ImagedataURL = canvas.toDataURL();

            ImagedataURL = signSel.text

            var posX =
              docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.left) * 1.35)) : docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.left) * 1.35))
            var posY = docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.top) * 1.35)) : docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.top) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.top) * 1.35))

            var datasended = {
              "Key_Api": "test@dkbsign7",
              "Id_cl": "0708155815",
              "signataire": "",
              "signataire2": "",
              "posX_signataire2": "",
              "posY_signataire2": "",
              "signataire3": "",
              "posX_signataire3": "",
              "posY_signataire3": "",
              "nbre_signatair": "1",
              "Code_ctr": "cl07",
              "nom_ctr": docs.document.name,
              "posX": (choice === 'draw' || choice === 'image') ? (posX - 25) : posX,
              "posY": posY,
              "posX_qr": signSel.qrCode ?
                docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) :
                  docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) :
                    docs.element[2].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].qrcode[0].style.left) * 1.35)) :
                      docs.element[3].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].qrcode[0].style.left) * 1.35)) :
                        docs.element[4].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].qrcode[0].style.left) * 1.35)) :
                          docs.element[5].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].qrcode[0].style.left) * 1.35)) :
                            docs.element[6].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].qrcode[0].style.left) * 1.35)) :
                              docs.element[7].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].qrcode[0].style.left) * 1.35)) :
                                docs.element[8].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].qrcode[0].style.left) * 1.35)) :
                                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].qrcode[0].style.left) * 1.35)) : "",

              "posY_qr": signSel.qrCode ?
                docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.top) * 1.35)) :
                  docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 1.35)) :
                    docs.element[2].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].qrcode[0].style.top) * 1.35)) :
                      docs.element[3].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].qrcode[0].style.top) * 1.35)) :
                        docs.element[4].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].qrcode[0].style.top) * 1.35)) :
                          docs.element[5].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].qrcode[0].style.top) * 1.35)) :
                            docs.element[6].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].qrcode[0].style.top) * 1.35)) :
                              docs.element[7].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].qrcode[0].style.top) * 1.35)) :
                                docs.element[8].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].qrcode[0].style.top) * 1.35)) :
                                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].qrcode[0].style.top) * 1.35)) : "",
              "taille_qr": 20,
              "img_signataire_png": ImagedataURL,
              "format_img_signataire": 1,
              "posX_Imgsign": posX,
              "posY_Imgsign": posY,
              "page_sign":
                docs.element[0].signature ? docs.element[0].signature[0].title :
                  docs.element[1].signature ? docs.element[1].signature[0].title :
                    docs.element[2].signature ? docs.element[2].signature[0].title :
                      docs.element[3].signature ? docs.element[3].signature[0].title :
                        docs.element[4].signature ? docs.element[4].signature[0].title :
                          docs.element[5].signature ? docs.element[5].signature[0].title :
                            docs.element[6].signature ? docs.element[6].signature[0].title :
                              docs.element[7].signature ? docs.element[7].signature[0].title :
                                docs.element[8].signature ? docs.element[8].signature[0].title :
                                  docs.element[9].signature[0].title,
              "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
              "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
              "initial": "",
              "qrcodeyes": signSel.qrCode ? "1" : "0",

              "date_ctr": "",
              "posX_date": posX + 38,
              "posY_date": posY + 25,
              "taille_date": "15"
            }

            datasended.posY_qr += 10
            const dument = await formatDocument(docs.document)

            const formData = new FormData();
            formData.append("data", JSON.stringify(datasended));
            formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
            formData.append('document', dument.message)
            formData.append('signataires', JSON.stringify(sgaires))
            formData.append('action', signSel.mode)
            formData.append('urgence', urgence)
            formData.append('sendAlert', key > 0 ? 0 : 1)

            const token = Cookies.get('token')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formData,
              redirect: 'follow'
            };

            setLoader(true)
            fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
              .then(result => {
                if (result.status !== 200) {
                  setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                  return
                }
                if (i_docs === data.length) window.location.href = '/alldocument'
              })
              .catch(error => {
                console.log(error)
                setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
              });
          } else {

            var datasended = {
              "Key_Api": "test@dkbsign7",
              "Id_cl": "0708155815",
              "signataire": "",
              "signataire2": "",
              "posX_signataire2": "",
              "posY_signataire2": "",
              "signataire3": "",
              "posX_signataire3": " ",
              "posY_signataire3": " ",
              "nbre_signatair": "1",
              "Code_ctr": "cl07",
              "nom_ctr": docs.document.name,
              "posX": "",
              "posY": "",
              "posX_qr": signSel.qrCode ?
                docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) :
                  docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) :
                    docs.element[2].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].qrcode[0].style.left) * 1.35)) :
                      docs.element[3].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].qrcode[0].style.left) * 1.35)) :
                        docs.element[4].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].qrcode[0].style.left) * 1.35)) :
                          docs.element[5].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].qrcode[0].style.left) * 1.35)) :
                            docs.element[6].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].qrcode[0].style.left) * 1.35)) :
                              docs.element[7].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].qrcode[0].style.left) * 1.35)) :
                                docs.element[8].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].qrcode[0].style.left) * 1.35)) :
                                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].qrcode[0].style.left) * 1.35))
                : "",
              "posY_qr": signSel.qrCode ?
                docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.top) * 1.35)) :
                  docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 1.35)) :
                    docs.element[2].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].qrcode[0].style.top) * 1.35)) :
                      docs.element[3].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].qrcode[0].style.top) * 1.35)) :
                        docs.element[4].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].qrcode[0].style.top) * 1.35)) :
                          docs.element[5].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].qrcode[0].style.top) * 1.35)) :
                            docs.element[6].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].qrcode[0].style.top) * 1.35)) :
                              docs.element[7].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].qrcode[0].style.top) * 1.35)) :
                                docs.element[8].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].qrcode[0].style.top) * 1.35)) :
                                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].qrcode[0].style.top) * 1.35)) : "",
              "taille_qr": 20,
              "img_signataire_png": "",
              "format_img_signataire": 1,
              "posX_Imgsign": "",
              "posY_Imgsign": "",
              "page_sign": signSel.qrCode ?
                docs.element[0].qrcode ? docs.element[0].qrcode[0].title :
                  docs.element[1].qrcode ? docs.element[1].qrcode[0].title :
                    docs.element[2].qrcode ? docs.element[2].qrcode[0].title :
                      docs.element[3].qrcode ? docs.element[3].qrcode[0].title :
                        docs.element[4].qrcode ? docs.element[4].qrcode[0].title :
                          docs.element[5].qrcode ? docs.element[5].qrcode[0].title :
                            docs.element[6].qrcode ? docs.element[6].qrcode[0].title :
                              docs.element[7].qrcode ? docs.element[7].qrcode[0].title :
                                docs.element[8].qrcode ? docs.element[8].qrcode[0].title :
                                  docs.element[9].qrcode[0].title : "1",
              "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
              "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
              "initial": "",
              "qrcodeyes": signSel.qrCode ? "1" : "0"
            }

            datasended.posY_qr += 10

            const dcment = await formatDocument(docs.document)

            console.log(`here: ${key}`)
            const formData = new FormData();
            formData.append("data", JSON.stringify(datasended));
            formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
            formData.append('document', dcment.message)
            formData.append('signataires', JSON.stringify(sgaires))
            formData.append('action', signSel.mode)
            formData.append('urgence', urgence)
            formData.append('sendAlert', key > 0 ? 0 : 1)

            const token = Cookies.get('token')

            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: formData,
              redirect: 'follow'
            };

            setLoader(true)
            fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
              .then(result => {
                if (result.status !== 200) {
                  setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                  return
                }
                if (i_docs === data.length) window.location.href = '/alldocument'
              })
              .catch(error => {
                console.log(error)
                setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
              });
          }
        })
      } else {
        var canvas = document.getElementById("signature-canvas");
        var ImagedataURL = canvas.toDataURL();

        ImagedataURL = signSel.text

        var i_docs = 0
        var choice = signSel.choice;
        data.map(async (docs, key) => {
          i_docs++

          var posX =
            docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.left) * 1.35)) :
              docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.left) * 1.35)) :
                docs.element[2].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.left) * 1.35)) :
                  docs.element[3].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].signature[0].style.left) * 1.35)) :
                    docs.element[4].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].signature[0].style.left) * 1.35)) :
                      docs.element[5].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].signature[0].style.left) * 1.35)) :
                        docs.element[6].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].signature[0].style.left) * 1.35)) :
                          docs.element[7].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].signature[0].style.left) * 1.35)) :
                            docs.element[8].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].signature[0].style.left) * 1.35)) :
                              pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].signature[0].style.left) * 1.35))

          var posY =
            docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.top) * 1.35)) :
              docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.top) * 1.35)) :
                docs.element[2].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.top) * 1.35)) :
                  docs.element[3].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[3].signature[0].style.top) * 1.35)) :
                    docs.element[4].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[4].signature[0].style.top) * 1.35)) :
                      docs.element[5].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[5].signature[0].style.top) * 1.35)) :
                        docs.element[6].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[6].signature[0].style.top) * 1.35)) :
                          docs.element[7].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[7].signature[0].style.top) * 1.35)) :
                            docs.element[8].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[8].signature[0].style.top) * 1.35)) :
                              pixelToMillimeter(Math.max(0, parseFloat(docs.element[9].signature[0].style.top) * 1.35))

          var datasended = {
            "Key_Api": "test@dkbsign7",
            "Id_cl": "0708155815",
            "signataire": signSel.paraphe ? name : '',
            "signataire2": signSel.paraphe ? name : '',
            "posX_signataire2": "",
            "posY_signataire2": "",
            "signataire3": "",
            "posX_signataire3": "",
            "posY_signataire3": "",
            "nbre_signatair": "1",
            "Code_ctr": "cl07",
            "nom_ctr": docs.document.name,
            "ctr": "http://www.dkbsolutions.com/Api_dkbsign4/include/DOCSIGN_BICICI/TEMPLATE/Contrat_Signature_KO.pdf",
            "posX": posX,
            "posY": posY,
            "posX_qr": signSel.qrCode ? docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) : "",
            "posY_qr": signSel.qrCode ? docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.top) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 1.35)) : "",
            "taille_qr": 20,
            "img_signataire_png": ImagedataURL,
            "format_img_signataire": 1,
            "posX_Imgsign": posX,
            "posY_Imgsign": posY,
            "page_sign":
              docs.element[0].signature ? docs.element[0].signature[0].title :
                docs.element[1].signature ? docs.element[1].signature[0].title :
                  docs.element[2].signature ? docs.element[2].signature[0].title :
                    docs.element[3].signature ? docs.element[3].signature[0].title :
                      docs.element[4].signature ? docs.element[4].signature[0].title :
                        docs.element[5].signature ? docs.element[5].signature[0].title :
                          docs.element[6].signature ? docs.element[6].signature[0].title :
                            docs.element[7].signature ? docs.element[7].signature[0].title :
                              docs.element[8].signature ? docs.element[8].signature[0].title :
                                docs.element[9].signature[0].title,
            "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
            "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
            "initial": "",
            "qrcodeyes": signSel.qrCode ? "1" : "0",

            "date_ctr": "",
            "posX_date": posX + 38,
            "posY_date": posY + 25,
            "taille_date": "15"
          }

          datasended.posY_qr += 10

          const formData = new FormData();

          const dcment = await formatDocument(docs.document)

          formData.append("data", JSON.stringify(datasended));
          formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
          formData.append('document', dcment.message)
          formData.append('action', signSel.mode)
          formData.append('urgence', urgence)
          formData.append('sendAlert', key > 0 ? 0 : 1)

          const token = Cookies.get('token')

          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
          };

          setLoader(true)
          fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
            .then(result => {
              if (result.status !== 200) {
                setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                return
              }
              if (i_docs === data.length) window.location.href = '/alldocument'
            })
            .catch(error => {
              console.log(error)
              setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
            });
        })
      }
    } catch (error) {
      console.error(error)

      toast.error("Ooops, une erreur s'est produite veuillez reprendre le processus depuis le début si le problème persiste veuillez contacter l'administrateur du site", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }

  const handleOnLoadPageSuccess = (page) => {
    setPdfSize({
      width: page.view[2],
      height: page.view[3]
    })
  }

  // Gérer le début du glisser-déposer
  const handleDragStart = (event, data) => {
    event.currentTarget.classList.add('dragging');
  };

  // Gérer la fin du glisser-déposer
  const handleDragEnd = (event) => {
    event.currentTarget.classList.remove('dragging');
  };

  // Gérer le survol de la zone de dépôt
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, data) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="content">
        <LoaderDocs showModal={loader} erros={error} setShowModal={setLoader} />
        <ApplyModal
          showModal={allModalOpen}
          setShowModal={setAllModalOpen}
          alertMsg={alertMsg}
          onAccept={() => {
            placeMark()
            toggleFunction()
          }}
          onDecline={() => placeMark()}
        />
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          error={message}
          type={type}
          fxt={sendData}
        />
        <div className="tools">
          <p style={{ fontSize: 17 }}>
            <FaTools color="#009EF7" /> {translate.admin.outils}
          </p>
          {(signSel.image || signSel.draw || signSel.text !== '') && (
            <div className="signature">
              <FaRegEdit
                className="edit-tools"
                size={20}
                onClick={(e) => selectSign(e)}
              />
              <canvas
                id="signature-canvas"
                width="110"
                height="110"
                ref={canvasSignRef}
                onClick={(e) => selectSign(e)}
              ></canvas>
              <p>{translate.admin.cliquezPourSigne}</p>
              <p>
                <BsPenFill color="#009EF7" size={20} />
              </p>
            </div>
          )}
          {signSel.qrCode && (
            <div className="qr-code">
              <img
                src={qrcode}
                ref={qrcodeRef}
                onClick={() => selectQrCode()}
                style={{ width: '50px', height: '50px' }}
                alt="qr-code"
              />
              <p>{translate.admin.votreQrcode}</p>
              <p>
                <ImQrcode color="#009EF7" />
              </p>
            </div>
          )}

          {signataire.length > 0 && (
            <>
              <div className="lst-signataire">
                {signataire.map((it) => (
                  <p
                    key={it.id}
                    id={it.id}
                    ref={signataireRef}
                    onClick={(e) => {
                      selectZone(e);
                      setSignataireName(it.nom);
                    }}
                  >
                    <RiUser2Fill color="green" /> {it.nom}
                  </p>
                ))}
              </div>

              <p className="signataire-zn">{translate.admin.signataire}</p>
            </>
          )}
        </div>

        <div className="container-page">
          <div className="btn-pages">
            {pageNumber === 1 ? (
              <div className="btn-switch" href="#">
                <AiFillMinusSquare color="white" size={20} />
              </div>
            ) : (
              <div
                className="btn-switch"
                href="#"
                onClick={() => {
                  pageNumber !== 1 && setPageNumber(pageNumber - 1);
                }}
              >
                <GoArrowLeft color="white" size={20} />
              </div>
            )}

            {pageNumber === numPages ? (
              <div className="btn-switch" href="#">
                <AiFillMinusSquare color="white" size={20} />
              </div>
            ) : (
              <div
                className="btn-switch"
                href="#"
                onClick={() => {
                  pageNumber !== numPages && setPageNumber(pageNumber + 1);
                }}
              >
                <GoArrowRight color="white" size={20} />
              </div>
            )}
          </div>

          <div
            className="docview"
            onMouseDown={() => false}
            onSelect={() => false}
          // style={{ background: 'teal' }}
          >
            <p style={{ color: 'black', margin: '5px' }}>
              Page{' '}
              <input
                className="btn-page"
                min="1"
                type="number"
                value={pageNumber}
                onChange={(e) => {
                  if (e.currentTarget.value === '') setPageNumber(0);
                  if (parseInt(e.currentTarget.value) <= numPages)
                    setPageNumber(parseInt(e.currentTarget.value));
                }}
              />{' '}
              {translate.admin.sur} {numPages}
            </p>
            <div
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, null)}
              ref={docRef}
              onClick={() => imgCode.uri && onDump()}
              className={getDocumentType() === "landscape" ? 'document_landscape' : 'document'}
              style={{
                marginTop: 100
              }}
            >
              <Document
                ref={pdfRef}
                file={currentFile}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {imgCode.uri && (
                  <div
                    ref={boxsign}
                    draggable
                    style={{
                      top: coordSign.y,
                      left: coordSign.x,
                      display: coordSign.display,
                    }}
                    onDragStart={(e) => handleDragStart(e, 'Données à glisser')}
                    onDragEnd={handleDragEnd}
                    className="box-sign"
                  >
                    <img
                      src={imgCode.uri ? imgCode.uri : null}
                      style={{ width: '100px', height: '100px' }}
                      alt="Placer une signature ou qrcode"
                    />
                  </div>
                )}
                <Page
                  onLoadSuccess={handleOnLoadPageSuccess}
                  renderTextLayer={false}
                  width={600}
                  pageNumber={pageNumber}
                />
              </Document>
            </div>
            <button
              disabled={
                curNumber !== DOCUMENT.length || pageNumber !== numPages
              }
              onClick={() => checkDocs()}
              style={{ marginTop: '35px' }}
              className={
                curNumber !== DOCUMENT.length || pageNumber !== numPages
                  ? 'bnt-sign dis-btn'
                  : 'bnt-sign'
              }
            >
              {signSel.mode === 'signer_faire_signer'
                ? translate.admin.signerFaireSigner
                : signSel.mode === 'signer'
                  ? translate.admin.termine
                  : translate.admin.faireSigner}
              <MdDone style={{ marginLeft: '7px' }} size={24} />
            </button>{' '}
            <br />
          </div>
        </div>

        <PreviewDoc
          docList={docList}
          DOCUMENT={DOCUMENT}
          SETDOCUMENT={SETDOCUMENT}
          switchDocument={switchDocument}
        />
      </div>
    </React.Fragment>
  );
}
