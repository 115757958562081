import { createSlice } from '@reduxjs/toolkit';

export const SignModalStepSlice = createSlice({
    name : 'signModalStep',
    initialState: 1,
    reducers: {
        setSignTypeStep : (state, action) => action.payload
    }
})

export const { setSignTypeStep } = SignModalStepSlice.actions
export const signModalStepSelector = state => state.signModalStep