import React from 'react'

export default function HeaderTable({ children }) {
  return (
    <div className="card">
      <div className="card-header border-0 pt-6">
        <div className="card-title">

        </div>
        <div className="card-toolbar">
          <div
            className="d-flex justify-content-end"
            data-kt-user-table-toolbar="base"
          >

            <div
              className="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
              data-kt-menu="true"
            >
              <div className="separator border-gray-200" />
            </div>
          </div>
      
        </div>
      </div>
      {children}
    </div>
  )
}
