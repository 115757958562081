import React from 'react'
import HeaderTable from './HeaderTable'

export default function Table({ children, setShowForm, setDataEdit }) {
    return (
        <HeaderTable setShowForm={setShowForm} setDataEdit={setDataEdit}>
            <div className="card-body py-4">
                <div
                    id="kt_table_users_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                >
                    <div className="table-responsive">
                        <table
                            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                            id="kt_table_users"
                        >
                            {/*begin::Table head*/}
                            <thead>
                                {/*begin::Table row*/}
                                <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                    <th
                                        className="w-10px pe-2 sorting_disabled"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="								
                                                        "
                                        style={{ width: "29.25px" }}
                                    >
                                        <div className="form-check form-check-sm form-check-custom form-check-solid me-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                data-kt-check="true"
                                                data-kt-check-target="#kt_table_users .form-check-input"
                                                defaultValue={1}
                                            />
                                        </div>
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="User: activate to sort column ascending"
                                        style={{ width: "296.062px" }}
                                    >
                                        Page
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Role: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        Titre
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta keywords
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta description
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta robots
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta category
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta identifier url
                                    </th>
                                    <th
                                        className="min-w-125px sorting"
                                        tabIndex={0}
                                        aria-controls="kt_table_users"
                                        rowSpan={1}
                                        colSpan={1}
                                        aria-label="Last login: activate to sort column ascending"
                                        style={{ width: "164.75px" }}
                                    >
                                        meta reply to
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 fw-bold">
                                {children}
                            </tbody>
                            {/*end::Table body*/}
                        </table>
                    </div>
                </div>
            </div>
        </HeaderTable>
    )
}
