import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Step3.css';
import {
  signatureSelector,
  setSignataire,
  setUrgence
} from '../../../../../services/reduxSlices/SignatureSlice';
import { useTranslateContext } from '../../../../../hooks';
import styles from './styles.module.css'

/**
 * Composant d'ajout de signataires de l'étape 2 de la fenêtre modale faire signer
*/
export default function Step3() {
  const { translate } = useTranslateContext();
  
  const [applySameDate, setApplySameDate] = useState(false)
  const dispatch = useDispatch();
  const signature = useSelector(signatureSelector);

  function comparerDates(date1, date2) {
    // Convertir les dates en millisecondes
    var millisecDate1 = date1.getTime();
    var millisecDate2 = date2.getTime();

    // Comparer les millisecondes
    if (millisecDate1 >= millisecDate2) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      <div className="d-flex flex-column">

        <div className="d-flex flex-column">
          <p className={styles.prioriteTxt}>{translate.admin.priorite}</p>

          <div className={styles.row}>
            <PrioriteField
              libelle={translate.admin.normal}
              defaultChecked={true}
              onChange={() => dispatch(setUrgence('normal'))}
              labelStyles={{ color: 'green' }}
            />

            <PrioriteField
              libelle={translate.admin.urgent}
              onChange={() => dispatch(setUrgence('urgent'))}
              labelStyles={{ color: 'orange' }}
            />

            <PrioriteField
              libelle={translate.admin.tres_urgent}
              onChange={() => dispatch(setUrgence('tres_urgent'))}
              labelStyles={{ color: 'red' }}
            />
          </div>

        </div>

        <div className="d-flex flex-column mt-7">
          <div className="d-flex flex-row">
            <input defaultChecked={applySameDate} onChange={() => setApplySameDate(!applySameDate)} type="checkbox" name="forAll" />
            <label htmlFor="forAll">{translate.admin.applySameDateForAll}</label>
          </div>
          {
            applySameDate &&
            <input
              className={styles.applySameInput}
              onChange={e => {
                e.preventDefault();

                let _signataires = [...signature.signataire];

                const updatedSignataires = _signataires.map((_signataire, i) => {
                  return {
                    ..._signataire,
                    deadline: e.target.value
                  };
                });

                dispatch(setSignataire(updatedSignataires));
              }}
              type="date"
              name=""
              id=""
            />
          }
        </div>

        <div className="py-5">
          {signature.signataire.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">

                  </th>
                  {signature.HaveOrder && <th scope="col">Rang(s)</th>}
                  <th scope="col">{translate.admin.first_last_name}</th>
                  {!applySameDate && <th scope="col">{translate.admin.signatureDelay}</th>}
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {signature.signataire.map((signataire) => {
                  return (
                    <tr key={signataire.id}>
                      <td></td>
                      {signature.HaveOrder && (
                        <td>
                          <input
                            type="number"
                            min={1}
                            disabled
                            max={signature.signataire.length}
                            className="rang"
                            defaultValue={signataire.id}
                            contentEditable={false}
                            onChange={(e) => {
                              // let _signataires = [...signature.signataire]
                              // let _signataire = _signataires.find(_signataire => _signataire.id === signataire.id)
                              // if(parseInt(e.target.value)){
                              //     var sign_arr = _signataires.filter(sgn => sgn.id !== parseInt(e.target.value))
                              //     let _sign = {..._signataire, id: parseInt(e.target.value)}
                              //     console.log([...sign_arr,_sign])
                              //     handleRemoveSignataire(signataire.id)
                              //     dispatch(setSignataire([...sign_arr,_sign]))
                              // }
                            }}
                          />
                        </td>
                      )}
                      <td>{signataire.nom}</td>
                      {
                        !applySameDate && <td>
                          <DelayBox
                            value={signataire.deadline}
                            onChange={(e) => {

                              e.preventDefault();

                              let _signataires = [...signature.signataire];
                              let index = _signataires.findIndex(_signataire => _signataire.id === signataire.id);

                              if (signature.HaveOrder) {
                                if (index >= 1) {

                                  if (_signataires[index - 1].deadline === '') {
                                    e.target.value = ''
                                    alert('Veuillez préciser d\'abord la deadline du signataire précedent')
                                    return
                                  }

                                  const isDateSuperior = comparerDates(new Date(e.target.value), new Date(_signataires[index - 1].deadline))
                                  if (!isDateSuperior) {
                                    e.target.value = ''
                                    alert('La deadline de ce signataire doit être supérieur du signataire précedent')
                                    return
                                  }
                                }
                              }

                              if (index !== -1) {
                                let updatedSignataire = {
                                  ..._signataires[index],
                                  deadline: e.target.value
                                };
                                _signataires[index] = updatedSignataire;
                              }

                              dispatch(setSignataire(_signataires))
                            }}
                          />
                        </td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
}

const PrioriteField = ({ libelle, defaultChecked, onChange, labelStyles }) => {
  return (
    <div className={styles.prioriteField}>
      <label style={labelStyles} htmlFor="priorite">{libelle}</label>
      <input
        onChange={() => onChange()}
        defaultChecked={defaultChecked}
        type="radio"
        name="priorite"
      />
    </div>
  )
}

const DelayBox = ({ onChange, value }) => {

  // Obtenez la date d'aujourd'hui au format "YYYY-MM-DD"
  const today = new Date().toISOString().split('T')[0];

  console.log(new Date(value))

  return (
    <div className={styles.delayContainer}>
      <input defaultValue={new Date(value)} onChange={(e) => onChange(e)} type="date" min={today} />
    </div>
  )
}