import React, { useEffect, useState } from 'react'
import LandingWrapper from './LandingWrapper'
import { GetActualiteById, GetActualite } from './Admin/Main/services';
import Fade from 'react-reveal/Fade';
import './styles.css'

function trimString(chaine) {
  if (chaine.length > 20) {
      return chaine.slice(0, 20) + '...';
  } else {
      return chaine;
  }
}

export default function Actuality() {

  const [data, setData] = useState(null)
  const [actualite, setActualite] = useState(null)

  useEffect(() => {
    const queryString = window.location.href

    const parts = queryString.split("/"); 
    const id = parts[parts.length - 1];

    GetActualiteById(id).then(source => setData(source))
    GetActualite().then(source => setActualite(source))
  }, [])

  return (
    <LandingWrapper>
      <Header data={data} />
      <div className='actuality-subtitle'>
        <h3>{data && data.subtitle}</h3>
      </div>
      <Container data={data} actualite={actualite} />
    </LandingWrapper>
  )
}

const Header = ({ data }) => {
  return (
      <React.Fragment>
          <div className="landing-reference__body-content">
              <div className="flip-header-reference">
                  <Fade duration={4000} left>
                      <div className="landing-reference__body-content-left-title">
                          <h1>Les actualités</h1>
                          <p>{data && data.title}</p>
                      </div>
                  </Fade>
              </div>
          </div>
      </React.Fragment>
  )
}

const Container = ({ data, actualite }) =>{
  return(
    <div className='actuality-group'>
      <ActualityCard data={data} />
      <LastNews data={actualite} />
    </div>
  )
}

const ActualityCard = ({ data }) => {
  return (
    data ?
      <div className='actuality-container'>
        <div className="actuality-card">
          <span>{data.posteddate}</span>

          <div className='actuality-img'>
            <img className='actuality-preview' alt={data.alt} src={`https://dkbsign.com/dkbsign_web_api/public/storage/actualities/${data.img}`} />
          </div>

          <div className="card-one-paragraph actuality-txt" dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </div>
      </div>
      :
      <div className='not-actuality'>Aucune actualite</div>
  )
}

const LastNews = ({ data }) =>{
  return(
    <div className='last-news'>
      <div className="last-news-title">Dernières actualités</div>
      { data && data.map((dt, key)=> <LastNewsItems key={key} posteddate={dt.posteddate} slug={dt.slug} alt={dt.alt} title={dt.title} img={dt.img} />)}
    </div>
  )
}

const LastNewsItems = ({ posteddate, title, img, slug, alt }) =>{
  const handleClick = () => {
    window.location.href = `/actuality/${slug}`
  }

  return(
    <div onClick={() => handleClick()} className='news-item'>
      <img className="news-item-img" alt={alt} src={`https://dkbsign.com/dkbsign_web_api/public/storage/actualities/${img}`}></img>
      <div className="news-item-info">
        <div className="news-item-title">{title}</div>
        <div className="news-info-date">{posteddate}</div>
      </div>
    </div>
  )
}