import axios from "axios"
import { REFERENCEMENT_URL } from "../../../../services/api/URL_API"
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const BASE_URL = 'https://dkbsign.com/dkbsign_web_api/public'

const token = Cookies.get('token_admin')

export const AddReferencement = async ({ pageCible, siteTitle, meta_keywords, meta_description, meta_robots, meta_category, meta_identifier_url, meta_reply_to }) => {
    const formData = new FormData();
    
    formData.append('pageCible', pageCible);
    formData.append('siteTitle', siteTitle);
    formData.append('meta_keywords', meta_keywords);
    formData.append('meta_description', meta_description);
    formData.append('meta_robots', meta_robots);
    formData.append('meta_category', meta_category);
    formData.append('meta_identifier_url', meta_identifier_url);
    formData.append('meta_reply_to', meta_reply_to);

    try {
        const response = await axios.post(`${BASE_URL}${REFERENCEMENT_URL}/store`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        // handle success

        toast.success("Le referencement a été ajouté", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        window.location.reload()
    } catch (error) {
        toast.error("Erreur impossible d'ajouter le referencement", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const EditReferencement = async ({ pageCible, siteTitle, meta_keywords, meta_description, meta_robots, meta_category, meta_identifier_url, meta_reply_to, id }) => {
    const formData = new FormData();
    
    formData.append('pageCible', pageCible);
    formData.append('siteTitle', siteTitle);
    formData.append('meta_keywords', meta_keywords);
    formData.append('meta_description', meta_description);
    formData.append('meta_robots', meta_robots);
    formData.append('meta_category', meta_category);
    formData.append('meta_identifier_url', meta_identifier_url);
    formData.append('meta_reply_to', meta_reply_to);

    try {
        const response = await axios.post(`${BASE_URL}${REFERENCEMENT_URL}/update/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        // handle success

        toast.success("Le referencement a été modifié", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        window.location.reload()
    } catch (error) {
        toast.error("Erreur impossible de modifié le referencement", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const DeleteReferencement = async ({ id }) => {

    try {
        const response = await axios.get(`${BASE_URL}${REFERENCEMENT_URL}/destroy/${id}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        // handle success

        toast.success("Le referencement a été supprimé", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        window.location.reload()
    } catch (error) {
        toast.error("Erreur impossible de supprimer le referencement", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}