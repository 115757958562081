import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function VerifyConnected({ children }){
    const isAuthenticated = Cookies.get('token') ? true : false;    

    if (isAuthenticated) {
      const role = JSON.parse(Cookies.get('user')).role
      
      if(role === 'user')
        return <Navigate to="/home" />
        
      if(role === 'admin')
        return <Navigate to="/adduser" />

    }
      
    return children
}