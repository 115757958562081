import React from "react";
import { Pie } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

/**
 * 
 * Un diagramme Circulaire Personnalisable
 * 
 * @param {data, options} param0 
 * @returns 
 */
export default function CustomPie({ data, options }) {
    return (
        <Pie style={{width:'150px',height:'150px'}} data={data} options={options} />
    );
}