import React from 'react';

import { useSelector } from 'react-redux';
import { docListSelector } from '../../../services/reduxSlices/DocListSlice';

import { useTranslateContext } from '../../../hooks';

/**
 * Affiche un message d'avertissement  en cas de fermeture de la fenêtre modale de signature
 */
const CloseModalMessage = () => {
  const { translate } = useTranslateContext();
  const docList = useSelector(docListSelector);

  const stopUploadDocsMessage = () => {
    if (docList.length == 5) {
      return (
        <>
          <strong>
            {' '}
            <br />
            Pour votre formule souscrite, vous ne pouvez que chargé 5 documents.{' '}
            <br />
            <a href="#service-client">
              {' '}
              Merci de contactez notre service client pour plus d'informations.
            </a>
          </strong>
        </>
      );
    }
  };

  if (docList.length !== 0 && docList.length >= 1) {
    return (
      <>
        <div
          className="alert alert-warning alert-dismissible fade show alert-message"
          role="alert"
        >
          <i className="bi bi-exclamation-triangle-fill me-2"></i>
          <strong>{translate.admin.attention}!</strong>{' '}
          {translate.admin.vousPerdrezToutesModificationsEffectues}
          {stopUploadDocsMessage()}
          {/* <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
        </div>
      </>
    );
  }
};

export default CloseModalMessage;
