import React, { useEffect, useState } from 'react';
import TableWrapper from './TableWrapper';
import RowTable from './RowTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDocument, userSelector } from '../../services/reduxSlices/UserSlice';

/**
 * Composant présent sur la boite de réception 
 * Tableau contenant les documents
 * 
 * @returns ListdocSignTable
 */
const today = new Date();
const tenDaysLater = new Date();
tenDaysLater.setDate(today.getDate() - 20);
const tenDaysLaterFormatted = tenDaysLater.toISOString().split('T')[0];


const ListdocSignTable = () => {
	const { signDocuments } = useSelector(userSelector)

	const [search, setSearch] = useState()
	const [fromDate, setFromDate] = useState(tenDaysLaterFormatted)
	const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0])

	const [page, setPage] = useState(1)
	const dispatch = useDispatch()

	useEffect(() => {
		const data = {
			page,
			start: fromDate,
			end: toDate,
			search: search ?? undefined,
            limit: 20
		}
		dispatch(getDocument(data))
	}, [page])

	const onApplyDateFilter = () => {
		const data = {
			page: page,
			start: fromDate,
			end: toDate,
			search: search ?? undefined,
            limit: 20
		}
		dispatch(getDocument(data))
	}

	const onApplySearch = (e) => {
		e.preventDefault()
		const { value } = e.target
		setSearch(value)

		const data = {
			page: page,
			start: fromDate,
			end: toDate,
			search: value,
            limit: 20
		}
		dispatch(getDocument(data))
	}

	return (
		<TableWrapper onApplySearch={onApplySearch} onApplyDateFilter={onApplyDateFilter} setFromDate={setFromDate} setToDate={setToDate} setPage={setPage} currentPage={page} maxPage={signDocuments.data ? signDocuments.meta.last_page : 0}>
			{
				signDocuments.data && signDocuments.data.map((rw) => {
					return <RowTable data={rw} />
				})
			}
		</TableWrapper>
	);
}

export default ListdocSignTable;