import { configureStore } from "@reduxjs/toolkit"
import { docListSlice } from "./services/reduxSlices/DocListSlice"
import { userSlice } from "./services/reduxSlices/UserSlice"
import { CurrentDocSlice } from "./services/reduxSlices/SignDocSlice"
import {signatureSlice} from "./services/reduxSlices/SignatureSlice";
import { SignModalSlice } from "./services/reduxSlices/SignModalSlice";
import { SignModalStepSlice } from "./services/reduxSlices/SignModalStepSlice";
import { SignataireSlice } from './services/reduxSlices/SignataireSlice'

export default configureStore({
    reducer: {
        user: userSlice.reducer,
        docList: docListSlice.reducer,
        signature: signatureSlice.reducer,
        signModal: SignModalSlice.reducer,
        signModalStep: SignModalStepSlice.reducer,
        currentdoc: CurrentDocSlice.reducer,
        signataires: SignataireSlice.reducer
    }
})
