import React, { useState } from 'react';
import StepNavBarLeft from '../StepNavBarLeft';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import './MainSeulSignModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  clearDocs,
  docListSelector,
} from '../../../../services/reduxSlices/DocListSlice';
import {
  clearSignature,
  signatureSelector,
  setMode,
} from '../../../../services/reduxSlices/SignatureSlice';
import CloseModalMessage from '../CloseModalMessage';

import { useTranslateContext } from '../../../../hooks';
import { SpinnerFour } from '../../../Loader/mini-loader';

const MAX_STEPS = 2;

/**
 * Composant principal de la fenêtre modale seul signataire
 */
const MainSeulSignModal = () => {
  const { translate } = useTranslateContext();
  const [currentStep, setCurrentStep] = useState(1);

  const docList = useSelector(docListSelector);
  const signature = useSelector(signatureSelector);
  const dispatch = useDispatch();

  const handleDisplaySuivantButton = () => {
    if (signature.text !== '' && signature.previewText !== '') return true;
  };

  const handleCloseModal = () => {
    dispatch(clearDocs());
    dispatch(clearSignature());
    setCurrentStep(1);
  };

  return (
    <>
      <div
        className="modal fade"
        id="MainSeulSignModal"
        tabIndex="-1"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered mw-1200px modal-xl">
          <div className="modal-content w-100">
            <div className="modal-header">
              <h2 className="modal-seul-sign-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="50"
                  height="50"
                  fill="currentColor"
                  className="bi bi-filetype-pdf"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                  />
                </svg>
                {`${translate.admin.signezVosDocuments} - ${translate.admin.seulSignataire}`}
              </h2>

              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={() => handleCloseModal()}
              >
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="6"
                      y="17.3137"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y="6"
                      width="16"
                      height="2"
                      rx="1"
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div className="modal-body py-lg-10 px-lg-10">
              <div
                className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid"
                id="kt_modal_create_app_stepper"
              >
                <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                  <div className="stepper-nav ps-lg-10">
                    <StepNavBarLeft currentStep={currentStep} />
                  </div>
                </div>

                <div className="flex-row-fluid py-lg-5 px-lg-15">
                  <CloseModalMessage />
                  <form
                    className="form"
                    noValidate="novalidate"
                    id="kt_modal_create_app_form"
                  >
                    <div
                      className={currentStep === 1 ? 'current' : ''}
                      data-kt-stepper-element="content"
                    >
                      <Step1 />
                    </div>

                    <div
                      className={currentStep === 2 ? 'current' : ''}
                      data-kt-stepper-element="content"
                    >
                      <Step2 />
                    </div>

                    <div className="d-flex flex-stack pt-10">
                      <div className="me-2">
                        {currentStep > 1 && (
                          <button
                            type="button"
                            className="btn btn-lg btn-light-primary me-3"
                            onClick={() => setCurrentStep(currentStep - 1)}
                          >
                            <span className="svg-icon svg-icon-3 me-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="6"
                                  y="11"
                                  width="13"
                                  height="2"
                                  rx="1"
                                  fill="currentColor"
                                />
                                <path
                                  d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                            {translate.admin.precedent}
                          </button>
                        )}
                      </div>

                      <div>
                        
                        {currentStep === MAX_STEPS &&
                          handleDisplaySuivantButton() ? (
                            <Link
                              to="/viewdocument"
                              onClick={() => dispatch(setMode('signer'))}
                              className="btn btn-lg btn-primary"
                            >
                              {translate.admin.terminer}
                              <span className="indicator-label">
                                <span className="svg-icon svg-icon-3 ms-2 me-0">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <rect
                                      opacity="0.5"
                                      x="18"
                                      y="13"
                                      width="13"
                                      height="2"
                                      rx="1"
                                      transform="rotate(-180 18 13)"
                                      fill="currentColor"
                                    />
                                    <path
                                      d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </Link>
                          ) : <></>}

                        {currentStep < MAX_STEPS && (
                          <button
                            type="button"
                            className="btn btn-lg btn-primary"
                            onClick={() => setCurrentStep(currentStep + 1)}
                            disabled={docList.length === 0}
                          >
                            {translate.admin.suivant}
                            <span className="svg-icon svg-icon-3 ms-1 me-0">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="18"
                                  y="13"
                                  width="13"
                                  height="2"
                                  rx="1"
                                  transform="rotate(-180 18 13)"
                                  fill="currentColor"
                                />
                                <path
                                  d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </span>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainSeulSignModal;
