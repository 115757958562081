import React, { useRef, useEffect, useState } from 'react';
import './drop-file-input.css';
import uploadImg from '../../../../../../public/asset/dragdrop/cloud-upload-regular-240.png';
import { useSelector, useDispatch } from 'react-redux';
import {
  addDocs,
  docListSelector,
  removeDoc,
  setDocs,
} from '../../../../../../services/reduxSlices/DocListSlice';
import { Document, Page, pdfjs } from 'react-pdf';
import fileSize from 'filesize';

import { useTranslateContext } from '../../../../../../hooks';

/**
 * Composant de chargement des documents pour la signature
 */
const DropFileInput = () => {
  const { translate } = useTranslateContext();
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  const dispatch = useDispatch();
  const truncate = (str) =>
    str.length > 10 ? str.substring(0, 10) + '...' : str;

  const wrapperRef = useRef(null);
  const docList = useSelector(docListSelector);
  // var cinqPremierDocs = docList.splice(0, 5)//2
  // var compteDocRestant = docList.filter(value => value > cinqPremierDocs.length)
  // docList.splice(docList.length - compteDocRestant.length, compteDocRestant.length)

  useEffect(() => {
    if (docList.length > 5) {
      var newDocList = docList.slice(0, 5);
      dispatch(setDocs(newDocList));
    }
  }, [docList]);

  const onDragEnter = () => wrapperRef.current.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');
  const onDrop = () => wrapperRef.current.classList.remove('dragover');
  const fileRemove = (file) => dispatch(removeDoc(file));

  const onFileDrop = (e) => {
    if (e.target.files) {
      dispatch(addDocs(e.target.files));
    }
  };

  return (
    <>
      <div
        ref={wrapperRef}
        className="drop-file-input col-md-2"
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        <div className="drop-file-input__label">
          <img src={uploadImg} alt="" />
          <p>{translate.admin.chargezVosDocumentsIci}!</p>
        </div>
        <input
          type="file"
          onChange={onFileDrop}
          multiple
          accept="application/pdf"
        />
      </div>

      {docList.length > 0 ? (
        <div className="col-md-12 document-card">
          <p className="drop-file-preview__title mt-3">
            {translate.admin.listeDocumentsCharges}
            <span className="text-danger"> ( {docList.length} ) </span>
          </p>

          <div className="drop-file-preview w-100 preview-items-position">
            {docList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <div className="c-box d-flex flex-column">
                  <div className="d-flex mb-4 align-items-center justify-content-center sub-g">
                    <span
                      title="Retirer le document"
                      className="drop-file-preview__item__del me-3"
                      onClick={() => fileRemove(item)}
                    >
                      X
                    </span>
                    <p className="mb-0 w-75">
                      {truncate(item.name)}
                      <br />({fileSize(item.size)})
                    </p>
                  </div>
                  <Document file={URL.createObjectURL(item)} className="docs">
                    <Page pageNumber={1} width={100} height={100} />
                  </Document>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DropFileInput;
