import React, { useEffect } from 'react'
import Container from "../../components/Container/Container"
import ListdocSignTable from "../../components/ListdocSignTable/ListdocSignTable"

/**
 * Page du menu Dashbord
 * @returns Dashboard
 */
const Dashboard = () => {

    useEffect(() => {
        let script = document.createElement("script")
        script.src = "./assets/js/scripts.bundle.js"
        document.body.appendChild(script)
    }, [])

    return (
        <Container>
            <ListdocSignTable />
        </Container>
    )
}

export default Dashboard