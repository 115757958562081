import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { IoCall } from 'react-icons/io5'
import { HiMailOpen } from 'react-icons/hi'
import { Helmet } from "react-helmet";
import { FaMapMarkerAlt } from 'react-icons/fa'
import Fade from 'react-reveal/Fade';
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from 'formik';
import axios from 'axios'
import SpinnerPage from './SpinnerPage';
import * as Yup from "yup";
import './styles.css'

var empty_img = {
  "img1": null,
  "alt1": null,
  "img2": null,
  "alt2": null,
  "img3": null,
  "alt3": null,
  "img4": null,
  "alt4": null,
  "img5": null,
  "alt5": null,
  "img6": null,
  "alt6": null,
  "img7": null,
  "alt7": null,
  "img8": null,
  "alt8": null,
  "img9": null,
  "alt9": null,
  "img10": null,
  "alt10": null,
  "img11": null,
  "alt11": null,
  "img12": null,
  "alt12": null,
  "img13": null,
  "alt13": null,
  "img14": null,
  "alt14": null,
  "img15": null,
  "alt15": null,
  "img16": null,
  "alt16": null,
  "img17": null,
  "alt17": null,
  "img18": null,
  "alt18": null,
  "img19": null,
  "alt19": null,
  "img20": null,
  "alt20": null
}

const PAGE_NAME = 'APROPOS'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingAPropos() {
  const [referencement, setReferencement] = useState(null)
  const [IMAGES, SETIMAGES] = useState(null)

  useEffect(() => {
    GetReferencement().then(source => setReferencement(source))
    GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
  }, [])

  return (
    IMAGES ?
      <div className='a-propos-cover'>
        <LandingWrapper>
          <Helmet>
            <title>
              {
                referencement ?
                  referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                  : "DKBSign"
              }
            </title>
            <meta
              name="description"
              content={
                referencement ?
                  referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                  : ""
              }
            />

            <meta
              name="keywords"
              lang="fr"
              content={
                referencement ?
                  referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                  : ""
              }
            />

            <meta name="robots" content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                : ""
            }
            />
            <meta name="category" content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                : ""
            }
            />

            <meta name="identifier-url" content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                : ""
            }
            />

            <meta name="reply-to" content={
              referencement ?
                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                : ""
            }
            />
          </Helmet>
          <div className="a-propos">
            <div className="landing_apropos-content">
              <Fade duration={3000} left>
                <Title />
              </Fade>
              <Description IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
            </div>
          </div>
          <ContactUs />
        </LandingWrapper>
      </div>
      :
      <div className='global-loader'>
        <SpinnerPage />
      </div>
  )
}

const Title = () => {
  return (
    <div className="landing-apropos__title">
      <p className='bold-title'>DKBSign La référence n°1</p>
      <p>pour la gestion des transactions numériques®</p>
    </div>
  )
}

const Description = ({ IMAGES }) => {
  return (
    <div className="landing-apropos__description">
      <Fade duration={4000} left>
        <div className="landing-apropos__description-txt">
          Les entreprises qui ont réussi ont pour tradition d'adopter les nouvelles technologies en remplacement des processus manuels, lents et complexes, afin d'améliorer l'efficacité, de réduire les coûts et d'augmenter la satisfaction des clients. Sauf dans le cas de la collecte et de l'utilisation d'informations pour les transactions, où ce sont toujours les méthodes archaïques qui prévalent, surtout lorsque des signatures sont requises. Alors que les entreprises ont recours à des processus automatisés pour les opérations intervenant avant et après la signature effective, un grand nombre d'entre elles continuent de s'appuyer inutilement sur le papier pour conclure des affaires. Cela ralentit l'activité, retarde les résultats et nuit à l'expérience du client. Il ne devrait pas en être ainsi. Il n'y a aucune raison qu'il en soit ainsi. La plateforme de gestion des transactions numériques de DKBSign permet aux entreprises d'adopter des processus entièrement numériques, du début à la fin, et ainsi d'accélérer les transactions et de réduire les coûts, pour la plus grande satisfaction des clients, des partenaires, des fournisseurs et des employés.
        </div>
      </Fade>

      <Fade duration={4000} right>
        <img
          src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/signature-DKBSign.png"}
          alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
          className='landing-apropos__description-img'
        />
      </Fade>
    </div>
  )
}

const ContactUs = () => {
  const [initialValues] = useState({
    prenom: '',
    nom: '',
    email: '',
    contact: '',
    etr_name: '',
    ctr_interet: '',
    atr_preciser: ''
  })

  const FormSchema = Yup.object().shape({
    prenom: Yup.string()
      .required('Champs requis'),

    nom: Yup.string()
      .required('Champs requis!'),

    email: Yup.string()
      .required('Champs requis!').email('Le format du mail est invalide'),

    contact: Yup.string()
      .required('Champs requis!'),

    etr_name: Yup.string()
      .required('Champs requis!'),

    ctr_interet: Yup.string()
      .required('Champs requis!'),

    atr_preciser: Yup.string()
      .notRequired()
  });

  const [showOther, setShowOther] = useState(false)

  const onOptionChange = (e) => {
    const { value } = e.target

    if (value === 'Autres') setShowOther(true)
    else setShowOther(false)
  }

  const sendData = (data) => {
    const { prenom, nom, email, contact, etr_name, ctr_interet, atr_preciser } = data;
    const queryParams = new URLSearchParams({
      prenom,
      nom,
      email,
      contact,
      etr_name,
      ctr_interet,
      atr_preciser
    }).toString();

    const url = `https://dkbsign.com/dkbsign_web_api/public/api/contact?${queryParams}`;

    axios.get(url)
      .then(response => {
        // Traitement de la réponse
        console.log(response.data);
        toast.success("Formulaire envoyé veuillez consulter vos mails", {
          position: "bottom-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(error => {
        // Gestion de l'erreur
        console.error(error);
      });
  };

  const [infos] = useState([
    {
      id: 1,
      icon: <IoCall size={25} color='white' />,
      info1: '(+225) 27 22 46 47 24',
      info2: ''
    },
    {
      id: 2,
      icon: <HiMailOpen size={25} color='white' />,
      info1: 'infos@dkbsolutions.com',
      info2: 'infos@dkbsign.com'
    },
    {
      id: 3,
      icon: <FaMapMarkerAlt size={25} color='white' />,
      info1: 'Abidjan, Cocody, Riviera Golf, Lot 92B, ilot 6,',
      info2: 'rue E127, pavillon Granit, 01 B.P. 6106 Abidjan 01'
    }
  ])

  const [options] = useState([
    {
      id: 1,
      label: 'Token',
      value: 'Token'
    },
    {
      id: 2,
      label: 'API',
      value: 'API'
    },
    {
      id: 3,
      label: 'Plateforme Web',
      value: 'Plateforme Web'
    },
    {
      id: 4,
      label: 'Certificat',
      value: 'Certificat'
    },
    {
      id: 5,
      label: 'Autres( a préciser )',
      value: 'Autres'
    },
  ])

  return (
    <Formik
      initialValues={initialValues}

      validationSchema={FormSchema}

      onSubmit={async (values) => {

        const dataSend = {
          prenom: values.prenom,
          nom: values.nom,
          email: values.email,
          contact: values.contact,
          etr_name: values.etr_name,
          ctr_interet: values.ctr_interet,
          atr_preciser: values.atr_preciser,
        }

        sendData(dataSend)
      }}
    >

      {({ handleChange, handleBlur, handleSubmit, values }) => (
        <Form>
          <div className="landing-apropos__contact-us">
            <Fade duration={3000} left>
              <div className="landing-apropos__integration-header">
                <h2>CONTACTEZ NOUS</h2>
              </div>
            </Fade>

            <Fade duration={3000} top>
              <div className="contact-us_content">
                <div className="contact-us_content_info">
                  {infos.map(item => <InfoBlock key={item.id} item={item} />)}
                </div>

                <div className="contact-us_content_form">
                  <div className="content_form">
                    <div className="content_form-input_area">
                      <Input label='Prénom*' type='text' onChange={handleChange} value={values.prenom} onBlur={handleBlur} placeholder='Prénom' name='prenom' />
                      <Input label='Nom*' type='text' onChange={handleChange} value={values.nom} onBlur={handleBlur} placeholder='Nom' name='nom' />
                    </div>

                    <div className="content_form-input_area">
                      <Input label='Email professionnel*' type='email' value={values.email} onChange={handleChange} onBlur={handleBlur} placeholder='Email' name='email' />
                    </div>

                    <div className="content_form-input_area">
                      <Input label='Numéro de Télephone' type='text' value={values.contact} onChange={handleChange} onBlur={handleBlur} placeholder='Numéro de téléphone' name='contact' />
                      <Input label="Nom de l'entreprise " type='text' value={values.etr_name} onChange={handleChange} onBlur={handleBlur} placeholder='Entreprise' name='etr_name' />
                    </div>

                    <div className="content_form-input_area">
                      <Select label="Quelle est votre centre d'intérêt ?" value={values.ctr_interet} onChange={handleChange} onBlur={handleBlur} onOptionChange={onOptionChange} options={options} name='ctr_interet' />
                    </div>
                    {
                      showOther &&
                      <div className="content_form-input_area">
                        <Input label='Précisé' type='text' onChange={handleChange} onBlur={handleBlur} placeholder='Autre' name='atr_preciser' />
                      </div>
                    }
                    <p className='content_form-policy'>
                      En cliquant sur le bouton "Envoyer", vous acceptez que vos données soient utilisées conformément à <a href="#">la politique de confidentialité de DkbSign</a>.
                    </p>
                    <button
                      onClick={handleSubmit}
                      type='submit'
                      className='content_form-send'
                      href="#">Envoyer</button>
                  </div>
                </div>
              </div>
            </Fade>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const InfoBlock = ({ item }) => {
  return (
    <div className="contact-us_content_info-items">
      <div className="contact-us_content_info-items-icon">
        {item.icon}
      </div>
      <div className="contact-us_content_info-items-txt">
        <div className="contact-us_content_info-items-txt-p">{item.info1}</div>
        <div className="contact-us_content_info-items-txt-p">{item.info2}</div>
      </div>
    </div>
  )
}

const Input = ({ label, type, placeholder, name, value, onChange, onBlur }) => {
  return (
    <div className="content_form-input">
      <label className="content_form-label" htmlFor={name}>{label}</label>
      <input className="content_form-element" onChange={onChange} onBlur={onBlur} value={value} type={type} name={name} placeholder={placeholder} />
      <ErrorMessage className="error-msg" name={name} component={'div'} />
    </div>
  )
}

const Select = ({ label, name, options, onOptionChange, value, onChange, onBlur }) => {
  return (
    <div className="content_form-input">
      <label className="content_form-label" htmlFor={name}>{label}</label>
      <select
        onBlur={onBlur}
        onChange={(e) => {
          onOptionChange(e)
          onChange(e)
        }} value={value} className="content_form-element" name={name}>
        <option value={null}>{"Choisissez une option"}</option>
        {options.map(item => <option key={item.id} value={item.value}>{item.label}</option>)}
      </select>
      <ErrorMessage className="error-msg" name={name} component={'div'} />
    </div>
  )
}