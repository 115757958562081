export const TRANSLATE_FR = {
  landingPage: {
    accueil: 'ACCUEIL',
    produits: 'PRODUITS',
    solutions: 'SOLUTIONS',
    api: 'API',
    token: 'TOKEN',
    plateform: 'PLATEFORM',
    tarif: 'TARIF',
    reference: 'REFERENCE',
    propos: 'A PROPOS',
    fr: 'FR',
    espaceClient: 'ESPACE CLIENT',
    connexion: 'CONNEXION',
    inscrivezVous: 'INSCRIVEZ-VOUS A LA NEWSLETTER',
    descriptionFooter: `DKBSign est une plateforme mise à la disposition des utilisateurs
    pour leur permettre de faire de la signature électronique afin de
    conférer une valeur juridique légale aux documents signés. DKBSign
    est un produit de DKB Solutions tiers de confiance agréé par
    l'ARTCI, engagé à garantir la sécurité, la confidentialité,
    l'intégrité des transactions sur internet`,
    envoyezSigner: 'Envoyez et signez',
    vosDocumentsEnLigne: 'vos documents en ligne.',
    OuQueVousSoyezEnTouteSimplicite: 'Où que vous soyez, en toute simplicité.',
    rapideFacileEtMoindreCout:
      'C’est rapide, facile et à moindre cout : essayez DKBSign dès maintenant.',
    essayer: 'ESSAYER',
    ilsNousFontConfiance: 'Ils nous font confiance.',
    signezSimplementVosDocumentsEnQuelquesClics:
      'Signez simplement vos documents en quelques clics !',
    telechargerVosDocuments: 'Télécharger vos documents',
    designezLesSignataires: `Désignez les signataires, l'emplacement des signatures et envoyez.`,
    signezEtFaitesSigner: 'Signez et faites signer',
    chaquesSignataireParEmailSignerSaisieCodeSMS: `Chaques signataire est invité par e-mail a signer grâce a la saisie d'un code SMS.`,
    conservezVosDocuments: 'Conservez vos documents',
    notreServiceDeConservationValeurProbanteSecurise:
      'Notre service de conservation a valeur probante sécurise le stockage du document signé.',
  },
  others: {
    actualites: 'Actualités',
  },
  admin: {
    first_last_name: 'Nom et prénom(s)',
    normal: 'Normal',
    urgent: 'Urgent',
    tres_urgent: 'Très urgent',
    signatureDelay: 'Délais de signature',
    applySameDateForAll: 'Appliquer le même délais pour tout les signataires',
    priorite: 'Priorité',
    parametre: 'Paramétrés',
    configurer: 'Configurer',
    tableauDeBord: 'Tableau de bord',
    boiteDeReception: 'Boite de réception',
    mesDocuments: 'Mes documents',
    rapport: 'Rapport',
    contact: 'contact',
    signatureRestante: 'Signature restante',
    signaturesRestantes: 'Signatures restantes',
    documentsTotal: 'Total documents',
    contactEnregistre: 'Contact enregistré',
    documentsSigneAujourd: `Documents signé aujourd'hui`,
    statistiqueDesDocuments: 'Statistique des documents',
    CourbeDesDocumentsEnFonctionsDesStatus:
      'Courbe des documents en fonctions des status',
    signer: 'Signer',
    seulSignataire: 'Seul Signataire',
    faireSigner: 'Faire signer',
    signerFaireSigner: 'Signer & Faire Signer',
    documentsSignes: 'Documents signés',
    documentsRefuses: 'Documents refusés',
    documentsEnAttentes: 'Documents en attentes',
    refuse: 'Réfusé',
    signe: 'Signé',
    attente: 'En attente',
    encours: 'En cours',
    vosDocuments: 'Vos documents',
    voirTout: 'Voir tout',
    documentSigne: 'Document signé',
    documentsEnAttente: 'Document en attente',
    DocumentsEnCours: 'Documents en cours',
    DocumentsRefuse: 'Document refusé',
    signezVosDocuments: 'Signez vos documents PDF',
    chargezVosDocuments: 'Chargez vos documents PDF',
    signature: 'Signature',
    definissezVotreSignature: 'Définissez votre signature',
    chargezVosDocumentsIci: 'Chargez vos documents PDF ici',
    chargement: 'Chargement',
    suivant: 'Suivant',
    terminer: 'Terminer',
    termine: 'Terminer',
    refuser: 'Réfuser',
    vousPerdrezToutesModificationsEffectues:
      'si vous fermez la fenêtre, vous perdrez toutes les modifications effectuées.',
    attention: 'Attention',
    dataMonth: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    rechercherUnDocument: 'Rechercher un document',
    nom: 'Nom',
    entrezLeNom: 'Entrez le nom',
    prenom: 'Prénoms',
    entrezLePrenom: 'Entrez le prénom',
    telephone: 'Telephone',
    email: 'Email',
    entrezEmail: `Entrez l'email`,
    motif: 'Motif',
    entrezVotreContacts: 'Entrez votre contacts',
    rechercherUnContact: 'Rechercher un contact',
    rechercherUnUtilisateur: 'Rechercher un utilisateur',
    rechercherUneEntreprise: 'Rechercher une entreprise',
    rechercherUnCertificat: 'Rechercher un certificat',
    tous: 'Tous',
    recu: 'Récu le',
    appliquerLeFiltre: 'Appliquer le filtre',
    voirLeDocument: 'Voir le document',
    importerContact: 'Importer contact',
    ajouterContact: 'Ajouter contact',
    modifierContact: 'Modifier contact',
    utilisateur: 'Utilisateur',
    fonction: 'Fonction',
    entreprise: 'Entreprise',
    role: 'Role',
    datenscription: `Date d'inscription`,
    souvenirDeMoi: 'Se souvenir de moi',
    connect: 'se connecter',
    listeDocumentsCharges: 'Liste des documents chargés',
    precedent: 'Précédent',
    deinition: 'Définition',
    chargerUneImage: 'Charger une image',
    zoneDeSignature: 'Zone de signature',
    paraphe: 'Paraphe',
    effacer: 'Effacer',
    codeAuthentification: `Code d'authentification`,
    ajoutezVosSignataires: 'Ajoutez vos signataires',
    signataires: 'Signataires',
    fullName: 'Nom & Prénoms',
    ajouter: 'Ajouter',
    cliquezPourSigne: 'Sélectionnez votre signature',
    votreQrcode: 'Votre Qrcode',
    signataire: 'Signataire',
    outils: 'Outils',
    sur: 'sur',
    cliquezSurLeBouton: `Sélectionner l’endroit ou apparaîtra votre signature.`,
    selectionDeSignature: 'Sélection de signature',
    fermer: 'Fermer',
    selectionner: 'Sélectionner',
    enSignantDocumentAttesteAvoirLuComprisEtAcceptLes: `En signant ce document, j'atteste avoir lu, compris et accepté les`,
    conditionsRelativesSignatureElectronique:
      'conditions relatives à la signature électronique',
    leDocument: 'Le document',
    nApasEteSigne: `n'a pas été signé`,
    nOntPasEteCorrectementRenseignes: `n'ont pas été correctement renseignés.`,
    etreVousSurAvoirLu: `Êtes vous sûres d'avoir lu l'intégralité de(s) document(s) précédents ?`,
    oui: 'Oui',
    non: 'Non',
    info: 'Infos',
    voir: 'Voir',
    annuler: 'Annuler',
    supprimer: 'Supprimer',
    soumettre: 'Soumettre',
    chargerVosContact: 'Charger vos contact',
    valider: 'Valider',
    actualiteEteAjoute: `L'actualité a été ajouté`,
    erreurImpossibleAjouterActualite: `Erreur impossible d'ajouter l'actualité`,
    actualiteEteModifie: `L'actualité a été modifié`,
    erreurImpossibleDeModifieActualite: `Erreur, impossible de modifié l'actualité`,
    actualitéEteSupprime: `L'actualité a été supprimé`,
    erreurImpossibleDeSupprimerActualite: `Erreur, impossible de supprimer l'actualité`,

    imageEteAjouteAvecSucces: `L'image a été ajouté avec succès`,
    erreurImpossibleAjouterImage: `Erreur impossible d'ajouter l'image`,
    imageAEteModifie: `L'image a été modifié`,
    erreurImpossibleDeModifieImage: `Erreur impossible de modifié l'image`,
    lesImagesOntEteSupprime: `Les images ont été supprimé`,
    erreurImpossibleDeSupprimerLesImages: `Erreur impossible de supprimer les images`,
    emailOuMotDePasseIncorrect: `Email ou mot de passe incorrect`,

    veuillezSaisirToutLesChamps: 'Veuillez saisir tout les champs',
    leMotDasseDoitContenirAuMinimumCaracteres:
      'Le mot de passe doit contenir au minimum 8 caractères',

    votreDocumentEstEnCoursDeSignature:
      'Votre document est en cours de signature',
    signerLeDocument: 'Signer le(s) document(s)',
    refuserLeDocument: 'Réfuser le(s) document(s)',
    selectionnezLeDocument: 'Selectionnez le(s) document(s)',
    veuillezSelectionnerLesDocumentsRefuser:
      'Veuillez selectionner les documents a réfuser',
    veuillezSelectionnerAuMoinsUnDocument:
      'Veuillez selectionner au moins un document',
    enregistrerVotreSignature: 'Enregistrer votre signature',
    tousLesDocumentsOnSigne: 'Tous les documents on été signé'
  },
};
