import React, { useEffect, useState } from 'react'
import Container from "../../components/Container/Container"
import Document from '../../components/Documents/Document'
import { getDocument, userSelector } from '../../services/reduxSlices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';

/**
 * Page du menu Boite de reception --> Document en attente de signature
 * @returns SignDocs
 */
export default function InProgressDocument() {
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const FilterInProgressDoc = (data) => {
    const inProgressDocs = data.filter((doc) => doc.statut === 'en_cours')
    return inProgressDocs
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDocument({ page, search: search ?? undefined, limit: 20 }))
  }, [page])

  const onApplySearch = (e) => {
    e.preventDefault()
    const { value } = e.target
    setSearch(value)

    const data = {
      page: page,
      start: undefined,
      end: undefined,
      search: value,
      limit: 20
    }
    dispatch(getDocument(data))
  }

  const { signDocuments } = useSelector(userSelector)

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

  return (
    <Container>
      {
        signDocuments.data &&
        <>
          <h1 style={{ color: 'wheat', fontWeight: '700' }}>Document en cour de signature  ({FilterInProgressDoc(signDocuments.data).length})</h1>
          <Document onApplySearch={onApplySearch} data={FilterInProgressDoc(signDocuments.data)} maxPage={signDocuments.meta.last_page} setPage={setPage} currentPage={page} />
        </>
      }
    </Container>
  )
}
