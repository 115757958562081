export const DATA_DOCUMENT = {
    labels: ["Réfuser", "Signer","En attende","En cour" ],
    datasets: [
      {
        label: "Pie",
        data: [0, 0, 0, 0],
        fill: true,
        backgroundColor: [
          '#F1416C',
          '#009EF7',
          'rgb(228, 230, 239)',
          'rgb(75, 192, 192)'
      ]
      }
    ],
}

export const DATA_PLOT = {
  labels: ["Lundi","Mardi","Mercredi","Jeudi","Vendredi","Samedi","Dimanche"],
  datasets: [
    {
      label: "signé",
      data: [0,0,0,0,0,0,0],
      fill: true,
      backgroundColor: ["rgb(91,155,213)"],
    }
  ],
};

export const DATA_LINE = {
  labels: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"],
  datasets: [{
    label: 'Documents signé',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    fill: false,
    borderColor: 'rgb(75, 192, 192)',
    tension: 0.3
  },
  {
    label: 'Documents refusé',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    fill: false,
    borderColor: 'rgb(228, 230, 239)',
    tension: 0.3
  },
  {
    label: 'Documents en attente',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    fill: false,
    borderColor: 'rgb(91,155,213)',
    tension: 0.3
  },
]
}

export const DETAILS_RAPPORT = {
  labels: ["Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre"],
  datasets: [
    {
      label: "Document signé",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(91,155,213)"],
    },
    {
      label: "Document supprimé",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(237,125,49)"],
    },
    {
      label: "Document rejeté",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(165,165,165)"],
    }
  ],
};

export const DETAILS_DOCS_ADMIN = {
  labels: ["DKBS","Orange","Free","F-Society","Microsoft","Google","Tesla","Apple","Dell","Panasonic","Djamo","Wave"],
  datasets: [
    {
      label: "Document signé",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(91,155,213)"],
    },
    {
      label: "Document supprimé",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(237,125,49)"],
    },
    {
      label: "Document rejeté",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      fill: true,
      backgroundColor: ["rgb(165,165,165)"],
    }
  ],
};