import React from 'react';
import DropFileInput from './drop-file-input/DropFileInput';

/**
 * Etape 1 (chargement des documents) de la fenêtre modale faire signer
 */
const Step1 = () => {
    return (
        <div className="w-100">
            <div className="fv-row mb-10 row w-100">
                <DropFileInput />
            </div>
        </div>
    );
}

export default Step1;