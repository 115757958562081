import React from 'react'
import Cookies from 'js-cookie'
import axios from 'axios'

import { useTranslateContext } from '../../hooks';

const baseUrl = 'https://dkbsign.com/new_api_dkbsign/public/api'

export default function Row({ data }) {
    const { translate } = useTranslateContext();
    const user = JSON.parse(Cookies.get('user'))
    const token = Cookies.get('token')

    const showDocName = (url_) => {
        const url = new URL(url_)
        const paths = url.pathname.split('/')
        const filename = paths[paths.length - 1]
        return filename
    }

    function extractFileNameFromURL(url) {
        const urlParts = url.split('/');

        const fileName = urlParts[urlParts.length - 1];

        const cleanFileName = fileName.split('?')[0];

        return cleanFileName;
    }

    var getDateTime = function (timestamp) {
        var date = String(timestamp)

        var _date = date.split('T')[0]
        var _time = date.split('T')[1].split('.')[0]

        return _date + ' ' + _time
    }

    const goToConsulte = (e) => {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        axios.patch(`${baseUrl}/signatures/consulter?document=${data.document}&token=${user.telephone}`, null, config)
            .then(res => {
                console.log('Your document has been wathed')
                window.location.reload()
            })
            .catch(err => console.error(err))
    }

    var entreprise = user.entreprise ? user.entreprise.raison_sociale : null

    return (
        <tr>
            <td>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-50px me-5">
                        <img src="https://thumbs.dreamstime.com/b/documents-ou-contrat-de-bureau-sur-un-fond-bleu-136090443.jpg" className="" alt="" />
                    </div>
                    <div className="d-flex justify-content-start flex-column">
                        <a href={`${entreprise === 'ANRMP' ? `https://www.dkbsigns.com/API3/Api_dkbsign3/include/DOCSIGN_ARNMP/${extractFileNameFromURL(data.document)}` : data.document}`}
                            className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{showDocName(data.document)}</a>
                        <span className="text-muted fw-bold text-muted d-block fs-7">12 Mo</span>
                    </div>
                </div>
            </td>
            <td>
                <a target='_blank' onClick={(e) => goToConsulte(e)} href="#" className="text-dark fw-bolder text-hover-primary d-block mb-1 fs-6">{
                    data.statut === 'en_attente' ? getDateTime(data.created_at) : getDateTime(data.updated_at)
                }</a>
                <span className="text-muted fw-bold text-muted d-block fs-7"></span>
            </td>
            <td>
            {data.statut === "en_attente" && <span className="badge en_attente-btn">{translate.admin.attente}</span>}
                {data.statut === "en_cours" && <span className="badge en_cour-btn">{translate.admin.encours} </span>}
                {data.statut === "signe" && <span className="badge signer-btn">{translate.admin.signe} </span>}
                {data.statut === "refuse" && <span className="badge refuse-btn">{translate.admin.refuse} </span>}
            </td>
        </tr>
    )
}
