import React from 'react'
import './styles.css'

/**
 * Composant de chargement designé en CSS
 * Utilisé pour illustré un chargement 
 * 
 * @returns Loader
 */
export default function Loader() {
  return (
    <div className="spinner-three">
        <span className="loader-double-1">
            <span className="loader-double-2">
                <span className="loader-double-3"></span>
            </span>
        </span>
    </div>
  )
}
