import React, { useEffect, useState } from 'react'
import Container from "../../components/Container/Container"
import Document from '../../components/Documents/Document'
import { getDocument, userSelector } from '../../services/reduxSlices/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css'

/**
 * Page du menu Boite de reception --> Tout les documents
 * @returns AllDocs
 */

export default function AllDocs() {
  const [status, setStatus] = useState("0")
  const [search, setSearch] = useState()
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDocument({
      page,
      search: search ?? undefined,
      status: status === "0" ? undefined : status,
      limit: 20
    }))
  }, [page])


  const onApplySearch = (e) => {
    e.preventDefault()
    const { value } = e.target
    setSearch(value)

    const data = {
      page: page,
      start: undefined,
      end: undefined,
      search: value,
      status: status === "0" ? undefined : status,
      limit: 20
    }
    dispatch(getDocument(data))
  }

  const onApplyFilter = (value) => {
    setStatus(value)

    const data = {
      page: page,
      start: undefined,
      end: undefined,
      search: search,
      status: value === "0" ? undefined : value,
      limit: 20
    }
    dispatch(getDocument(data))
  }

  const { signDocuments } = useSelector(userSelector)

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

  return (
    <Container>
      {
        signDocuments.data &&
        <>
          <Document onApplySearch={onApplySearch} data={signDocuments.data} maxPage={signDocuments.meta.last_page} setPage={setPage} onApplyFilter={onApplyFilter} currentPage={page} status={status} />
        </>
      }
    </Container>
  )
}
