import React, {useState} from 'react'
import { saveContact, patchContact } from '../../../services/reduxSlices/UserSlice'
import { useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './style.css'
import { useTranslateContext } from '../../../hooks';

export default function Forms({showForm, setShowForm, data}) {

  const {translate} = useTranslateContext();
  
    const [initialValues, setInitialValues] = useState({ 
        nom: data ? data.nom : '', 
        prenom: data ? data.prenoms : '', 
        email: data ? data.email : '', 
        telephone: data ? data.fonction : '', 
    })
    
    // useEffect(()=>{
    //   var kt_body = document.getElementById('kt_body')
    //   showForm ?  kt_body.style.overflow = 'hidden' :  kt_body.style.overflow = 'overflow'
    // },[showForm])
  
    const dispatch = useDispatch()

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const ContactsSchema = Yup.object().shape({
      nom: Yup.string()
        .min(2, 'Trop court!')
        .max(30, 'Trop long!')
        .required('Champs requis'),

      prenom: Yup.string()
        .min(2, 'Trop court!')
        .max(30, 'Trop long!')
        .required('Champs requis'),

      email: Yup.string()
        .email('email invalide')
        .required('Champs requis!'),

      telephone : Yup.string()
          .min(10,'Minimum 10 caractères')
          .required('Champs requis')
    });

  return (
      <div
            className={showForm ? "modal fade show" : "modal fade"}
            id="kt_modal_new_target"
            tabIndex={-1}
            style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
          >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content rounded" style={{width: '100%'}}>
            <div className="modal-header pb-0 border-0 justify-content-end">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={()=>setShowForm(false)}
              >
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
            
              <Formik
                  initialValues={initialValues}
                          
                  onReset={true}
                  validationSchema = {ContactsSchema}
                              
                  onSubmit={async (values, { setSubmitting }) => {

                    const dataSend = {
                      id : data ? data.id : 0,
                      nom: values.nom, 
                      prenoms: values.prenom, 
                      email: values.email, 
                      telephone: String(values.telephone)
                    }
                    
                    data ? dispatch(patchContact(dataSend)) : dispatch(saveContact(dataSend))
                  }}
              >
            
                {({ isSubmitting, handleChange, handleBlur, values, handleSubmit, setFieldValue }) => (
                  <Form>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">
                    <form
                        id="kt_modal_new_target_form"
                        className="form fv-plugins-bootstrap5 fv-plugins-framework"
                        action="#"
                      >
                        <div className="mb-13 text-center">
                          <h1 className="mb-3">{data ? translate.admin.modifierContact: translate.admin.ajouterContact}</h1>
                        </div>

                        <div className="row mb-5">
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">{translate.admin.nom}</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder={translate.admin.entrezLeNom}
                              name="nom"
                              value={values.nom} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="nom" component={'div'} />
                          </div>
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">{translate.admin.prenom}</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder={translate.admin.entrezLePrenom}
                              name="prenom"
                              value={values.prenom} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="prenom" component={'div'} />
                          </div>
                        </div>

                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">{translate.admin.email}</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder={translate.admin.entrezEmail}
                            name="email"
                            value={values.email} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                          />
                          <ErrorMessage className="error-msg" name="email" component={'div'} />
                        </div>

                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">Contacts</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder={translate.admin.entrezVotreContacts}
                            name="telephone"
                            value={values.telephone} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                          />
                          <ErrorMessage className="error-msg" name="telephone" component={'div'} />
                        </div>

                        <div className="text-center">
                          <button
                            type="reset"
                            id="kt_modal_new_target_cancel"
                            onClick={()=>setShowForm(false)}
                            className="btn btn-light me-3"
                          >
                            {translate.admin.annuler}
                          </button>
                          <button
                            type="submit"
                            onClick={(e)=>{
                              setShowForm(false)
                              e.preventDefault()
                              handleSubmit()
                            }}
                            data-bs-dismiss="modal"
                            className="btn btn-primary"
                          >
                            <span className="indicator-label">{translate.admin.soumettre}</span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                        <div />
                      </form>
                    </div>
                  </Form>
                )}
              </Formik>

          </div>
        </div>
      </div>
  )
}
