import React from 'react'
import { MdDelete, MdOutlineModeEditOutline } from "react-icons/md";

function trimString(chaine) {
    if (chaine.length > 25) {
        return chaine.slice(0, 25) + '...';
    } else {
        return chaine;
    }
}

export default function Row({ item }) {
    return (
        <tr className="even">
            <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                    />
                </div>
            </td>

            <td>{item.nom}</td>
            <td>{item.prenom}</td>

            <td data-order="2022-08-17T08:23:58+00:00">
                <div className="badge badge-light fw-bolder">{item.email}</div>
            </td>
            <td data-order="2022-08-17T08:23:58+00:00">
                <div className="badge badge-light fw-bolder">{item.contact}</div>
            </td>

            <td>
                <div className="badge badge-light-success fw-bolder">
                    {item.etr_name}
                </div>
            </td>
            <td>
                <div className="badge badge-light-success fw-bolder">
                    {item.ctr_interet}
                </div>
            </td>
            <td>
                <div className="badge badge-light-success fw-bolder">
                    {item.atr_preciser}
                </div>
            </td>
        </tr>
    )
}
