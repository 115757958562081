import React, { useState } from 'react'
import Loader from '../Loader/Loader';
import './style.css'
import { useTranslateContext } from '../../hooks';

const VerifyCode = (code, setShowModal, setLoading, setError) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const signataire = urlParams.get('signataire')

    const formData = new FormData();
    formData.append("code_auth", code);

    var requestOptions = {
        method: 'POST',
        body: formData,
        redirect: 'follow'
    };

    fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signataires/${signataire}`, requestOptions)
        .then(result => {
            if (result.status === 200) {
                setShowModal(false)
                setLoading(false)
            } else {
                setLoading(false)
                setError('Code Invalide')
            }
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
            setError('Code Invalide')
        });
}

export default function CodeAuthmModal({ showModal, setShowModal, setCodeAuth, codeAuth }) {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const { translate } = useTranslateContext();

    {
        return loading ?
            <div id="myModal" style={{ display: showModal ? 'block' : 'none' }} className="modal">
                <div className="modal-content">
                    <Loader />
                </div>
            </div>
            :
            <div id="myModal" style={{ display: showModal ? 'block' : 'none' }} className="modal">
                <div className="modal-content">
                    <div>
                        <p className='txt-alert'>{error}</p>
                        <input type="number" onChange={(e) => setCodeAuth(e.target.value)} class="form-control" placeholder='Code auth' />
                        <div className="btn-area">
                            <button className='okbtn' onClick={() => {
                                setLoading(true)
                                VerifyCode(codeAuth, setShowModal, setLoading, setError)
                            }}>{translate.admin.valider}</button>
                        </div>
                    </div>
                </div>
            </div>

    }
}
