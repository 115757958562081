import React from 'react'

function trimString(chaine) {
    if (chaine.length > 25) {
        return chaine.slice(0, 25) + '...';
    } else {
        return chaine;
    }
}

export default function Row({ item }) {
    return (
        <tr className="even">
            <td>
                <div className="form-check form-check-sm form-check-custom form-check-solid">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        defaultValue={1}
                    />
                </div>
            </td>

            <td>{item.fullname}</td>
            <td>{item.company}</td>

            <td data-order="2022-08-17T08:23:58+00:00">
                <div className="badge badge-light fw-bolder">{item.activitysector}</div>
            </td>
            <td data-order="2022-08-17T08:23:58+00:00">
                <div className="badge badge-light fw-bolder">{item.contact}</div>
            </td>

            <td>
                <div className="badge badge-light-success fw-bolder">
                    {item.email}
                </div>
            </td>
            <td>
                <div className="badge badge-light-success fw-bolder">
                    {item.signvolume}
                </div>
            </td>
            <td>
                <div className="badge badge-light-success fw-bolder">
                    {trimString(item.description)}
                </div>
            </td>
        </tr>
    )
}
