import React, { useEffect, useState } from 'react'
import { saveEntreprise, patchEntreprise, getCertificat, userSelector } from '../../../../services/reduxSlices/UserSlice'
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './style.css'

export default function Forms({ showForm, setShowForm, data }) {

  console.log(data)
  const [initialValues] = useState({
    raison_sociale: data ? data.raison_sociale : '',
    email: data ? data.email : '',
    telephone: data ? data.telephone : 0,
    certificat_id: data ? data.certificat_id : 0,
    otp_sms: []
  })

  const dispatch = useDispatch()
  const { ls_certificat } = useSelector(userSelector)

  useEffect(() => {
    dispatch(getCertificat())
  }, [])

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

  const EntrepriseSchema = Yup.object().shape({
    raison_sociale: Yup.string()
      .required('Champs requis'),

    email: Yup.string()
      .required('Champs requis!'),

    telephone: Yup.number()
      .typeError('Format invalide')
      .min(10, 'Minimum 10 caractères')
      .required('Champs requis'),

    certificat_id: Yup.number()
      .required('Champs requis'),
  });

  return (
    <div
      className={showForm ? "modal fade show" : "modal fade"}
      id="kt_modal_new_target"
      tabIndex={-1}
      style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
    >
      <div className="modal-dialog modal-dialog-centered mw-650px">
        <div className="modal-content rounded" style={{ width: '100%' }}>
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={() => setShowForm(false)}
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x={6}
                    y="17.3137"
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  />
                  <rect
                    x="7.41422"
                    y={6}
                    width={16}
                    height={2}
                    rx={1}
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </div>
          </div>

          <Formik
            initialValues={initialValues}

            validationSchema={EntrepriseSchema}

            onSubmit={async (values) => {

              if(!parseInt(values.certificat_id)) {
                alert('Le champ du certificat est obligatoire')
                return
              }

              const dataSend = {
                id: data ? data.id : 0,
                raison_sociale: values.raison_sociale,
                email: values.email,
                telephone: String(values.telephone),
                certificat_id: parseInt(values.certificat_id),
                otp_sms: values.otp_sms.includes('otp_sms') ? 1 : 0,
              }

              data ? dispatch(patchEntreprise(dataSend)) : dispatch(saveEntreprise(dataSend))
              setShowForm(false)
            }}
          >

            {({ handleChange, handleBlur, values, handleSubmit }) => (
              <Form>
                <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">

                  <div className="mb-13 text-center">
                    <h1 className="mb-3">{data ? 'Modifier une entreprise' : 'Ajouter une entreprise'}</h1>
                  </div>

                  <div className="row mb-5">
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="required fs-5 fw-bold mb-2">Raison social</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Entrez la raison"
                        name="raison_sociale"
                        value={values.raison_sociale}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      <ErrorMessage className="error-msg" name="raison_sociale" component={'div'} />
                    </div>
                    <div className="col-md-6 fv-row fv-plugins-icon-container">
                      <label className="required fs-5 fw-bold mb-2">Email</label>
                      <input
                        type="text"
                        className="form-control form-control-solid"
                        placeholder="Entrez l'email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <div className="fv-plugins-message-container invalid-feedback" />
                      <ErrorMessage className="error-msg" name="email" component={'div'} />
                    </div>
                  </div>

                  <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                    <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                      <span className="required">Contacts</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Entrez votre contacts"
                      name="telephone"
                      value={values.telephone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <ErrorMessage className="error-msg" name="telephone" component={'div'} />
                  </div>

                  <select value={values.certificat_id} onChange={handleChange} onBlur={handleBlur} name="certificat_id" class="form-select" style={{ marginBottom: '3rem' }}>
                    <option value={0} selected>Selectionnez votre certificat</option>
                    {
                      ls_certificat.data &&
                      ls_certificat.data.map(it => <option selected={Number(it.id) === Number(values.certificat_id)} value={it.id}>{it.intitule}</option>)
                    }
                  </select>

                  <div className="mt-5">
                    <div className="d-flex align-items-center mb-5">
                      <div className="form-check form-check-custom form-check-solid">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="otp_sms"
                          name="otp_sms"
                          defaultChecked={data ? data.otp_sms : 0}
                          value={"otp_sms"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <label className="form-check-label" htmlFor="otp_sms">
                          OTP SMS
                        </label>
                      </div>
    
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="reset"
                      id="kt_modal_new_target_cancel"
                      onClick={() => setShowForm(false)}
                      className="btn btn-light me-3"
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      data-bs-dismiss="modal"
                      className="btn btn-primary"
                    >
                      <span className="indicator-label">Soumettre</span>
                      <span className="indicator-progress">
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    </button>
                  </div>
                  <div />
                </div>
              </Form>
            )}
          </Formik>

        </div>
      </div>
    </div>
  )
}
