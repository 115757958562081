import React, { useState, useEffect } from 'react';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useSelector, useDispatch } from 'react-redux';
import {
  signatureSelector,
  setSignataire,
  setHaveSignataire,
} from '../../../../services/reduxSlices/SignatureSlice';
import {
  getContact,
  userSelector,
} from '../../../../services/reduxSlices/UserSlice';
import { signModalSelector } from '../../../../services/reduxSlices/SignModalSlice';
import './style.css';

import { useTranslateContext } from '../../../../hooks';

const removeDuplicateArrayByKey = (arr) => {
  return [...new Set(arr.map((itm) => JSON.stringify(itm)))].map((i) =>
    JSON.parse(i)
  );
};

/**
 * Composant d'importation de contacts à ajouter aux signataires
 */
export default function ImportContact() {
  const { translate } = useTranslateContext();
  const [contacts, setContacts] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getContact({
        page: undefined,
        search: undefined,
      })
    );
  }, []);

  const { ls_contacts } = useSelector(userSelector);

  useEffect(() => {
    ls_contacts && setContacts(ls_contacts.data);
  }, [ls_contacts]);

  const signature = useSelector(signatureSelector);
  const modalSignType = useSelector(signModalSelector);
  const [signataires, setSignataires] = useState([]);

  const handleAddSignataire = (signataire) => {
    if (signature.signataire.length >= 5 || signataires.length === 5) {
      return alert(`Vous ne pouvez plus ajouter signataires`);
    }

    setSignataires([...signataires, signataire]);
    dispatch(setHaveSignataire(true));
    setContacts(contacts.filter((contact) => contact.id !== signataire.id));
  };

  const handleRemoveSignataire = (signataire) => {
    setContacts([...contacts, signataire]);
    setSignataires(
      signataires.filter((_signataire) => _signataire.id !== signataire.id)
    );
  };

  const handleSaveSignataires = () => {
    if (signature.signataire.length > 5) {
      return alert(
        `Vous ne pouvez ajouter que ${
          5 - signature.signataire.length
        } signataire(s)`
      );
    }

    if (signature.signataire.length >= 5) {
      return alert('Vous ne pouvez plus ajouter signataires');
    }

    let _signataires = signature.signataire.concat(signataires);

    //on retire l'attribut "read only" du tableau
    _signataires = JSON.parse(JSON.stringify(_signataires));

    //on refait la numérotation de l'id
    _signataires = removeDuplicateArrayByKey(_signataires).map(
      (_signataire, i) => {
        _signataire.id = i + 1;
        return _signataire;
      }
    );

    dispatch(setSignataire(_signataires));
  };

  const handleSearchContact = (e) => {
    let query = e.target.value;

    setContacts(
      ls_contacts.data.filter(
        (contact) =>
          contact.nom.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
          contact.email.toUpperCase().indexOf(query.toUpperCase()) > -1 ||
          contact.telephone.toUpperCase().indexOf(query.toUpperCase()) > -1
      )
    );
  };

  return (
    <div
      className="modal fade"
      id="import-contacts"
      tabIndex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered mw-1200px modal-lg">
        <div className="modal-content w-100">
          <div className="modal-header">
            <h2>
              <PermContactCalendarIcon /> Importer des contacts
            </h2>

            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-toggle="modal"
              data-bs-target="#MainFaireSignModal"
            >
              <span className="svg-icon svg-icon-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col">
                <p className="fw-bold mb-5 fs-5">Liste des contacts</p>

                <div
                  className="card shadow-sm"
                  style={{ height: '300px', overflowY: 'scroll' }}
                >
                  <div className="card-body">
                    <input
                      type="search"
                      className="form-control mb-5"
                      placeholder="Nom ou adresse email"
                      onChange={handleSearchContact}
                    ></input>

                    {contacts &&
                      contacts.map((contact) => {
                        return (
                          <div
                            className="form-check form-check-custom form-check-solid mb-5"
                            key={contact.id}
                          >
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={() => handleAddSignataire(contact)}
                            />
                            <label
                              className="form-check-label"
                              onClick={() => handleAddSignataire(contact)}
                            >
                              {contact.nom} - {contact.email}
                            </label>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className="col">
                <p className="fw-bold mb-5 fs-5">Contacts sélectionnés</p>

                <div
                  className="card shadow-sm"
                  style={{ height: '300px', overflowY: 'scroll' }}
                >
                  <div className="card-body">
                    {signataires.map((signataire) => {
                      return (
                        <div
                          key={signataire.id}
                          className="d-flex justify-content-between align-items-center mb-5"
                        >
                          <p>
                            {signataire.nom} - {signataire.email}
                          </p>

                          <button
                            type="button"
                            onClick={() => handleRemoveSignataire(signataire)}
                            title="Retirer"
                            className="btn btn-danger d-flex rounded-circle justify-content-center align-items-center"
                            style={{
                              width: '5px',
                              height: '5px',
                              padding: '12px',
                            }}
                          >
                            x
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {signataires.length > 0 && (
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-lg btn-primary"
                data-bs-toggle="modal"
                data-bs-target={
                  modalSignType === 2
                    ? '#MainFaireSignModal'
                    : '#MainSignFaireSignModal'
                }
                onClick={() => handleSaveSignataires()}
              >
                {translate.admin.terminer}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
