import React, { useEffect } from 'react';
import CustomLine from '../Charts/CustomLine';
import { DATA_LINE } from '../../services/mock';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDocument,
  userSelector,
} from '../../services/reduxSlices/UserSlice';

import { useTranslateContext } from '../../hooks';

/**
 * 
 * Composant présent sur le dashbord code.createjs.com
images-custom
js.hsforms.net
wp-content
wp-includes
wp-json
 * Une Card présentant les statistique des documents
 * @returns PlotCard
 */
export default function PlotCard() {
  const { translate } = useTranslateContext();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getDocument({
        page: undefined,
        start: undefined,
        end: undefined,
        search: undefined,
        limit: undefined,
      })
    );
  }, []);

  const { allDocs, signDocuments } = useSelector(userSelector);

  function countByMonthAndStatus(data) {
    let result = {};

    data.forEach((item) => {
      let month = new Date(item.created_at).getMonth();
      let status = item.statut;

      if (!result[month]) {
        result[month] = {};
      }
      if (!result[month][status]) {
        result[month][status] = 0;
      }
      result[month][status]++;
    });

    const DATA_LINE = {
      labels: translate.admin.dataMonth,
      datasets: [
        {
          label: translate.admin.documentsSignes,
          data: [
            result[0] ? result[0].signe ?? 0 : 0,
            result[1] ? result[1].signe ?? 0 : 0,
            result[2] ? result[2].signe ?? 0 : 0,
            result[3] ? result[3].signe ?? 0 : 0,
            result[4] ? result[4].signe ?? 0 : 0,
            result[5] ? result[5].signe ?? 0 : 0,
            result[6] ? result[6].signe ?? 0 : 0,
            result[7] ? result[7].signe ?? 0 : 0,
            result[8] ? result[8].signe ?? 0 : 0,
            result[9] ? result[9].signe ?? 0 : 0,
            result[10] ? result[10].signe ?? 0 : 0,
            result[11] ? result[11].signe ?? 0 : 0,
          ],
          fill: false,
          backgroundColor: ['rgba(75, 192, 192)'],
          borderColor: 'rgba(75, 192, 192)',
          tension: 0.3,
        },
        {
          label: translate.admin.documentsRefuses,
          data: [
            result[0] ? result[0].refuse ?? 0 : 0,
            result[1] ? result[1].refuse ?? 0 : 0,
            result[2] ? result[2].refuse ?? 0 : 0,
            result[3] ? result[3].refuse ?? 0 : 0,
            result[4] ? result[4].refuse ?? 0 : 0,
            result[5] ? result[5].refuse ?? 0 : 0,
            result[6] ? result[6].refuse ?? 0 : 0,
            result[7] ? result[7].refuse ?? 0 : 0,
            result[8] ? result[8].refuse ?? 0 : 0,
            result[9] ? result[9].refuse ?? 0 : 0,
            result[10] ? result[10].refuse ?? 0 : 0,
            result[11] ? result[11].refuse ?? 0 : 0,
          ],
          fill: false,
          backgroundColor: ['rgb(196, 30, 58)'],
          borderColor: 'rgb(196, 30, 58)',
          tension: 0.3,
        },
        {
          label: translate.admin.documentsEnAttentes,
          data: [
            result[0] ? result[0].en_attende ?? 0 : 0,
            result[1] ? result[1].en_attende ?? 0 : 0,
            result[2] ? result[2].en_attende ?? 0 : 0,
            result[3] ? result[3].en_attende ?? 0 : 0,
            result[4] ? result[4].en_attende ?? 0 : 0,
            result[5] ? result[5].en_attende ?? 0 : 0,
            result[6] ? result[6].en_attende ?? 0 : 0,
            result[7] ? result[7].en_attende ?? 0 : 0,
            result[8] ? result[8].en_attende ?? 0 : 0,
            result[9] ? result[9].en_attende ?? 0 : 0,
            result[10] ? result[10].en_attende ?? 0 : 0,
            result[11] ? result[11].en_attende ?? 0 : 0,
          ],
          fill: false,
          backgroundColor: ['rgb(237,125,49)'],
          borderColor: 'rgb(237,125,49)',
          tension: 0.3,
        },
      ],
    };

    return DATA_LINE;
  }

  let option = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
      },
    },

    scales: {
      y: {
        display: true,
        title: { display: false, text: 'FCFA/kg' },
      },
      x: {
        display: true,
        title: { display: false, text: 'Region' },
      },
    },
  };

  return (
    <div className="col-xl-6 mb-5 mb-xl-10">
      <div className="card card-flush overflow-hidden h-md-100">
        <div className="card-header py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder text-dark">
              {translate.admin.statistiqueDesDocuments}
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-overflow="true"
            ></button>
            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">
                  Quick Actions
                </div>
              </div>
              <div className="separator mb-3 opacity-75"></div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                  New Ticket
                </a>
              </div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                  New Customer
                </a>
              </div>
              <div
                className="menu-item px-3"
                data-kt-menu-trigger="hover"
                data-kt-menu-placement="right-start"
              >
                <a href="#" className="menu-link px-3">
                  <span className="menu-title">New Group</span>
                  <span className="menu-arrow"></span>
                </a>
                <div className="menu-sub menu-sub-dropdown w-175px py-4">
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3">
                      Admin Group
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3">
                      Staff Group
                    </a>
                  </div>
                  <div className="menu-item px-3">
                    <a href="#" className="menu-link px-3">
                      Member Group
                    </a>
                  </div>
                </div>
              </div>
              <div className="menu-item px-3">
                <a href="#" className="menu-link px-3">
                  New Contact
                </a>
              </div>
              <div className="separator mt-3 opacity-75"></div>
              <div className="menu-item px-3">
                <div className="menu-content px-3 py-3">
                  <a className="btn btn-primary btn-sm px-4" href="#">
                    Generate Reports
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
          <div className="px-9 mb-5">
            <span className="fs-6 fw-bold text-gray-400">
              {translate.admin.CourbeDesDocumentsEnFonctionsDesStatus}
            </span>
          </div>
          <div 
            id="kt_charts_widget_4"
            className="min-h-auto ps-4 pe-6"
            style={{ height: '300px' }}
          >
            <CustomLine
              data={
                allDocs.data
                  ? countByMonthAndStatus(allDocs.data)
                  : signDocuments.data
                  ? countByMonthAndStatus(signDocuments.data)
                  : DATA_LINE
              }
              options={option}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
