import React, { useState, useRef } from 'react';
import { toPng } from 'html-to-image';
import SignatureCanvas from 'react-signature-canvas';
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux';
import {
  signatureSelector,
  setChoice,
  setText,
  setDraw,
  setQrCode,
  setParaphe,
  setPreviewText,
  setFont
} from '../../../../../services/reduxSlices/SignatureSlice';
import './Step2.css';
import { Designed } from '../../../../Designed';
import { useTranslateContext } from '../../../../../hooks';
import styles from './step2.module.css'

/**
 * Etape 2 (Signature) de la fenêtre modale seul signataire
 */
const contentId = 'containerDesigned'
export default function Step2() {


  const [loading, setLoading] = useState(false)

  const removeLeftDesc_Content = (node) => {
    const element = node.getElementsByClassName('left-desc_designed');

    if (element.length > 0) {
      element[0].innerHTML = '<!-- This Content was removed -->';
    }
  }

  function addElementToDOM(clonedElement, parentId) {
    const parentElement = document.getElementById(parentId);

    if (parentElement) {
      parentElement.appendChild(clonedElement);
    } else {
      console.error('Parent element not found');
    }
  }

  function removeClonedElement(clonedElement) {
    if (clonedElement.parentNode) {
      clonedElement.parentNode.removeChild(clonedElement);
    } else {
      console.error('Cloned element is not in the DOM or has no parent');
    }
  }

  const getParapheBase64 = async () => {
    const previewElement = document.getElementById(contentId)

    const originalEelement = previewElement.cloneNode(true)
    removeLeftDesc_Content(originalEelement)

    addElementToDOM(originalEelement, 'signature-preview_xstep');

    toPng(previewElement, { cacheBust: false })
      .then((dataUrl) => {
        dispatch(setPreviewText(dataUrl))

      })
      .catch((err) => {
        console.log(err);
      })

    toPng(originalEelement, { cacheBust: false })
      .then((dataUrl) => {
        removeClonedElement(originalEelement)
        dispatch(setText(dataUrl))
      })
      .catch((err) => {
        console.log(err);
      })
  };

  const bigFont = ['Lihataja', 'Asem Kandis']

  const fontScript = [
    { name: 'Southam Demo' },
    { name: 'Lihataja' },
    { name: 'Queenstown Signature' },
    { name: 'Bellandha Signature' },
    { name: 'Asem Kandis' }
  ]

  const [onDone, setOnDone] = useState(false)
  const [selected, setSelected] = useState(fontScript[0].name)
  const [textValue, setTextValue] = useState('')
  const { translate } = useTranslateContext();

  const signature = useSelector(signatureSelector);
  const dispatch = useDispatch();

  const signatureRef = useRef({});

  const handleDisplayChoiceParameters = (choice) =>
    signature.choice === choice ? 'd-flex align-items-center' : 'd-none';

  const handleSetSignatureImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64String = event.target.result;
        const image = `<image src=${base64String} />`;

        dispatch(setTextValue(image));
      };

      // Read the file as a data URL, which will be base64-encoded
      reader.readAsDataURL(file);
    }
  }

  const handleSetSignatureDraw = () => {
    var image = `<image src=${signatureRef.current.getTrimmedCanvas().toDataURL('image/png')} />`
    setTextValue(image)
  };

  function transformName(chaine) {
    return chaine.split(' ')
      .map(mot => mot.charAt(0).toUpperCase() + mot.substring(1).toLowerCase())
      .join(' ');
  }

  const handleClearSignatureDraw = () => {
    signatureRef.current.clear();
    dispatch(setDraw(null));
  };

  const handleClearImage = () => {
    document.querySelector('#signature-image').value = null;
    dispatch(setTextValue(null));
  };

  const onSelectFont = (item) => {
    setSelected(item.name)
    dispatch(setFont(item.name))
  }

  const handleDone = (e) => {
    e.preventDefault()
    setOnDone(true)
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center mb-5">
        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="radio"
            value="text"
            id="text"
            name="choice"
            defaultChecked
            onClick={() => dispatch(setChoice('text'))}
          />
          <label className="form-check-label" htmlFor="text">
            {translate.admin.deinition}
          </label>
        </div>

        <div className="form-check form-check-custom form-check-solid mx-5">
          <input
            className="form-check-input"
            type="radio"
            value="image"
            id="image"
            name="choice"
            onClick={() => dispatch(setChoice('image'))}
          />
          <label className="form-check-label" htmlFor="image">
            {translate.admin.chargerUneImage}
          </label>
        </div>

        <div className="form-check form-check-custom form-check-solid">
          <input
            className="form-check-input"
            type="radio"
            value="draw"
            id="draw"
            name="choice"
            onClick={() => dispatch(setChoice('draw'))}
          />
          <label className="form-check-label" htmlFor="draw">
            {translate.admin.zoneDeSignature}
          </label>
        </div>
      </div>

      <div className={`py-5 ${styles.container}`}>
        {
          signature.choice === 'text' ?
            <div className={styles.navbar}>
              <span className={styles.leftIcon}>
                <AiOutlineLeft color={'#009EF7'} size={18} />
              </span>

              {
                onDone &&
                <div className={styles.display}>
                  {
                    fontScript.map((item, key) => (
                      <div onClick={
                        async () => {
                          onSelectFont(item)
                          await getParapheBase64()
                        }
                      } key={key} className={`${styles.policyBox} ${selected === item.name && styles.selected}`}>
                        <label style={{ fontFamily: item.name }}>Your font</label>
                      </div>
                    ))
                  }
                </div>
              }

              <span className={styles.rigthIcon}>
                <AiOutlineRight color={'#009EF7'} size={18} />
              </span>
            </div>
            : <span></span>
        }
        <div className={`d-flex align-items-center`}>
          <div className={`${handleDisplayChoiceParameters('text')} ${styles.searchPreview}`}>
            <input
              type="search"
              id="input_x65"
              className="form-control"
              defaultValue={textValue}
              maxLength={14}
              disabled={onDone}
              onChange={async e => {
                setTextValue(e.target.value)
              }} />

            <button
              onClick={async (e) => {
                if (!onDone) {
                  handleDone(e)
                  setTimeout(async () => {
                    await getParapheBase64()
                  }, 1000);
                } else {
                  e.preventDefault()
                  setOnDone(false)
                  dispatch(setText(''))
                  dispatch(setPreviewText(''))
                }
              }}
              className={`${styles.doneBtn}`}
              style={{ color: onDone ? 'red' : 'green' }}
            >
              {onDone ? translate.admin.annuler : translate.admin.terminer}
            </button>
            <br />
          </div>
          {
            onDone &&
            <Designed
              id={contentId}
              style={{ fontFamily: selected, fontSize: bigFont.includes(selected) ? '4rem' : '4rem' }}
              value={
                signature.choice === 'text' ?
                  transformName(textValue) : textValue
              }
            />
          }
        </div>

        <div className="d-flex align-items-center">
          <div className={handleDisplayChoiceParameters('image')}>
            <input type="file" id="signature-image" className="form-control" accept='image/*' onChange={handleSetSignatureImage} />

            <div className="position-relative" id="image-container">
              {textValue && <button type='button' title="Retirer cette image"
                style={{ top: 0, right: 0, width: '10px', height: '10px', padding: '15px' }}
                className='btn btn-danger ms-5 rounded-circle justify-content-center align-items-center position-absolute'
                onClick={() => handleClearImage()}>X
              </button>}
            </div>
            <button
              onClick={async (e) => {
                if (!onDone) {
                  handleDone(e)
                  setTimeout(async () => {
                    await getParapheBase64()
                  }, 1000);
                } else {
                  e.preventDefault()
                  setOnDone(false)
                  handleClearSignatureDraw()
                  dispatch(setText(''))
                  dispatch(setPreviewText(''))
                }
              }}
              className={`${handleDisplayChoiceParameters('image')} ${styles.doneBtn}`}
              style={{ color: onDone ? 'red' : 'green', marginTop: '10px' }}
            >
              {onDone ? 'Annuler' : 'Terminer'}
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center">
          <div className={handleDisplayChoiceParameters('draw')}>
            <div className='rounded' style={{ border: '1px solid #E4E6EF' }} id="canvas">
              <SignatureCanvas
                ref={signatureRef}
                canvasProps={{ width: 400, height: 100 }}
                onEnd={() => handleSetSignatureDraw()}
              />
            </div>
          </div>
        </div>
        <button
          onClick={async (e) => {
            if (!onDone) {
              handleDone(e)
              setTimeout(async () => {
                await getParapheBase64()
              }, 1000);
            } else {
              e.preventDefault()
              setOnDone(false)
              handleClearSignatureDraw()
              dispatch(setText(''))
              dispatch(setPreviewText(''))
            }
          }}
          className={`${handleDisplayChoiceParameters('draw')} ${styles.doneBtn}`}
          style={{ color: onDone ? 'red' : 'green', marginTop: '10px' }}
        >
          {onDone ? 'Annuler' : 'Terminer'}
        </button>
      </div>

      <div className="mt-5">
        <div className="d-flex align-items-center mb-5">
          <div className="form-check form-check-custom form-check-solid">
            <input
              className="form-check-input"
              type="checkbox"
              value="paraphe"
              id="paraphe"
              name="option"
              onClick={() => dispatch(setParaphe(!signature.paraphe))}
            />
            <label className="form-check-label" htmlFor="paraphe">
              {translate.admin.paraphe}
            </label>
          </div>

          <div className="form-check form-check-custom form-check-solid mx-5">
            <input
              className="form-check-input"
              type="checkbox"
              value="qrcode"
              id="qrcode"
              name="option"
              onClick={() => dispatch(setQrCode(!signature.qrCode))}
            />
            <label className="form-check-label" htmlFor="qrcode">
              QR Code
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}