import axios from 'axios';
import { toast } from 'react-toastify';
import { LOGIN_URL } from '../../../services/api/URL_API';
import Cookies from 'js-cookie';
import { useTranslateContext } from '../../../hooks';

const BASE_URL = 'https://dkbsign.com/dkbsign_web_api/public';

export const Login = async ({ email, password }) => {
  const { translate } = useTranslateContext();
  const formData = new FormData();

  formData.append('email', email);
  formData.append('password', password);

  try {
    const response = await axios.post(`${BASE_URL}${LOGIN_URL}`, formData);
    // handle success

    Cookies.set('token_admin', response.data.response.token);

    window.location.href = '/home/admin/actualite';
  } catch (error) {
    toast.error(translate.admin.emailOuMotDePasseIncorrect, {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
