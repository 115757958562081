import React, {useEffect} from 'react'
import Container from "../../components/Container/Container"
import Card1 from '../../components/Dashbord_Card/Card1'
import HeaderCard from '../../components/Dashbord_Card/HeaderCard'
import PlotCard from '../../components/Dashbord_Card/PlotCard'
import TableCard from '../../components/Dashbord_Card/TableCard'
import './home.css'

/**
 * Page du menu principal
 * @returns 
 */
export default function Home() {

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

    return (
        <Container>
          <HeaderCard />
          <Card1 />
          <PlotCard />
          <TableCard />
        </Container>
    )
}
