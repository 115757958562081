import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function PrivateRoute({ children, authRoles }){
    const isAuthenticated = Cookies.get('token') ? true : false;    

    if (isAuthenticated) {
      const role = JSON.parse(Cookies.get('user')).role
      
      if(role === authRoles)
        return children
        
      if(role === 'admin')
        return <Navigate to="/adduser" />

    }
      
    return <Navigate to="/login" /> 
}