import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import DevisTable from '../../../../components/AdminLanding/Devis/DevisTable'
import { GetDevis } from './services'

export default function Devis() {

    const [data, setData] = useState(null)

    useEffect(() => {
        GetDevis().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <DevisTable data={data} />
        </Container>
    )
}
