import React from 'react'
import CustomBar from '../Charts/CustomBar'
import { DETAILS_DOCS_ADMIN } from '../../../services/mock'
import './style.css'

/**
 * 
 * Composant présent sur le dashbord 
 * Une Card présentant les statistique des documents
 * @returns PlotCard
 */
export default function PlotCard() {

    let option = {
        responsive : true,
        plugins:{
          legend : { display : true},
          title:{
            display : false
          }
        },
    
        scales:{
          y:{
            display: true,
            title:{ display: false, text: 'Quantité' }
          },
          x:{
            display: true,
            title:{ display: false, text: 'Année' }
          }
        }
    }
  return (
    <div className="col-xl-6 mb-5 mb-xl-10" style={{width: '100%'}}>
    <div className="card card-flush overflow-hidden h-md-100">
        <div className="card-header py-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bolder text-dark">Statistique des entreprises</span>
            </h3>
            <div className="card-toolbar">
                <button className="btn btn-icon btn-color-gray-400 btn-active-color-primary justify-content-end" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true">
                </button>
                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px" data-kt-menu="true">
                    <div className="menu-item px-3">
                        <div className="menu-content fs-6 text-dark fw-bolder px-3 py-4">Quick Actions</div>
                    </div>
                    <div className="separator mb-3 opacity-75"></div>
                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Ticket</a>
                    </div>
                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Customer</a>
                    </div>
                    <div className="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-start">
                        <a href="#" className="menu-link px-3">
                            <span className="menu-title">New Group</span>
                            <span className="menu-arrow"></span>
                        </a>
                        <div className="menu-sub menu-sub-dropdown w-175px py-4">
                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Admin Group</a>
                            </div>
                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Staff Group</a>
                            </div>
                            <div className="menu-item px-3">
                                <a href="#" className="menu-link px-3">Member Group</a>
                            </div>
                        </div>
                    </div>
                    <div className="menu-item px-3">
                        <a href="#" className="menu-link px-3">New Contact</a>
                    </div>
                    <div className="separator mt-3 opacity-75"></div>
                    <div className="menu-item px-3">
                        <div className="menu-content px-3 py-3">
                            <a className="btn btn-primary btn-sm px-4" href="#">Generate Reports</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="card-body d-flex justify-content-between flex-column pb-1 px-0">
            <div className="px-9 mb-5">
                <span className="fs-6 fw-bold text-gray-400">Courbe des documents en fonctions des entreprises</span>
            </div>
            <div id="kt_charts_widget_4" className="min-h-auto ps-4 pe-6" style={{height: '600px'}}>
                <CustomBar data={DETAILS_DOCS_ADMIN} options={option} />
            </div>
        </div>
    </div>
</div>
  )
}
