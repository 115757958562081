import React from 'react'

function getPagesAroundCurrent(currentPage, totalPage) {
    const pagesToShow = 3;
    const pages = [];

    // Calculate the starting page based on the current page
    let startPage = currentPage - Math.floor(pagesToShow / 2);
    startPage = Math.max(startPage, 1);
    startPage = Math.min(startPage, totalPage - pagesToShow + 1);

    // Populate the pages array
    for (let i = 0; i < pagesToShow; i++) {
        pages.push(startPage + i);
    }

    return pages;
}

export default function Pagination({ setPage, currentPage, maxPage }) {

    const goToPrevPage = () =>{
        if(currentPage === 1) return

        setPage(currentPage-1)
    }

    const goToNextPage = () =>{
        if(currentPage === maxPage) return
        
        setPage(currentPage+1)
    }

    return (
        <div className="row">
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
            <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="kt_table_users_paginate"
                >
                    <ul className="pagination">
                        <li
                            className="paginate_button page-item previous disabled"
                            id="kt_table_users_previous"
                            onClick={()=> goToPrevPage()}
                        >
                            <a
                                href="#"
                                aria-controls="kt_table_users"
                                className="page-link"
                            >
                                <i className="previous" />
                            </a>
                        </li>
                        { getPagesAroundCurrent(currentPage, maxPage).map((item, key) => <Item key={key} page={item} active={currentPage === item} setPage={setPage} />) }
                        <li className="paginate_button page-item next" id="kt_table_users_next">
                            <a
                                href="#"
                                aria-controls="kt_table_users"
                                className="page-link"
                                onClick={()=> goToNextPage()}
                            >
                                <i className="next" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const Item = ({ page, active, setPage }) => {
    return (
        <li className={`paginate_button page-item ${active && 'active'}`}>
            <a
                href="#"
                aria-controls="kt_table_users"
                className="page-link"
                onClick={()=> setPage(page)}
            >
                {page}
            </a>
        </li>
    )
}