import React, {useEffect, useState} from 'react'
import { saveCertificat, patchCertificat } from '../../../../services/reduxSlices/UserSlice'
import { useDispatch } from 'react-redux';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from "yup";
import './style.css'

export default function Forms({showForm, setShowForm, data}) {
  
    console.log(data)
    const [initialValues] = useState({ 
      intitule: data ? data.intitule : '',  
      cle_api: data ? data.cle_api : '', 
      cle_securite: data ? data.cle_securite : 0,
      date_expiration: data ? data.date_expiration : '', 
      volume_signatures_total: data ? data.volume_signatures_total : ''
    })
  
    const dispatch = useDispatch()

    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));

    const CertificatSchema = Yup.object().shape({
        intitule: Yup.string()
        .required('Champs requis'),

        cle_api: Yup.string()
        .required('Champs requis!'),

        cle_securite: Yup.string()
        .required('Champs requis!'),
      
        date_expiration: Yup.string()
        .required('Champs requis!'),
      
        volume_signatures_total: Yup.string()
        .required('Champs requis!'),
    });

  return (
      <div
            className={showForm ? "modal fade show" : "modal fade"}
            id="kt_modal_new_target"
            tabIndex={-1}
            style={{ display: showForm ? "block" : "none", marginTop: '0px' }}
          >
        <div className="modal-dialog modal-dialog-centered mw-650px">
          <div className="modal-content rounded" style={{width: '100%'}}>
            <div className="modal-header pb-0 border-0 justify-content-end">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary"
                data-bs-dismiss="modal"
                onClick={()=>setShowForm(false)}
              >
                <span className="svg-icon svg-icon-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x={6}
                      y="17.3137"
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(-45 6 17.3137)"
                      fill="currentColor"
                    />
                    <rect
                      x="7.41422"
                      y={6}
                      width={16}
                      height={2}
                      rx={1}
                      transform="rotate(45 7.41422 6)"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </div>
            </div>
            
              <Formik
                  initialValues={initialValues}
                          
                  validationSchema = {CertificatSchema}
                              
                  onSubmit={async (values) => {
                    
                    const dataSend = {
                      id : data ? data.id : 0,
                      intitule: values.intitule, 
                      cle_api: values.cle_api, 
                      cle_securite: values.cle_securite, 
                      date_expiration: values.date_expiration,
                      volume_signatures_total : values.volume_signatures_total
                    }
                  
                    data ? dispatch(patchCertificat(dataSend)) : dispatch(saveCertificat(dataSend))
                    setShowForm(false)
                  }}
              >
            
                {({ handleChange, handleBlur, values, handleSubmit }) => (
                  <Form>
                    <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15">

                        <div className="mb-13 text-center">
                          <h1 className="mb-3">{data ? 'Modifier le certificat': 'Ajouter un certificat'}</h1>
                        </div>

                        <div className="row mb-5">
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Titre</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Entrez le titre"
                              name="intitule"
                              value={values.intitule} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="intitule" component={'div'} />
                          </div>
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Clee API</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Entrez la clee API"
                              name="cle_api"
                              value={values.cle_api} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="cle_api" component={'div'} />
                          </div>
                        </div>

                        <div className="row mb-5">
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Clee sécurité</label>
                            <input
                              type="text"
                              className="form-control form-control-solid"
                              placeholder="Entrez la clee sécurité"
                              name="cle_securite"
                              value={values.cle_securite} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="cle_securite" component={'div'} />
                          </div>
                          <div className="col-md-6 fv-row fv-plugins-icon-container">
                            <label className="required fs-5 fw-bold mb-2">Date d'expiration</label>
                            <input
                              type="date"
                              className="form-control form-control-solid"
                              placeholder="Entrez la date d'expiration"
                              name="date_expiration"
                              value={values.date_expiration} 
                              onChange={handleChange} 
                              onBlur={handleBlur}
                            />
                            <div className="fv-plugins-message-container invalid-feedback" />
                            <ErrorMessage className="error-msg" name="date_expiration" component={'div'} />
                          </div>
                        </div>
                        
                        <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-invalid">
                          <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                            <span className="required">Volume signature total</span>
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-solid"
                            placeholder="Entrez votre clée de sécurité"
                            name="volume_signatures_total"
                            value={values.volume_signatures_total} 
                            onChange={handleChange} 
                            onBlur={handleBlur}
                          />
                          <ErrorMessage className="error-msg" name="volume_signatures_total" component={'div'} />
                        </div>

                        <div className="text-center">
                          <button
                            type="reset"
                            id="kt_modal_new_target_cancel"
                            onClick={()=>setShowForm(false)}
                            className="btn btn-light me-3"
                          >
                            Annuler
                          </button>
                          <button
                            type="submit"
                            onClick={handleSubmit}
                            data-bs-dismiss="modal"
                            className="btn btn-primary"
                          >
                            <span className="indicator-label">Soumettre</span>
                            <span className="indicator-progress">
                              Please wait...
                              <span className="spinner-border spinner-border-sm align-middle ms-2" />
                            </span>
                          </button>
                        </div>
                        <div />
                    </div>
                  </Form>
                )}
              </Formik>

          </div>
        </div>
      </div>
  )
}
