import React, {useEffect, useState} from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import {useSelector} from 'react-redux'
import {CurrentDocSelector} from '../../services/reduxSlices/SignDocSlice'
import axios from 'axios'
import './style.css'
import Cookies from 'js-cookie'

export default function ViewSignDocs() {

    const currentdoc = useSelector(CurrentDocSelector)
    const [pdf, setPDF] = useState('')

    useEffect(()=>{
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    },[])

    // console.log(currentdoc)
    const token = Cookies.get('token')

    axios.get(`http://localhost:8000/api/signatures/document/${currentdoc.currentDoc}`,{
        headers: { 'Authorization': `Bearer ${token}` },
    })
    .then(response => setPDF(response.data.filename))

  return (
    <React.Fragment>
            <div className='content'>
                <div className="container-page">
                    <div className='docview'>
                        <div className="document"> 
                            <Document file={pdf}> 
                                <Page bookmark="Harry Potter and the Philosopher's Stone" width={600} pageNumber={0} />
                            </Document>
                        </div> 
                    </div>
                </div>
            </div>
    </React.Fragment>
  )
}