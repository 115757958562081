import apiInstance from "../api/axios.config";
import { LOGIN_URL, SIGN_DOCUMENT, GET_DOCUMENT, ENTREPRISES_URL, USER_URL, CONTACT_URL, CERTIFICAT_URL, IMPORT_CONTACT_URL, GET_STATISTIC } from '../api/URL_API'
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Cookies from 'js-cookie'
import { toast } from "react-toastify";

export const login = createAsyncThunk(
    'users/login',
    async (values, thunkAPI) => {

        try {
            var new_data = { email: values.email, password: values.password }
            const response = await apiInstance.post(LOGIN_URL, new_data);
            let data = response.data

            if (response.status === 200) {
                if (values.remember) {
                    let date = new Date();
                    date.setMinutes(date.getMinutes() + 120);
                    Cookies.set('token', data.token, { expires: date })
                    Cookies.set('user', JSON.stringify(data.user), { expires: date })
                } else {
                    let date = new Date();
                    date.setMinutes(date.getMinutes() + 10);
                    Cookies.set('token', data.token, { expires: date })
                    Cookies.set('user', JSON.stringify(data.user), { expires: date })
                }

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Email ou mot de passe incorrect", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const signDocument = createAsyncThunk(
    'users/signdocument',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(SIGN_DOCUMENT, values, {
                headers: { 'authorizations': `${token}`, 'Content-Type': 'application/json' },
            });

            let data = response.data
            console.log(data)
            if (data.code === 804) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getDocument = createAsyncThunk(
    'users/getdocument',
    async (values, thunkAPI) => {
        const { page, start, end, search, status, limit } = values

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(GET_DOCUMENT, {
                headers: { Authorization: `Bearer ${token}` },
                params: { page, start, end, search, status, limit }, // Pass the page parameter here
            });

            let data = response.data
            if (data) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getCertificat = createAsyncThunk(
    'users/getcertificat',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(CERTIFICAT_URL, {
                headers: { Authorization: `Bearer ${token}` },
            });

            let data = response.data
            if (data) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const saveCertificat = createAsyncThunk(
    'users/savecertificat',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(CERTIFICAT_URL, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("Le certificat a été ajouté avec succès", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue l'ors de la création du certificat", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue l'ors de la création du certificat", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteCertificat = createAsyncThunk(
    'users/deletecertificat',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.delete(`${CERTIFICAT_URL}/${values}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("Le Certificat a été supprimé", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la suppression", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la suppression", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const patchCertificat = createAsyncThunk(
    'users/patchcertificat',
    async (values, thunkAPI) => {

        console.log(values)
        try {
            const token = Cookies.get('token')
            const response = await apiInstance.patch(`${CERTIFICAT_URL}/${values.id}`, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("Le certificat a été modifié", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la modification", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la modification", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getEntreprise = createAsyncThunk(
    'users/getentreprise',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(ENTREPRISES_URL, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const saveEntreprise = createAsyncThunk(
    'users/saveentreprise',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(ENTREPRISES_URL, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'entreprise a été ajouté avec succès", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue l'ors de la création de l'entreprise", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue l'ors de la création de l'entreprise", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteEntreprise = createAsyncThunk(
    'users/deleteentreprise',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.delete(`${ENTREPRISES_URL}/${values}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'entreprise a été supprimé", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la suppression", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la suppression", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const patchEntreprise = createAsyncThunk(
    'users/patchentreprise',
    async (values, thunkAPI) => {

        console.log(values)
        try {
            const token = Cookies.get('token')
            const response = await apiInstance.patch(`${ENTREPRISES_URL}/${values.id}`, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'entreprise a été modifié", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la modification", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la modification", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getUsers = createAsyncThunk(
    'users/getuser',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(USER_URL, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const saveUsers = createAsyncThunk(
    'users/saveuser',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(USER_URL, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été ajouté avec succès", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getContact = createAsyncThunk(
    'users/getcontact',
    async (values, thunkAPI) => {
        var limit = 20
        const { page, search } = values
        
        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(CONTACT_URL, {
                headers: { 'Authorization': `Bearer ${token}` },
                params: { page, search, limit }, // Pass the page parameter here
            });

            let data = response.data

            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const getStatistic = createAsyncThunk(
    'users/getstatistic',
    async (values, thunkAPI) => {
        
        try {
            const token = Cookies.get('token')
            const response = await apiInstance.get(GET_STATISTIC, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            let data = response.data

            if (response.status === 200) {
                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const saveContact = createAsyncThunk(
    'users/savecontact',
    async (values, thunkAPI) => {

        console.log(values)
        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(CONTACT_URL, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été ajouté avec succès", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const importContact = createAsyncThunk(
    'users/importcontact',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.post(IMPORT_CONTACT_URL, values, {
                headers: { 
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}` 
                },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("Vos contacts on été importé", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteContact = createAsyncThunk(
    'users/deletecontact',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.delete(`${CONTACT_URL}/${values}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été supprimé", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la suppression", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la suppression", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const patchContact = createAsyncThunk(
    'users/patchcontact',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.patch(`${CONTACT_URL}/${values.id}`, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été modifié", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la modification", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la modification", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const deleteUsers = createAsyncThunk(
    'users/deleteuser',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.delete(`${USER_URL}/${values}`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été supprimé", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la suppression", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la suppression", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);

export const patchUsers = createAsyncThunk(
    'users/patchuser',
    async (values, thunkAPI) => {

        try {
            const token = Cookies.get('token')
            const response = await apiInstance.patch(`${USER_URL}/${values.id}`, values, {
                headers: { 'Authorization': `Bearer ${token}` },
            });

            let data = response.data

            if (response.status === 200) {

                toast.success("L'utilisateur a été modifié", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return data;
            } else {
                toast.error("Une erreur est survenue lors de la modification", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                return thunkAPI.rejectWithValue(data);
            }

        } catch (e) {
            toast.error("Une erreur est survenue lors de la modification", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return thunkAPI.rejectWithValue(e.response.data);
        }
    }
);


export const userSlice = createSlice({
    name: "user",

    initialState: {
        user: {},
        stats: null,
        isFetching: false,
        errorHappen: false,
        isLogin: false,
        signDocuments: [],
        allDocs: [],
        ls_entrepises: [],
        ls_contacts: [],
        ls_certificat: [],
        ctc_updated: false,
        name_entreprise: '',
        etp_updated: false,
        cert_updated: false,

        token: "",
        errorMessage: "",
    },

    reducers: {
        clearState: (state) => {
            state.user = {};
            state.isFetching = false;
            state.errorHappen = false;
            state.isLogin = false;
            state.ctc_updated = false;
            state.etp_updated = false;
            state.cert_updated = false;
            state.signDocuments = []
            state.allDocs = []
            state.stats = null
            state.ls_contacts = []
            state.ls_entrepises = []
            state.ls_contacts = []

            state.token = "";
            state.errorMessage = "";

            return state;
        }
    },

    extraReducers: {
        [login.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = false;
            state.isLogin = true;
            return state;
        },
        [login.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = 'email ou mot de passe incorrect';
        },
        [login.pending]: (state) => {
            state.isFetching = true;
        },

        [signDocument.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = false
            return state;
        },
        [signDocument.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [signDocument.pending]: (state) => {
            state.isFetching = true;
        },

        [getDocument.fulfilled]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = false
            if(payload.data.length > 20 )
                state.allDocs = payload
            else
                state.signDocuments = payload
            return state;
        },
        [getDocument.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getDocument.pending]: (state) => {
            state.isFetching = true;
        },

        [getEntreprise.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ls_entrepises = payload

            return state;
        },
        [getEntreprise.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getEntreprise.pending]: (state) => {
            state.isFetching = true;
        },

        [getUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ls_contacts = payload

            return state;
        },
        [getUsers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getUsers.pending]: (state) => {
            state.isFetching = true;
        },

        [saveUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [saveUsers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [saveUsers.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [deleteUsers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [deleteUsers.pending]: (state) => {
            state.isFetching = true;
        },

        [patchUsers.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [patchUsers.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [patchUsers.pending]: (state) => {
            state.isFetching = true;
        },

        [saveEntreprise.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.etp_updated = true

            return state;
        },
        [saveEntreprise.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [saveEntreprise.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteEntreprise.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.etp_updated = true

            return state;
        },
        [deleteEntreprise.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [deleteEntreprise.pending]: (state) => {
            state.isFetching = true;
        },

        [patchEntreprise.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.etp_updated = true

            return state;
        },
        [patchEntreprise.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [patchEntreprise.pending]: (state) => {
            state.isFetching = true;
        },

        [getContact.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ls_contacts = payload

            return state;
        },
        [getContact.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getContact.pending]: (state) => {
            state.isFetching = true;
        },

        
        [getStatistic.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.stats = payload

            return state;
        },
        [getStatistic.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getStatistic.pending]: (state) => {
            state.isFetching = true;
        },

        [saveContact.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [saveContact.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [saveContact.pending]: (state) => {
            state.isFetching = true;
        },

        [importContact.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [importContact.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [importContact.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteContact.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [deleteContact.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [deleteContact.pending]: (state) => {
            state.isFetching = true;
        },

        [patchContact.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ctc_updated = true

            return state;
        },
        [patchContact.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [patchContact.pending]: (state) => {
            state.isFetching = true;
        },

        [saveCertificat.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.cert_updated = true

            return state;
        },
        [saveCertificat.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [saveCertificat.pending]: (state) => {
            state.isFetching = true;
        },

        [deleteCertificat.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.cert_updated = true

            return state;
        },
        [deleteCertificat.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [deleteCertificat.pending]: (state) => {
            state.isFetching = true;
        },

        [patchCertificat.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.cert_updated = true

            return state;
        },
        [patchCertificat.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [patchCertificat.pending]: (state) => {
            state.isFetching = true;
        },

        [getCertificat.fulfilled]: (state, { payload }) => {
            state.isFetching = false
            state.errorHappen = false
            state.ls_certificat = payload

            return state;
        },
        [getCertificat.rejected]: (state, { payload }) => {
            state.isFetching = false;
            state.errorHappen = true
            state.errorMessage = payload ? payload.error : '';
        },
        [getCertificat.pending]: (state) => {
            state.isFetching = true;
        },
    },
})

export const { clearState } = userSlice.actions;
export const userSelector = state => state.user