import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    choice: 'text',
    authCode: String(Math.floor(1000 + Math.random() * 9000)),
    text: '',
    previewText: '',
    fontScript: 'Southam Demo',
    image: null,
    draw: null,
    qrCode: false,
    paraphe: false,
    HaveSignataire : false,
    HaveOrder : false,
    signataire: [],
    mode: '',
    urgence: 'normal'
}

export const signatureSlice = createSlice({
    name: 'signature',
    initialState,
    reducers: {
        setChoice: (state, action) => { state.choice = action.payload },
        setText: (state, action) => { state.text = action.payload },
        setPreviewText: (state, action) => { state.previewText = action.payload },
        setFont: (state, action) => { state.fontScript = action.payload },
        setImage: (state, action) => { state.image = action.payload },
        setDraw: (state, action) => { state.draw = action.payload },
        setQrCode: (state, action) => { state.qrCode = action.payload },
        setAuthCode: (state, action) => { state.authCode = action.payload },
        setParaphe: (state, action) => { state.paraphe = action.payload },
        setSignataire: (state, action) => { state.signataire = action.payload },
        setHaveSignataire: (state, action) => { state.HaveSignataire = action.payload },
        setUrgence: (state, action) => { state.urgence = action.payload },
        setHaveOrder: (state, action) => { state.HaveOrder = action.payload },
        setMode: (state, action) => { state.mode = action.payload },
        clearSignature: () => initialState
    }
})

export const {
    setChoice,
    setText,
    setImage,
    setDraw,
    setQrCode,
    setAuthCode,
    setPreviewText,
    setParaphe,
    setSignataire,
    setHaveSignataire,
    clearSignature,
    setHaveOrder,
    setUrgence,
    setFont,
    setMode
} = signatureSlice.actions

export const signatureSelector = state => state.signature
