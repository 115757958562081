import React, {useEffect} from 'react'
import Container from "../../components/Container/Container"
import Contact from '../../components/Contact/Contact'

/**
 * Page du Menu Contact
 * @returns ContactPage
 */
export default function ContactPage() {

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

    return (
      <Container>
        <Contact />
      </Container>
               
    )
}
