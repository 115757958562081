import React, { useState, useEffect, useRef } from 'react';
import Container from '../../components/Container/Container';

import { Document, Page, pdfjs } from 'react-pdf';

import { useSelector } from 'react-redux';
import { docListSelector } from '../../services/reduxSlices/DocListSlice';
import { signatureSelector } from '../../services/reduxSlices/SignatureSlice';

import { GoArrowRight, GoArrowLeft } from 'react-icons/go';
import { ImQrcode } from 'react-icons/im';
import { MdDone } from 'react-icons/md';
import { BsPenFill } from 'react-icons/bs';
import { RiUser2Fill } from 'react-icons/ri';

import { useParamsDocument, useTranslateContext } from '../../hooks';

import square from '../../public/asset/img/square.png';
// import { PDFDocument } from 'pdf-lib';
import qrcode from '../../public/asset/img/qr_code_.png';

import LoaderDocs from '../../components/Modal/LoaderDocs/LoaderDocs';
import Cookies from 'js-cookie';
// import download from 'downloadjs';
import Modal from '../../components/Modal/Modal';
// import './style.css';

import PreviewAllDocs from './PreviewAllDocs';

/**
 * Page principale pour effectuer une signature
 * @returns ViewPDF
 */
const pixelToMillimeter = (value) => value * 0.2645833333;

const formatDocument = async (document) => {
  const formData = new FormData();
  formData.append('document', document);

  const token = Cookies.get('token');

  var myHeaders = new Headers();
  myHeaders.append('Authorization', `Bearer ${token}`);

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow',
  };

  const reponse = await fetch(
    'https://dkbsign.com/new_api_dkbsign/public/api/signatures/formater',
    requestOptions
  );
  if (!reponse.ok) {
    throw new Error('Network response was not ok');
  }

  const result = await reponse.json();
  return result;
}

function getToday() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  return dd + '/' + mm + '/' + yyyy;
}

export default function ViewDocPdf() {
  const { translate, langTranslate } = useTranslateContext();
  document.querySelectorAll('.modal-backdrop').forEach((el) => el.remove());
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const {
    allModalOpen,
    toggleFunction,
    setAllModalOpen,
    getMessageAlert,
    alertMsg,
    setIsAll,
    isAll
  } = useParamsDocument()

  const [openModaleSign, setOpenModaleSign] = useState(false);
  const [selectTypeSign, setSelectTypeSign] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'auto';
  }, []);

  const docList = useSelector(docListSelector);
  const signSel = useSelector(signatureSelector);

  const [loader, setLoader] = useState(false);
  var signataire = signSel.signataire;

  const [urlDoc, setUrlDoc] = useState('');
  const [signataireName, setSignataireName] = useState(null);
  const [imageSign, setImageSign] = useState('');
  const [pdfSize, setPdfSize] = useState({})
  const [error, setError] = useState('');
  const [boxList, setBoxList] = useState([]);
  const [type, setType] = useState('basic');
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [curNumber, setCurNumber] = useState(1);
  const [isSwicth, setIsSwitch] = useState(false);
  const [currentFile, setCurrentFile] = useState(docList[0]);
  const [DOCUMENT, SETDOCUMENT] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [imgCode, setImgCode] = useState({ uri: null, type: '', page: null });
  const [data, setData] = useState([]);
  const [isSigned, setIsSigned] = useState([]);
  const [coordSign, setCoordSign] = useState({
    x: 0,
    y: 0,
    display: 'none',
  });
  const docRef = useRef();
  const qrcodeRef = useRef();
  const boxsign = useRef();
  const signataireRef = useRef();
  const pdfRef = useRef();
  const canvasSignRef = useRef();

  useEffect(() => {
    formatDocument(currentFile)
      .then((res) => setUrlDoc(res.message))
      .catch((err) => console.log(err));
  }, [currentFile]);

  /** * UseEffect exécuté au moment du chargement de la page. * Il sert a inscrit du contenu dans le canvas selon sont type de choix. */
  useEffect(() => {
    setTimeout(() => {
      var choice = signSel.choice;
      var canvasWidth = 200;
      var canvasHeight = 100;

      var c = document.getElementById('signature-canvas');
      c.width = canvasWidth;
      c.height = canvasHeight;

      var ctx = c.getContext('2d');
      if (choice === 'text') {
        var image = signSel.previewText;
        var base_image = new Image();
        base_image.src = image;
        base_image.onload = function () {
          ctx.drawImage(
            base_image,
            0,
            0,
            200,
            100
          )
        }
      } else if (choice === 'draw') {
        var draw = signSel.draw;
        var base_image = new Image();
        base_image.src = draw;
        base_image.onload = function () {
          ctx.drawImage(
            base_image,
            0,
            0,
            canvasWidth * 0.5,
            canvasHeight * 0.5
          );
        };
      } else if (choice === 'image') {
        var image = signSel.image;
        var base_image = new Image();
        base_image.src = image;
        base_image.onload = function () {
          ctx.drawImage(
            base_image,
            0,
            0,
            base_image.width,
            base_image.height,
            0,
            0,
            canvasWidth,
            canvasHeight
          );
        };
      }

      setImageSign(c.toDataURL());
    }, 1000);
  }, []);

  /**
   * UseEffect exécuté au moment du chargement de la page.
   * Ecoute les modification sur l'état [imgCode].
   * Charge le lien CDN pour utiliser une librairie PDF.
   * Ajoute un evenement sur le document.
   */
  useEffect(() => {
    document.querySelectorAll('.modal-backdrop').forEach((el) => el.remove());
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    docRef.current.addEventListener('mousemove', (e) => {
      if (!imgCode.uri) return null;
      getCoordinateDoc(e);
    });
  }, [imgCode]);

  /**
   * UseEffect exécuté au moment du chargement de la page.
   * Ecoute les modification sur l'état [pageNumber, isSwicth].
   * Chargé de gérer l'affiche de signature, qrcode ... ,
   * selon la page ou le document sur le quel l'élément a été déposé
   */
  useEffect(() => {
    var elem = DOCUMENT.filter((it) => currentFile.name === it.link.name);
    elem[0] && setCurNumber(elem[0].id);

    var signatureSel = document.querySelectorAll('.signaturesel');
    var qrcodeSel = document.querySelectorAll('.qrcodesel');

    if (signatureSel) {
      for (let i = 0; i < signatureSel.length; i++) {
        const curFile = signatureSel[i].getAttribute('currentfile');

        if (
          curFile !== currentFile.name ||
          signatureSel[i].title !== String(pageNumber)
        ) {
          signatureSel[i].style.display = 'none';
        } else {
          signatureSel[i].style.display = 'block';
        }
      }
      isSwicth && setIsSwitch(!isSwicth);
    }

    if (qrcodeSel) {
      for (let i = 0; i < qrcodeSel.length; i++) {
        const curFile = qrcodeSel[i].getAttribute('currentfile');

        if (
          curFile !== currentFile.name ||
          qrcodeSel[i].title !== String(pageNumber)
        ) {
          qrcodeSel[i].style.display = 'none';
        } else {
          qrcodeSel[i].style.display = 'block';
        }
      }
      isSwicth && setIsSwitch(!isSwicth);
    }

    signataire.map((it) => {
      var squareSel = document.querySelectorAll(`.square${it.id}sel`);
      if (squareSel) {
        for (let i = 0; i < squareSel.length; i++) {
          const curFile = squareSel[i].getAttribute('currentfile');

          if (
            curFile !== currentFile.name ||
            squareSel[i].title !== String(pageNumber)
          ) {
            squareSel[i].style.display = 'none';
          } else {
            squareSel[i].style.display = 'block';
          }
        }
        isSwicth && setIsSwitch(!isSwicth);
      }
    });
  }, [pageNumber, isSwicth]);

    /**
 * Fonction appelé pour placer la mark sur tout les documents et revenir sur le premier document
 */
    // function modifyArray() {
    //   var pdf__Document = document.getElementsByClassName('react-pdf__Document')[0]
  
    //   if (DOCUMENT.length === 0) {
    //     return;
    //   }
  
    //   const firstElement = DOCUMENT[0];
  
    //   for (let i = 1; i < DOCUMENT.length; i++) {
    //     DOCUMENT[i] = {
    //       ...DOCUMENT[i], // conserve les propriétés `id`, `link`, et `className`
    //       nbSignature: firstElement.nbSignature,
    //       typeSign: [...firstElement.typeSign] // crée une copie du tableau typeSign
    //     };
  
    //     var elementSignature = document.getElementsByClassName('signaturesel')
    //     if (elementSignature.length) {
    //       const cloneSignature = elementSignature[0].cloneNode(true)
  
    //       cloneSignature.ondragend = (event) => endDragFx(event);
    //       cloneSignature.addEventListener('click', function init(e) {
    //         if (cloneSignature.getAttribute('mousemove') === 'true') {
    //           cloneSignature.removeEventListener('mousemove', init, false);
    //           cloneSignature.setAttribute('mousemove', 'false');
    //         } else {
    //           cloneSignature.setAttribute('mousemove', 'true');
    //         }
    //       });
    //       cloneSignature.setAttribute('currentFile', DOCUMENT[i].link.name);
    //       cloneSignature.childNodes[1].onclick = () => removeMarker({ type: 'signature' }, DOCUMENT, DOCUMENT[i].link);
  
    //       var stri = DOCUMENT[i].link.name + 'signature';
    //       stri = stri.replace('.', '');
    //       cloneSignature.id = stri;
  
    //       pdf__Document.appendChild(cloneSignature)
    //     }
  
    //     var elementQrcode = document.getElementsByClassName('qrcodesel')
    //     if (elementQrcode.length) {
    //       const cloneQrCode = elementQrcode[0].cloneNode(true)
  
    //       cloneQrCode.ondragend = (event) => endDragFx(event);
    //       cloneQrCode.addEventListener('click', function init(e) {
    //         if (cloneQrCode.getAttribute('mousemove') === 'true') {
    //           cloneQrCode.removeEventListener('mousemove', init, false);
    //           cloneQrCode.setAttribute('mousemove', 'false');
    //         } else {
    //           cloneQrCode.setAttribute('mousemove', 'true');
    //         }
    //       });
    //       cloneQrCode.setAttribute('currentFile', DOCUMENT[i].link.name);
    //       cloneQrCode.childNodes[1].onclick = () => removeMarker({ type: 'qrcode' }, DOCUMENT, DOCUMENT[i].link);
  
    //       var stri = DOCUMENT[i].link.name + 'qrcode';
    //       stri = stri.replace('.', '');
    //       cloneQrCode.id = stri;
  
    //       pdf__Document.appendChild(cloneQrCode)
    //     }
  
    //     var signataire = document.getElementsByClassName(`square${i}sel`)
    //     if (signataire.length) {
    //       const cloneSignataire = signataire[0].cloneNode(true)
  
    //       cloneSignataire.ondragend = (event) => endDragFx(event);
    //       cloneSignataire.addEventListener('click', function init(e) {
    //         if (cloneSignataire.getAttribute('mousemove') === 'true') {
    //           cloneSignataire.removeEventListener('mousemove', init, false);
    //           cloneSignataire.setAttribute('mousemove', 'false');
    //         } else {
    //           cloneSignataire.setAttribute('mousemove', 'true');
    //         }
    //       });
    //       cloneSignataire.setAttribute('currentFile', DOCUMENT[i].link.name);
    //       cloneSignataire.childNodes[1].onclick = () => removeMarker({ type: `square${i}` }, DOCUMENT, DOCUMENT[i].link);
  
    //       var stri = DOCUMENT[i].link.name + `square${i}`;
    //       stri = stri.replace('.', '');
    //       cloneSignataire.id = stri;
  
    //       pdf__Document.appendChild(cloneSignataire)
    //     }
  
    //     setImgCode({ uri: null, type: '' });
    //   }
    // }
  
    // useEffect(() => {
    //   if (isAll) {
    //     modifyArray()
    //     setIsAll(false)
    //   }
    // }, [DOCUMENT])
  
    /**
     * Fonction appelé l'orsqu'on change de document dans la liste
     */
    // const switchDocument = (link) => {
    //   var qr = document.getElementById('exit-qrcode')
    //   var square = document.getElementById('exit-square')
  
    //   if (qr) qr.remove()
  
    //   if (square) square.remove()
    //   setIsSwitch(true)
    //   setCurrentFile(link)
  
    //   var newLsDoc = DOCUMENT.map(doc => {
    //     if (doc.link == link)
    //       return ({ id: doc.id, 'link': doc.link, 'className': 'active', 'nbSignature': doc.nbSignature, 'typeSign': doc.typeSign })
    //     else
    //       return ({ id: doc.id, 'link': doc.link, 'className': '', 'nbSignature': doc.nbSignature, 'typeSign': doc.typeSign })
    //   })
  
    //   SETDOCUMENT(newLsDoc)
    // }

  /**
   * Fonction appelé pour affecter le nombre de page total d'un document au state setNumPages
   * @param {numPages} param0
   */
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  /**
   * Fonction appelé pour récupérer les coordonnées (x,y),
   * au moment ou la souris survole le document
   * @param {*} e
   */
  const getCoordinateDoc = (e) => {
    var rect = docRef.current.getBoundingClientRect();
    const display = 'block';

    var x = e.clientX - rect.left;
    var y = e.clientY - rect.top;

    if (x > 0.88 * rect.width) {
      x = 0.88 * rect.width;
      setCoordSign({ x, y, display });
    } else {
      setCoordSign({ x, y, display });
    }

    if (y > 0.9 * rect.height) {
      y = 0.9 * rect.height;
      setCoordSign({ x, y, display });
    } else {
      setCoordSign({ x, y, display });
    }
  }

  /**
   * Fonction appelé l'orsqu'une signature est sélectionné
   */
  const selectSign = () => {
    var c = document.getElementById('signature-canvas');

    setImgCode({ uri: c.toDataURL(), type: 'signature', page: pageNumber });
    const sign = canvasSignRef.current;

    const qrc = qrcodeRef.current;
    qrc && qrc.classList.remove('active-tools');
    sign.classList.add('active-tools');
  };

  /**
   * Fonction appelé l'orsqu'un QrCode est sélectionné
   */
  const selectQrCode = () => {
    setImgCode({ uri: qrcode, type: 'qrcode' });
    const qrc = qrcodeRef.current;

    const sign = canvasSignRef.current;
    sign.classList.remove('active-tools');
    qrc && qrc.classList.add('active-tools');
    setOpenModaleSign(false);
  };

  /**
   * Fonction appelé l'orsqu'un signataire est sélectionné
   * @param {*} e
   */
  const selectZone = (e) => {
    console.log(e.target);
    setImgCode({ uri: square, type: `square${e.target.id}` });

    var ls_signature = document.getElementsByClassName('lst-signataire');

    for (let i = 0; i < ls_signature[0].children.length; i++) {
      ls_signature[0].children[i].classList.remove('active-tools');
    }

    e.target.classList.add('active-tools');
  };

  /**
   * Fonction appelé l'orsqu'un element est supprimé du document
   * @param {*} uri
   * @param {*} doc
   */
  function removeMarker(uri, doc, current) {
    var newIsSigned = isSigned.filter((it) => it.type !== uri.type);

    if (uri.type === 'qrcode') {
      var stri = current.name + 'qrcode';
      stri = stri.replace('.', '');
      var resizer_br = document.getElementById(stri);
      resizer_br.remove();
    } else if (uri.type === 'signature') {
      var stri = current.name + 'signature';
      stri = stri.replace('.', '');
      var resizer_br = document.getElementById(stri);
      resizer_br.remove();
    } else if (uri.type.includes('square')) {
      signataire.map((it) => {
        var stri = current.name + `square${it.id}`;

        if (`square${it.id}` === uri.type) {
          stri = stri.replace('.', '');
          var resizer_br = document.getElementById(stri);

          resizer_br && resizer_br.remove();
        }
      });
    }

    var newLsDoc = doc.map((doc) => {
      if (doc.link.name === current.name)
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature - 1,
          typeSign: doc.typeSign.filter((it) => it !== uri.type),
        };
      else
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
    });

    SETDOCUMENT(newLsDoc);
    setIsSigned(newIsSigned);
  }

  const onDump = () => {
    if (docList.length > 1) {
      if (docList[0].name === currentFile.name) {
        getMessageAlert(imgCode.type)
        setAllModalOpen(true)
      } else placeMark()
    } else placeMark()
  }

  // function verifyContains(type) {
  //   console.warn("Cette fonction n'est pas autorisé !");
  //   var elem = DOCUMENT.filter((it) => currentFile.name === it.link.name);
  //   elem = elem[0];

  //   if (!elem.typeSign) return false;

  //   return elem.typeSign.includes(type);
  // }

  /**
   * Fonction appelé l'orsqu'un element est déplacé sur le document
   * @param {*} event
   */
  const endDragFx = (event) => {
    var rect = docRef.current.getBoundingClientRect();

    // console.log('test', event);

    var x, y;

    if (navigator.maxTouchPoints === 1) {
      // Le site est en format mobile
      x = event.changedTouches[0].clientX - rect.left;
      y = event.changedTouches[0].clientY - rect.top;
    } else {
      // Le site est en format desktop
      x = event.clientX - rect.left;
      y = event.clientY - rect.top;
    }

    if (x > 0.88 * rect.width) {
      x = 0.88 * rect.width;

      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    } else {
      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    }

    if (y > 0.9 * rect.height) {
      y = 0.9 * rect.height;

      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    } else {
      if (navigator.maxTouchPoints === 1) {
        // Le site est en format mobile
        event.target.offsetParent.style.top = y + 'px';
        event.target.offsetParent.style.left = x + 'px';
      } else {
        // Le site est en format desktop
        event.toElement.offsetParent.style.top = y + 'px';
        event.toElement.offsetParent.style.left = x + 'px';
      }
    }
  };

  /**
   * Fonction appelé l'orsqu'un element est déposé sur le document
   */
  const placeMark = () => {
    const div = document.createElement('div');
    div.style = `top:${coordSign.y}px;left:${coordSign.x}px;display:${coordSign.display}`;

    if (navigator.maxTouchPoints === 1) {
      // Le site est en format mobile
      div.addEventListener('touchend', (event) => endDragFx(event));
    } else {
      // Le site est en format desktop
      div.ondragend = (event) => endDragFx(event);
    }

    div.classList.add('area-doc-sign');
    div.classList.add(imgCode.type + 'sel');

    div.title = pageNumber;
    div.setAttribute('currentFile', currentFile.name);

    var stri = currentFile.name + imgCode.type;
    stri = stri.replace('.', '');
    div.id = stri;

    const qrc = qrcodeRef.current;
    const sign = canvasSignRef.current;

    sign && sign.classList.remove('active-tools');
    qrc && qrc.classList.remove('active-tools');

    var newLsDoc = DOCUMENT.map((doc) => {
      if (doc.link.name === currentFile.name)
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature + 1,
          typeSign: [...doc.typeSign, imgCode.type],
        };
      else
        return {
          id: doc.id,
          link: doc.link,
          className: doc.className,
          nbSignature: doc.nbSignature,
          typeSign: doc.typeSign,
        };
    });

    SETDOCUMENT(newLsDoc);

    div.addEventListener('click', function init(e) {
      if (div.getAttribute('mousemove') === 'true') {
        div.removeEventListener('mousemove', init, false);
        div.setAttribute('mousemove', 'false');
      } else {
        div.setAttribute('mousemove', 'true');
      }
    });

    div.className = div.className + ' resizable';

    var resizer_br = document.createElement('div');
    resizer_br.className = 'resizer-br';

    var exit_ic = document.createElement('div');
    exit_ic.innerText = 'X';
    exit_ic.className = 'exit-ic';
    exit_ic.onclick = () => removeMarker(imgCode, newLsDoc, currentFile);

    var text_p = document.createElement('p');
    text_p.innerText =
      signataireName && imgCode.type.includes('square') ? signataireName : '';
    text_p.style = 'position: absolute; top: 50%; left: 20%;';

    div.appendChild(resizer_br);
    div.appendChild(exit_ic);
    div.appendChild(text_p);

    resizer_br.addEventListener('mousedown', initDrag, false);

    const img = document.createElement('img');
    img.style = 'width:auto;height:auto';
    img.classList.add('.pic');
    img.src = imgCode.uri;

    div.appendChild(img);
    setBoxList((prevState) => [...prevState, div]);

    boxsign.current.parentNode.appendChild(div);
    setImgCode({ uri: null, type: '' });
    setIsSigned((prev) => [...prev, imgCode]);

    var startX, startY, startWidth, startHeight;

    function initDrag(e) {
      startX = e.clientX;
      startY = e.clientY;
      startWidth = parseInt(
        document.defaultView.getComputedStyle(div).width,
        10
      );
      startHeight = parseInt(
        document.defaultView.getComputedStyle(div).height,
        10
      );
      document.documentElement.addEventListener('mousemove', doDrag, false);
      document.documentElement.addEventListener('mouseup', stopDrag, false);
    }

    function doDrag(e) {
      var x = startWidth + e.clientX - startX;
      var y = startHeight + e.clientY - startY;
      if (x < 50 || y < 50 || x > 100 || y > 100) return;

      div.style.width = x + 'px';
      div.style.height = y + 'px';
      img.style.width = x + 'px';
      img.style.height = y + 'px';
    }

    function stopDrag(e) {
      document.documentElement.removeEventListener('mousemove', doDrag, false);
      document.documentElement.removeEventListener('mouseup', stopDrag, false);
    }
  };

  /**
   * Fonction appelé pour effectuer des vérifications sur l'état des document
   */
  const checkDocs = () => {
    setShowModal(true);
    var elemToSend = [];

    DOCUMENT.map((el) => {
      var temp = {
        document: el.link,
        element: [],
      };

      el.typeSign.map((it) => {
        var obj = {};
        var element = document.getElementsByClassName(it + 'sel');
        Object.values(element);

        var curdoc = Object.values(element).filter((it) => {
          if (it.getAttribute('currentfile') === el.link.name) {
            return it;
          }
        });

        obj[it] = curdoc;
        temp.element.push(obj);
      });
      elemToSend.push(temp);
    });

    setData(elemToSend);
    var docNotSigned = signSel.qrCode
      ? DOCUMENT.filter(
        (it) =>
          !(
            it.typeSign.includes('signature') &&
            it.typeSign.includes('qrcode')
          )
      )
      : DOCUMENT.filter((it) => !it.typeSign.includes('signature'));

    docNotSigned = signSel.HaveSignataire
      ? DOCUMENT.filter((it) => {
        var typeSign = it.typeSign.filter(
          (t) => t !== 'signature' && t !== 'qrcode'
        );

        if (typeSign.length === 0) return it;
        if (typeSign.length !== signSel.signataire.length) return it;
      })
      : docNotSigned;

    if (docNotSigned.length === 0) {
      setMessage(translate.admin.etreVousSurAvoirLu);
      setType('confirm');
    } else if (docNotSigned.length === 1) {
      setMessage(
        `${translate.admin.leDocument} ${docNotSigned[0].link.name} ${translate.admin.nApasEteSigne}`
      );
      setType('basic');
    } else {
      var names = [];
      console.log('else', docNotSigned);
      docNotSigned.map((it) => names.push(it.link.name));

      setMessage(
        `${translate.admin.leDocument}s ${names.toString()} ${translate.admin.nOntPasEteCorrectementRenseignes
        }`
      );
      setType('basic');
    }
  };

  /**
   * Fonction appelé pour envoyer des informations a l'API
   */
  const sendData = async () => {
    const DPI = 75;
    const MMM = 25.4;
    const name = JSON.parse(Cookies.get('user')).nom + ' ' + JSON.parse(Cookies.get('user')).prenoms

    if (signSel.HaveSignataire) {
      var i_docs = 0
      data.map(async (docs, key) => {
        i_docs++
        var sgaires = []

        signataire.map((sgn) => {

          if (signSel.mode === 'signer_faire_signer') {
            var x = docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.left) :
              docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.left) :
                parseFloat(docs.element[2][`square${sgn.id}`][0].style.left)

            var y = docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.top) :
              docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.top) :
                parseFloat(docs.element[2][`square${sgn.id}`][0].style.top)

            var page = docs.element[0][`square${sgn.id}`] ? docs.element[0][`square${sgn.id}`][0].title :
              docs.element[1][`square${sgn.id}`] ? docs.element[1][`square${sgn.id}`][0].title :
                docs.element[2][`square${sgn.id}`][0].title

            if (signSel.HaveOrder) {
              sgaires.push({
                email: sgn.email,
                telephone: sgn.telephone,
                ordre: 1,
                nom: sgn.nom,
                page: page,
                code_auth: String(signSel.authCode),
                position: {
                  x: x,
                  y: y
                }
              })
            } else {
              sgaires.push({
                email: sgn.email,
                telephone: sgn.telephone,
                ordre: sgn.id,
                nom: sgn.nom,
                page: page,
                code_auth: String(signSel.authCode),
                position: {
                  x: x,
                  y: y
                }
              })
            }
          } else {

            var x = docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.left) :
              docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.left) :
                parseFloat(docs.element[2][`square${sgn.id}`][0].style.left)

            var y = docs.element[0][`square${sgn.id}`] ? parseFloat(docs.element[0][`square${sgn.id}`][0].style.top) :
              docs.element[1][`square${sgn.id}`] ? parseFloat(docs.element[1][`square${sgn.id}`][0].style.top) :
                parseFloat(docs.element[2][`square${sgn.id}`][0].style.top)

            var page = docs.element[0][`square${sgn.id}`] ? docs.element[0][`square${sgn.id}`][0].title :
              docs.element[1][`square${sgn.id}`] ? docs.element[1][`square${sgn.id}`][0].title :
                docs.element[2][`square${sgn.id}`][0].title

            if (signSel.HaveOrder) {
              sgaires.push({
                email: sgn.email,
                telephone: sgn.telephone,
                ordre: 1,
                nom: sgn.nom,
                page: page,
                code_auth: String(signSel.authCode),
                position: {
                  x: x,
                  y: y
                },
              })
            } else {
              sgaires.push({
                email: sgn.email,
                telephone: sgn.telephone,
                ordre: sgn.id,
                nom: sgn.nom,
                page: page,
                code_auth: String(signSel.authCode),
                position: {
                  x: x,
                  y: y
                },
              })
            }
          }
        })

        if (signSel.mode === 'signer' || signSel.mode === 'signer_faire_signer') {
          var canvas = document.getElementById("signature-canvas");
          var ImagedataURL = canvas.toDataURL();

          ImagedataURL = signSel.choice === 'text' ? signSel.text : ImagedataURL

          var posX = docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.left) * 1.35)) : docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.left) * 1.35))
          var posY = docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.top) * 2.7)) : docs.element[1].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.top) * 2.7)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].signature[0].style.top) * 2.7))

          var datasended = {
            "Key_Api": "test@dkbsign7",
            "Id_cl": "0708155815",
            "signataire": "",
            "signataire2": "",
            "posX_signataire2": "",
            "posY_signataire2": "",
            "signataire3": "",
            "posX_signataire3": "",
            "posY_signataire3": "",
            "nbre_signatair": "1",
            "Code_ctr": "cl07",
            "nom_ctr": docs.document.name,
            "posX": posX,
            "posY": posY,
            "posX_qr": signSel.qrCode ? docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) : docs.element[2].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[2].qrcode[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) : "",
            "posY_qr": signSel.qrCode ? docs.element[0].qrcode ? (parseFloat(docs.element[0].qrcode[0].style.top) / DPI) * MMM : docs.element[2].qrcode? (parseFloat(docs.element[2].qrcode[0].style.top) / DPI) * MMM : (parseFloat(docs.element[1].qrcode[0].style.top) / DPI) * MMM : '',
            "taille_qr": 20,
            "img_signataire_png": ImagedataURL,
            "format_img_signataire": 1,
            "posX_Imgsign": posX,
            "posY_Imgsign": posY,
            "page_sign": docs.element[0].signature ? docs.element[0].signature[0].title : docs.element[1].signature ? docs.element[1].signature[0].title : docs.element[2].signature[0].title,
            "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
            "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
            "initial": "",
            "qrcodeyes": signSel.qrCode ? "1" : "0",

            "date_ctr": getToday(),
            "posX_date": posX+38,
            "posY_date": posY+25,
            "taille_date": "15"
          }

          datasended.posY_qr += 10
          const dument = await formatDocument(docs.document)

          const formData = new FormData();
          formData.append("data", JSON.stringify(datasended));
          formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
          formData.append('document', dument.message)
          formData.append('signataires', JSON.stringify(sgaires))
          formData.append('action', signSel.mode)
          formData.append('sendAlert', key > 0 ? 0 : 1)

          const token = Cookies.get('token')

          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
          };

          setLoader(true)
          fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
            .then(result => {
              if (result.status !== 200) {
                setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                return
              }
              // if (i_docs === data.length) window.location.href = '/alldocument'
            })
            .catch(error => {
              console.log(error)
              setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
            });
        } else {

          var datasended = {
            "Key_Api": "test@dkbsign7",
            "Id_cl": "0708155815",
            "signataire": "",
            "signataire2": "",
            "posX_signataire2": "",
            "posY_signataire2": "",
            "signataire3": "",
            "posX_signataire3": " ",
            "posY_signataire3": " ",
            "nbre_signatair": "1",
            "Code_ctr": "cl07",
            "nom_ctr": docs.document.name,
            "posX": "",
            "posY": "",
            "posX_qr": signSel.qrCode ?
              docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) :
                docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) :
                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35))
              : "",
            "posY_qr": signSel.qrCode ?
              docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.top) * 2.7)) :
                docs.element[1].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 2.7)) :
                  pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 2.7)) : "",
            "taille_qr": 20,
            "img_signataire_png": "",
            "format_img_signataire": 1,
            "posX_Imgsign": "",
            "posY_Imgsign": "",
            "page_sign": signSel.qrCode ?
              docs.element[0].qrcode ? docs.element[0].qrcode[0].title : docs.element[1].qrcode ? docs.element[1].qrcode[0].title : docs.element[2].qrcode[0].title : "1",
            "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
            "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
            "initial": "",
            "qrcodeyes": signSel.qrCode ? "1" : "0"
          }

          datasended.posY_qr += 10

          const dcment = await formatDocument(docs.document)

          // console.log(`here: ${key}`)
          const formData = new FormData();
          formData.append("data", JSON.stringify(datasended));
          formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
          formData.append('document', dcment.message)
          formData.append('signataires', JSON.stringify(sgaires))
          formData.append('action', signSel.mode)
          formData.append('sendAlert', key > 0 ? 0 : 1)

          const token = Cookies.get('token')

          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${token}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
            redirect: 'follow'
          };

          setLoader(true)
          fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
            .then(result => {
              if (result.status !== 200) {
                setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
                return
              }
              console.log(`in fetch: ${i_docs}`)
              if (i_docs === data.length) window.location.href = '/alldocument'
            })
            .catch(error => {
              console.log(error)
              setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
            });
        }
      })
    } else {
      var canvas = document.getElementById("signature-canvas");
      var ImagedataURL = canvas.toDataURL();

      ImagedataURL = signSel.choice === 'text' ? signSel.text : ImagedataURL

      var i_docs = 0
      data.map(async (docs, key) => {
        i_docs++

        var posX = docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.left) * 1.35))
        var posY = docs.element[0].signature ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].signature[0].style.top) * 2.7)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].signature[0].style.top) * 2.7))

        var datasended = {
          "Key_Api": "test@dkbsign7",
          "Id_cl": "0708155815",
          "signataire": signSel.paraphe ? name : '',
          "signataire2": signSel.paraphe ? name : '',
          "posX_signataire2": "",
          "posY_signataire2": "",
          "signataire3": "",
          "posX_signataire3": "",
          "posY_signataire3": "",
          "nbre_signatair": "1",
          "Code_ctr": "cl07",
          "nom_ctr": docs.document.name,
          "ctr": "http://www.dkbsolutions.com/Api_dkbsign4/include/DOCSIGN_BICICI/TEMPLATE/Contrat_Signature_KO.pdf",
          "posX": posX,
          "posY": posY,
          "posX_qr": signSel.qrCode ? docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.left) * 1.35)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.left) * 1.35)) : "",
          "posY_qr": signSel.qrCode ? docs.element[0].qrcode ? pixelToMillimeter(Math.max(0, parseFloat(docs.element[0].qrcode[0].style.top) * 2.7)) : pixelToMillimeter(Math.max(0, parseFloat(docs.element[1].qrcode[0].style.top) * 2.7)) : "",
          "taille_qr": 20,
          "img_signataire_png": ImagedataURL,
          "format_img_signataire": 1,
          "posX_Imgsign": posX,
          "posY_Imgsign": posY,
          "page_sign": docs.element[0].signature ? docs.element[0].signature[0].title : docs.element[1].signature ? docs.element[1].signature[0].title : docs.element[2].signature[0].title,
          "Largeur_img_signataire_png": pixelToMillimeter(200 * 1.35),
          "Hauteur_img_signataire_png": pixelToMillimeter(100 * 1.35),
          "initial": "",
          "qrcodeyes": signSel.qrCode ? "1" : "0",

          "date_ctr": getToday(),
          "posX_date": posX+38,
          "posY_date": posY+25,
          "taille_date": "15"
        }

        datasended.posY_qr += 10

        const formData = new FormData();

        const dcment = await formatDocument(docs.document)

        formData.append("data", JSON.stringify(datasended));
        formData.append("cle_securite", "DH9QhgopYgZ0VyQNSVjd9k5b4S2i78ng781407RrlAolzMAeQYwVSYLNA==");
        formData.append('document', dcment.message)
        formData.append('action', signSel.mode)
        formData.append('sendAlert', key > 0 ? 0 : 1)

        const token = Cookies.get('token')

        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: formData,
          redirect: 'follow'
        };

        setLoader(true)
        fetch(`https://dkbsign.com/new_api_dkbsign/public/api/signatures?lang=${langTranslate}`, requestOptions)
          .then(result => {
            if (result.status !== 200) {
              setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
              return
            }
            if (i_docs === data.length) window.location.href = '/alldocument'
          })
          .catch(error => {
            console.log(error)
            setError('Une erreur est survenue l\'ors du traitement, veuillez ressayer')
          });
      })
    }
  }

  // function fileToArrayBuffer(file) {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onload = function (event) {
  //       const arrayBuffer = event.target.result;
  //       resolve(arrayBuffer);
  //     };

  //     reader.onerror = function (event) {
  //       reject(event.target.error);
  //     };

  //     reader.readAsArrayBuffer(file);
  //   });
  // }

  // function dataURLToArrayBuffer(dataURL) {
  //   var base64 = dataURL.split(',')[1];
  //   var binaryString = window.atob(base64);
  //   var length = binaryString.length;
  //   var bytes = new Uint8Array(length);

  //   for (var i = 0; i < length; i++) {
  //     bytes[i] = binaryString.charCodeAt(i);
  //   }

  //   return bytes.buffer;
  // }

  // Gérer le début du glisser-déposer
  const handleDragStart = (event, data) => {
    event.currentTarget.classList.add('dragging');
  };

  // Gérer la fin du glisser-déposer
  const handleDragEnd = (event) => {
    event.currentTarget.classList.remove('dragging');
  };

  // Gérer le survol de la zone de dépôt
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event, data) => {
    event.preventDefault();
  };
  const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  };

  const handleCloseModale = () => {
    setOpenModaleSign(false);
    setSignataireName(null);
  };

  const handleCopySign = (e) => {
    e.preventDefault();
    if (selectTypeSign === 'qrCode') {
      setOpenModaleSign(false);

      selectQrCode(e);
    }
    if (selectTypeSign === 'signCode') {
      selectSign(e);
    }

    if (selectTypeSign === 'signataire') {
      setOpenModaleSign(false);

      selectZone(e);
    }

    handleCloseModale();
  };

  const handleOpenModaleSign = (e) => {
    setOpenModaleSign(true);
  };

  const getDocumentType = () => {
    if (pdfSize.width) {
      if (pdfSize.width > 850) return "landscape"
      else return "portrait"
    }
    return "portrait"
  }

  return (
    <div style={{ position: 'relative' }}>
      <Container>
        <LoaderDocs showModal={loader} erros={error} setShowModal={setLoader} />
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          error={message}
          type={type}
          fxt={sendData}
        />
        <div
          style={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '30px',
          }}
        >
          {(signSel.image || signSel.draw || signSel.text !== '') && (
            <div
              onClick={(e) => {
                setSelectTypeSign('signCode');
                handleOpenModaleSign(e);
              }}
              style={{
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '0 0 5px 0 #dadada',
                marginRight: '15px',
                textAlign: 'center',
                width: '100px',
                cursor: 'pointer',
              }}
            >
              <BsPenFill size={25} color="#009EF7" />
              <canvas
                id="signature-canvas"
                width="50"
                height="50"
                ref={canvasSignRef}
                onClick={(e) => {
                  setSelectTypeSign('signCode');
                  handleOpenModaleSign(e);
                }}
              ></canvas>
              <p>{translate.admin.cliquezPourSigne}</p>
            </div>
          )}

          {signSel.qrCode && (
            <div
              onClick={(e) => {
                setSelectTypeSign('qrCode');
                handleOpenModaleSign(e);
              }}
              style={{
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '0 0 5px 0 #dadada',
                marginRight: '15px',
                textAlign: 'center',
                width: '100px',
                cursor: 'pointer',
              }}
            >
              <ImQrcode size={25} color="#009EF7" />
              <p>{translate.admin.votreQrcode}</p>
            </div>
          )}

          {signataire.length > 0 && (
            <div
              style={{
                padding: '5px',
                borderRadius: '5px',
                boxShadow: '0 0 5px 0 #dadada',
                marginRight: '15px',
                textAlign: 'center',
                width: '100px',
                cursor: 'pointer',
              }}
            >
              {signataire.map((it) => (
                <p
                  key={it.id}
                  id={it.id}
                  ref={signataireRef}
                  onClick={(e) => {
                    setSelectTypeSign('signataire');
                    handleOpenModaleSign();

                    setSignataireName(it.nom);
                    // selectZone(e);
                  }}
                >
                  <RiUser2Fill size={20} color="green" /> {it.nom}
                </p>
              ))}
              <p className="signataire-zn">{translate.admin.signataire}</p>
            </div>
          )}
        </div>

        <div
          style={{
            flexGrow: 1,
            height: '80vh',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '15px',
          }}
        >
          <div
            onMouseDown={() => false}
            onSelect={() => false}
            style={{
              // background: 'gray',
              width: 'auto',
              boxSizing: 'border-box',
              textAlign: 'center',
              padding: '10px',
              position: 'absolute',
              paddingRight: '30px',
              boxShadow: '0 0 5px 0 #dadada',
              borderRadius: '10px',
            }}
          >
            <div
              onDragOver={handleDragOver}
              // onTouchMove={handleDragOver}
              onDrop={(e) => handleDrop(e, null)}
              ref={docRef}
              onClick={() => imgCode.uri && onDump()}
              // className={getDocumentType() === "landscape" ? 'document_landscape' : 'document'}
              style={{
                padding: '25px',
                border: '1px solid #dadada',
                borderRadius: '5px',
                borderStyle: 'dashed',
                background: '#fafafa',
              }}
            >
              <Document
                ref={pdfRef}
                file={docList[0]}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                // style={{ background: 'teal' }}
              >
                {imgCode.uri && (
                  <div
                    ref={boxsign}
                    draggable
                    style={{
                      top: coordSign.y,
                      left: coordSign.x,
                      display: coordSign.display,
                    }}
                    onDragStart={(e) => handleDragStart(e, 'Données à glisser')}
                    onDragEnd={handleDragEnd}
                    className="box-sign"
                  >
                    <img
                      src={imgCode.uri ? imgCode.uri : null}
                      style={{ width: '100px', height: '100px' }}
                      alt="Placer une signature ou qrcode"
                    />
                  </div>
                )}
                <Page
                  renderTextLayer={false}
                  width={window.innerWidth < 450 ? 310 : 500}
                  size="A4"
                  pageNumber={pageNumber}
                />
              </Document>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: '15px',
              }}
            >
              <p>
                Page {pageNumber} {translate.admin.sur} {numPages}
              </p>
              <input
                className="btn-page"
                min="1"
                type="number"
                value={pageNumber}
                onChange={(e) => {
                  if (e.currentTarget.value === '') setPageNumber(0);
                  if (parseInt(e.currentTarget.value) <= numPages)
                    setPageNumber(parseInt(e.currentTarget.value));
                }}
              />{' '}
              <p>
                {pageNumber >= 2 && (
                  <GoArrowLeft
                    onClick={() => {
                      pageNumber !== 1 && setPageNumber(pageNumber - 1);
                    }}
                    style={{ cursor: 'pointer' }}
                    size={25}
                  />
                )}

                {pageNumber !== numPages && (
                  <GoArrowRight
                    onClick={() => {
                      pageNumber !== numPages && setPageNumber(pageNumber + 1);
                    }}
                    style={{ cursor: 'pointer' }}
                    size={25}
                  />
                )}
              </p>
            </div>

            <button
              style={{
                background:
                  curNumber !== DOCUMENT.length || pageNumber !== numPages
                    ? '#dadada'
                    : '#009EF7',
                padding: '10px',
                borderRadius: '10px',
                color: '#fff',
              }}
              onClick={() => checkDocs()}
              disabled={
                curNumber !== DOCUMENT.length || pageNumber !== numPages
              }
            >
              {signSel.mode === 'signer_faire_signer'
                ? translate.admin.signerFaireSigner
                : signSel.mode === 'signer'
                  ? translate.admin.termine
                  : translate.admin.faireSigner}
              <MdDone style={{ marginLeft: '7px' }} size={24} />
            </button>
          </div>
        </div>
        <PreviewAllDocs
          setCurrentFile={setCurrentFile}
          setIsSigned={setIsSigned}
          docList={docList}
          DOCUMENT={DOCUMENT}
          SETDOCUMENT={SETDOCUMENT}
          setIsSwitch={setIsSwitch}
          isSwicth={isSwicth}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            display: openModaleSign ? 'flex' : 'none',
          }}
        >
          <div
            style={{
              minWidth: '200px',
              minHeight: '100px',
              maxWidth: '450px',
              background: '#fff',
              borderRadius: '5px',
              boxShadow: '0 0 5px 0 #ddd',
              padding: '15px',
            }}
          >
            <h4>{translate.admin.info}</h4>
            <p>{translate.admin.cliquezSurLeBouton}</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <button
                onClick={(e) => handleCopySign(e)}
                style={{
                  background: '#009EF7',
                  padding: '10px',
                  marginLeft: '10px',
                  color: '#fff',
                  borderRadius: '4px',
                }}
              >
                {translate.admin.oui}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
