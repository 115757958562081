import React, {useEffect} from 'react'
import Container from "../../components/Container/Container"
import HeaderCard from '../../components/Rapport/HeaderCard'
import PlotCard from '../../components/Rapport/PlotCard'
import TableCard from '../../components/Dashbord_Card/TableCard'

/**
 * Page du Menu Rapport
 * @returns Rapport
 */
export default function Rapport() {

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

    return (
      <Container>
        <HeaderCard />
        <TableCard />
        <PlotCard />
      </Container>
    )
}
