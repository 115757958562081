import React from 'react'
import Cookies from 'js-cookie'

import { useTranslateContext } from '../../hooks';

export default function ProgressBar({ handleSetSignType }) {
  const { translate } = useTranslateContext();
  const user = JSON.parse(Cookies.get('user'))

  const seulSignataire = user.signer
  const faireSigner = user.faire_signer
  const signeFaireSigne = user.signer_faire_signer

  return (
    <div className="d-flex align-items-center flex-column mt-3 w-50">

      <div className="dropdown">
        <p className='signer-txt'>{translate.admin.signer}</p>
        <div className="dropdown-content">
          {
            seulSignataire ? (
              <a href="#" onClick={() => handleSetSignType(1)} className="me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item" data-bs-toggle="modal" data-bs-target="#MainSeulSignModal">
                <i className="bi bi-person-check-fill"></i> {translate.admin.seulSignataire}
              </a>
            ) : null
          }{
            faireSigner ? (
              <a href="#" onClick={() => handleSetSignType(2)} className="me-4 btn btn-bg-white btn-active-color-primary btn-wh option-item" data-bs-toggle="modal" data-bs-target="#MainFaireSignModal">
                <i className="bi bi-person-bounding-box"></i> {translate.admin.faireSigner}
              </a>
            ): null
          }
          {
            signeFaireSigne ? (
              <a href="#" onClick={() => handleSetSignType(3)} className="btn btn-bg-white btn-active-color-primary btn-wh option-item" data-bs-toggle="modal" data-bs-target="#MainSignFaireSignModal">
                <i className="bi bi-people-fill"></i> {translate.admin.signerFaireSigner}
              </a>
            ): null
          }
        </div>
      </div>

      <div className="content-progessBar">
        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
          <span style={{ color: 'black' }} className="fw-boldest fs-6">{ user.certificat ? user.certificat.volume_signatures_restant : user.entreprise.certificat.volume_signatures_restant } {translate.admin.signaturesRestantes}</span>
          <span style={{ color: 'black' }} className="fw-bolder val fs-6 text-gray-400">{ user.certificat ? user.certificat.volume_signatures_total : user.entreprise.certificat.volume_signatures_total } </span>
        </div>

        <div style={{ width: '50% !important' }} className="h-8px mx-3 w-100 bg-light-success rounded">
          <div className="bg-success rounded h-8px" role="progressbar" aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} style={{ width: '62%' }} />
        </div>
      </div>
    </div>
  )

}
