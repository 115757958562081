import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik';
import { Login } from './services';
import * as Yup from "yup";
import './login.css'


export default function LoginManage() {
    const [initialValues] = useState({
        email: '',
        password: ''
    })

    const LoginSchema = Yup.object().shape({
        email: Yup.string()
            .required('Champs requis'),

        password: Yup.string()
            .required('Champs requis!')
    });

    return (
        <div className="body-form">
            <Formik
                initialValues={initialValues}

                validationSchema={LoginSchema}

                onSubmit={async (values) => {
                    await Login(values)
                }}
            >

                {({ handleChange, handleBlur, values, handleSubmit }) => (
                    <Form>
                        <div className="form-login-admin">
                            <div className="title">DKBSign</div>
                            <div className="subtitle">Login</div>
                            <div className="input-container ic1">
                                <input 
                                    id="email" 
                                    className="input" 
                                    type="text" 
                                    placeholder=" " 
                                    name="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <div className="cut" />
                                <label htmlFor="email" className="placeholder">
                                    Email
                                </label>
                                <ErrorMessage className="error-msg" name="email" component={'div'} />
                            </div>
                            <div className="input-container ic2">
                                <input 
                                    id="password" 
                                    className="input" 
                                    type="password" 
                                    name="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder=" " 
                                />
                                <div className="cut" />
                                <label htmlFor="password" className="placeholder">
                                    Password
                                </label>
                                <ErrorMessage className="error-msg" name="password" component={'div'} />
                            </div>
                            <button type="text" className="submit">
                                Se connecter
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    )
}
