import React,{useEffect , useState } from 'react'
import Table from './Table'
import Row from './Row'
import Forms from './Modal/Forms'
import Delete from './Modal/Delete'
import { getUsers, userSelector, clearState } from '../../../services/reduxSlices/UserSlice'
import { useDispatch, useSelector } from 'react-redux';

export default function Add() {

    const [showForm, setShowForm] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [dataEdit, setDataEdit] = useState(null)

    const dispatch = useDispatch()
    const { ls_contacts , ctc_updated } = useSelector(userSelector)

    useEffect(() => {
        dispatch(getUsers())
        dispatch(clearState())

    }, [ctc_updated])

    return (
        <React.Fragment>
            <Table setShowForm={setShowForm} setDataEdit={setDataEdit}>
                {
                    ls_contacts.data &&
                    ls_contacts.data.map(it=> <Row data={it} setShowForm={setShowForm} setDataEdit={setDataEdit} setShowDelete={setShowDelete} />)
                }
            </Table>
            {showForm ? <Forms showForm={showForm} setShowForm={setShowForm} data={dataEdit} /> : <></>}
            {showDelete ? <Delete showDelete={showDelete} setShowDelete={setShowDelete} data={dataEdit} />:<></>}
        </React.Fragment>
    )
}