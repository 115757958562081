import React, { useState, useEffect } from 'react'
import LandingWrapper from './LandingWrapper'
import { BsFillHouseDoorFill, BsArrowRightCircle } from 'react-icons/bs'
import { FaNotesMedical } from 'react-icons/fa'
import { MdComputer, MdSell } from 'react-icons/md'
import { RiShoppingBag3Fill } from 'react-icons/ri'
import { Helmet } from "react-helmet";
import { GiHumanTarget } from 'react-icons/gi'
import { GetReferencement, GetImagesByPage } from './Admin/Main/services'
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import SpinnerPage from './SpinnerPage';
import './styles.css'

var empty_img = {
    "img1": null,
    "alt1": null,
    "img2": null,
    "alt2": null,
    "img3": null,
    "alt3": null,
    "img4": null,
    "alt4": null,
    "img5": null,
    "alt5": null,
    "img6": null,
    "alt6": null,
    "img7": null,
    "alt7": null,
    "img8": null,
    "alt8": null,
    "img9": null,
    "alt9": null,
    "img10": null,
    "alt10": null,
    "img11": null,
    "alt11": null,
    "img12": null,
    "alt12": null,
    "img13": null,
    "alt13": null,
    "img14": null,
    "alt14": null,
    "img15": null,
    "alt15": null,
    "img16": null,
    "alt16": null,
    "img17": null,
    "alt17": null,
    "img18": null,
    "alt18": null,
    "img19": null,
    "alt19": null,
    "img20": null,
    "alt20": null
}

const PAGE_NAME = 'SOLUTIONS'
const BASE_URL_IMG = `https://dkbsign.com/dkbsign_web_api/public/storage/websiteimages/${PAGE_NAME}`

export default function LandingSolution() {
    const [referencement, setReferencement] = useState(null)
    const [IMAGES, SETIMAGES] = useState(null)

    useEffect(() => {
        GetReferencement().then(source => setReferencement(source))
        GetImagesByPage({ cible: PAGE_NAME }).then(source => SETIMAGES(source))
    }, [])

    return (
        IMAGES ?
            <LandingWrapper>
                <Helmet>
                    <title>
                        {
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).siteTitle : "DKBSign"
                                : "DKBSign"
                        }
                    </title>
                    <meta
                        name="description"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_description : ""
                                : ""
                        }
                    />

                    <meta
                        name="keywords"
                        lang="fr"
                        content={
                            referencement ?
                                referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_keywords : ""
                                : ""
                        }
                    />

                    <meta name="robots" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_robots : ""
                            : ""
                    }
                    />
                    <meta name="category" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_category : ""
                            : ""
                    }
                    />

                    <meta name="identifier-url" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_identifier_url : ""
                            : ""
                    }
                    />

                    <meta name="reply-to" content={
                        referencement ?
                            referencement.find((dt) => dt.pageCible === PAGE_NAME) ? referencement.find((dt) => dt.pageCible === PAGE_NAME).meta_reply_to : ""
                            : ""
                    }
                    />
                </Helmet>
                <Header />
                <Service IMAGES={IMAGES[0] ? IMAGES[0] : empty_img} />
                <DocumentConcerned />
                <Integration />
            </LandingWrapper>
            :
            <div className='global-loader'>
                <SpinnerPage />
            </div>
    )
}

const Header = () => {

    return (
        <React.Fragment>
            <div className="landing-solution__body-content">
                <div className="flip-header-solution">
                    <Fade duration={4000} left>
                        <div className="landing-solution__body-content-left-title">
                            <h1>SOLUTIONS DÉDIÉES À DES SECTEURS D'ACTIVITÉ ET SERVICES SPÉCIFIQUES</h1>
                        </div>
                    </Fade>
                </div>
            </div>
        </React.Fragment>
    )
}

const Service = ({ IMAGES }) => {
    return (
        <React.Fragment>
            <div className="landing-solution__service-content">

                <div className="landing-solution__service-top">
                    <Fade duration={3000} left>
                        <div className="landing-solution__service-top-txt">
                            Le réseau DKBSign permet aux entreprises de tout
                            type et de toute taille de conclure des affaires
                            plus rapidement à tout moment, en tout lieu et sur
                            tous les appareils grâce à la solution de signature
                            électronique la plus utilisée en Côte d'Ivoire. Ci-
                            dessous, vous trouverez des exemples illustrant
                            comment DKBSign peut transformer des secteurs
                            et des services :
                        </div>
                    </Fade>

                    <Fade duration={3000} right>
                        <img 
                            src={IMAGES.img1 ? `${BASE_URL_IMG}/${IMAGES.img1}` : "./assets/media/home_icon/Signature-tablette.png" }
                            className='landing-solution__service-img' 
                            alt={IMAGES.alt1 ? `${IMAGES.alt1}` : ""}
                        />
                    </Fade>
                </div>

                <div className="landing-solution__service-bottom">
                    <Bounce duration={4000} left>
                        <div className="landing-solution__service-bottom-col">
                            <div className="landing-solution__service-bottom-items">
                                <BsFillHouseDoorFill color='white' className='landing-solution__item-img' />
                                <p>Immobilier</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <FaNotesMedical color='white' className='landing-solution__item-img' />
                                <p>Assurance</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <MdComputer color='white' className='landing-solution__item-img' />
                                <p>Haute technologie</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <img 
                                    src={IMAGES.img2 ? `${BASE_URL_IMG}/${IMAGES.img2}` : "./assets/media/home_icon/banque-solution.png" }
                                    className='landing-solution__item-img' 
                                    alt={IMAGES.alt2 ? `${IMAGES.alt2}` : ""}
                                />
                                <p>Banque</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <img 
                                    src={IMAGES.img3 ? `${BASE_URL_IMG}/${IMAGES.img3}` : "./assets/media/home_icon/industrie-solution.png" }
                                    className='landing-solution__item-img' 
                                    alt={IMAGES.alt3 ? `${IMAGES.alt3}` : ""}
                                />
                                <p>Industrie</p>
                            </div>
                        </div>
                    </Bounce>

                    <Bounce duration={5000} right>
                        <div className="landing-solution__service-bottom-col">
                            <div className="landing-solution__service-bottom-items">
                                <MdSell color='white' className='landing-solution__item-img' />
                                <p>Ventes</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <RiShoppingBag3Fill color='white' className='landing-solution__item-img' />
                                <p>Achat</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <GiHumanTarget color='white' className='landing-solution__item-img' />
                                <p>Ressources humaines</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <img 
                                    src={IMAGES.img4 ? `${BASE_URL_IMG}/${IMAGES.img4}` : "./assets/media/home_icon/commercial-solution.png" }
                                    className='landing-solution__item-img' 
                                    alt={IMAGES.alt4 ? `${IMAGES.alt4}` : ""}
                                />
                                <p>Commercial</p>
                            </div>
                            <div className="landing-solution__service-bottom-items">
                                <img 
                                    src={IMAGES.img5 ? `${BASE_URL_IMG}/${IMAGES.img5}` : "./assets/media/home_icon/marketing-solution.png" }
                                    className='landing-solution__item-img' 
                                    alt={IMAGES.alt5 ? `${IMAGES.alt5}` : ""}
                                />
                                <p>Marketing</p>
                            </div>
                        </div>
                    </Bounce>
                </div>
            </div>
        </React.Fragment>
    )
}

const DocumentConcerned = () => {
    const [cards, setCards] = useState([
        {
            no: <MdSell color={'white'} size={20} />,
            title: 'Vente',
            description: 'Devis, contrats de vente, Conditions Générales de Vente...'
        },
        {
            no: <GiHumanTarget color={'white'} size={20} />,
            title: 'Ressources humaines',
            description: 'Contrats de travail, avenants, notes de frais...'
        },
        {
            no: <RiShoppingBag3Fill color={'white'} size={20} />,
            title: 'Achat Finance',
            description: 'Factures, bons de commande, accords de confidentialité...'
        }
    ])

    return (
        <React.Fragment>
            <div className="landing-solution__document-concerned-content">

                <div className="landing-solution__document-concerned-title">Quels sont les documents concernés ?</div>

                <div className="landing-solution__document-concerned-desc">
                    En ligne, a distance ou en face-a-face, la signature électronique est essentielle au processus de contractualisation.
                    Elle est aujourd'hui incontournable et s'applique a tous les de documents. <br />
                    que vous pouvez signer a la main :
                </div>

                <div className="landing-some-click-cards">
                    {cards.map((card, index) => { return <SignSomeClickCard card={card} key={index} /> })}
                </div>
            </div>
        </React.Fragment>
    )
}

const SignSomeClickCard = ({ card }) => {
    return (
        <div className="landing-some-click-card border-card-blue">
            <div className="landing-some-click-card__number">{card.no}</div>
            <div className="landing-some-click-card__content">
                <div className="landing-some-click-card__content-title">{card.title}</div>
                <div className="landing-some-click-card__content-description">{card.description}</div>
            </div>
        </div>
    )
}

const Integration = () => {
    return (
        <React.Fragment>
            <div className="landing-solution__integration-content">
                <div className="landing-solution__integration-header">
                    <h2>Intégration de DKBSign dans vos outils d'aide a la vente</h2>
                </div>

                <div className="landing-solution__integration-bot">
                    <div className="landing-solution__integration-desc">
                        <div className="landing-solution__integration-img"><BsArrowRightCircle color={'#5858cc'} size={60} /></div>
                        <div className="landing-solution__integration-txt">
                            Comme nos connecteurs sont déjà pré
                            configurés dans les outils d'aide à la
                            vente (CRM) les plus populaires du
                            marché, l'intégration de DKBSign se
                            fait en quelques clics. En créant tous
                            types de workflow (plus ou moins
                            complexes) adaptés à vos besoins
                            métier, les partenariats établis par
                            DKBSign avec les acteurs
                            incontournables du marché vous
                            offrent un moyen simple et rapide
                            d'envoyer n'importe quel document à
                            n'importe quel signataire.
                        </div>
                    </div>
                    <a className="content_form-send __integration-btn" href="/home-apropos">CONTACTEZ-NOUS</a>
                </div>
            </div>
        </React.Fragment>
    )
}