import { createSlice } from '@reduxjs/toolkit'

export const docListSlice = createSlice({
    name: 'docList',
    initialState: [],
    reducers: {
        addDocs: (state, action) => [...state, ...action.payload],
        setDocs: (state, action) => [...action.payload],
        removeDoc: (state, action) => state.filter(doc => doc !== action.payload),
        clearDocs: () => []
    }
})

export const {
    addDocs,
    removeDoc ,
    setDocs,
    clearDocs
} = docListSlice.actions

export const docListSelector = state => state.docList
