import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    signataire: '',
    codeAuth: '',
    currentDoc: '',
    status: '',
}

export const SignataireSlice = createSlice({
    name: 'signataires',
    initialState,
    reducers: {
        setCurrentDoc: (state, action) => { 
            state.currentDoc = action.payload 
        },

        setCodeAuth: (state, action) => { 
            state.codeAuth = action.payload 
        },

        setSignataire: (state, action) => { 
            state.signataire = action.payload 
        },

        setStatus: (state, action) => {
            state.status = action.payload
        },
        
        clearState: () => initialState
    }
})

export const {
    setCurrentDoc,
    setCodeAuth,
    setSignataire,
    clearState,
    setStatus
} = SignataireSlice.actions

export const SignatairesSelector = state => state.signataires