import React, {useState} from 'react'
import Container from "../../../components/Admin/Container/Container"

export default function Notification() {
  const [message, setMessage] = useState('')

  const sendNotification = ()=>{
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer 76F1B56351CA80FA2CEF35C28C7FBBC1821C94A3267F69DDC0B4D2798122D573");
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "message": message,
      "channel": "public",
      "event": "info"
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch("http://localhost:5500/sendAlert", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  }

  return (
    <Container>
      <div className="py-10">
        {/*begin::Heading*/}
        <h1 className="anchor fw-bolder mb-5" id="textarea-input" data-kt-scroll-offset={50} style={{color:'white'}}>
          <a href="#textarea-input" />
            Envoyez la notification
        </h1>
        {/*end::Heading*/}
        {/*begin::Block*/}
        <div className="py-5">
          <div className="rounded border p-10" style={{backgroundColor:'whitesmoke'}}>
            {/*begin::Form*/}
              {/*begin::Input group*/}
              <div className="fv-row mb-10 fv-plugins-icon-container">
                {/*begin::Label*/}
                <label className="required fw-bold fs-6 mb-2">Votre message</label>
                {/*end::Label*/}
                {/*begin::Input*/}
                <textarea
                  name="textarea_input"
                  className="form-control form-control-solid"
                  defaultValue={""}
                  onChange={(e)=> setMessage(e.target.value)}
                  style={{
                    backgroundColor: 'black',
                    color: 'white'
                  }}
                />
                {/*end::Input*/}
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
              {/*end::Input group*/}
              {/*begin::Actions*/}
              <button
                id="kt_docs_formvalidation_textarea_submit"
                type="submit"
                className="btn btn-primary"
                onClick={()=> sendNotification()}
              >
                <span className="indicator-label">Envoyer</span>
                <span className="indicator-progress">
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2" />
                </span>
              </button>
              {/*end::Actions*/}
              <div />
            {/*end::Form*/}
          </div>
        </div>
        {/*end::Block*/}
        {/*begin::Code*/}
        {/*end::Code*/}
      </div>
    </Container>
  )
}
