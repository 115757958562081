import axios from 'axios';
import {
  ACTUALITE_URL,
  GET_CLIENT_URL,
  REFERENCEMENT_URL,
  API_URL,
  IMAGE_URL,
} from '../../../../services/api/URL_API';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const BASE_URL = 'https://dkbsign.com/dkbsign_web_api/public';

const token = Cookies.get('token_admin');

export const GetActualite = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${ACTUALITE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    toast.error('Impossible de récupérer les actualités', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetClient = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${GET_CLIENT_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.clients;
  } catch (error) {
    toast.error('Impossible de récupérer les clients', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetReferencement = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${REFERENCEMENT_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.referencement;
  } catch (error) {
    toast.error('Impossible de récupérer les referencements', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetActualiteById = async (name) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${ACTUALITE_URL}/show/${name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    toast.error("Cette actualité n'existe pas", {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetDevis = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}${API_URL}/demandedevis/index`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.demandedevis;
  } catch (error) {
    toast.error('Impossible de récupérer les clients', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetContact = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${API_URL}/contact/index`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data.data;
  } catch (error) {
    toast.error('Impossible de récupérer les clients', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetImages = async () => {
  try {
    const response = await axios.get(`${BASE_URL}${IMAGE_URL}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(response.data);

    return response.data.gestimages;
  } catch (error) {
    toast.error('Impossible de récupérer les images', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const GetImagesByPage = async ({ cible }) => {
  try {
    const response = await axios.get(
      `${BASE_URL}${IMAGE_URL}/index?pagecible=${cible}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.gestimages;
  } catch (error) {
    toast.error('Impossible de récupérer les images', {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};
