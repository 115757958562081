import React from 'react';
import { signModalSelector } from '../../../services/reduxSlices/SignModalSlice';
import { useSelector } from 'react-redux';

import { useTranslateContext } from '../../../hooks';

/**
 * Affichage les numéros des étapes de signatures
 */
const StepNavBarLeft = ({ currentStep = 1 }) => {
  const { translate } = useTranslateContext();
  const modalSignType = useSelector(signModalSelector);

  const handleHideMenuLeft = () => {
    switch (modalSignType) {
      case 1:
        return (
          <>
            <div className={'stepper-item ' + setStepState(2)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.signature}</h3>
                <div className="stepper-desc">{translate.admin.definissezVotreSignature}</div>
              </div>
            </div>
          </>
        );
        break;
      case 2:
        return (
          <>
            <div className={'stepper-item ' + setStepState(2)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.signataires}</h3>
                <div className="stepper-desc">{translate.admin.ajoutezVosSignataires}</div>
              </div>
            </div>

            <div className={'stepper-item ' + setStepState(3)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">3</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.configurer}</h3>
                <div className="stepper-desc">{translate.admin.parametre}</div>
              </div>
            </div>
          </>
        );

        break;
      case 3:
        return (
          <>
            <div className={'stepper-item ' + setStepState(2)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">2</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.signature}</h3>
                <div className="stepper-desc">{translate.admin.definissezVotreSignature}</div>
              </div>
            </div>

            <div className={'stepper-item ' + setStepState(3)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">3</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.signature}</h3>
                <div className="stepper-desc">{translate.admin.definissezVotreSignature}</div>
              </div>
            </div>

            <div className={'stepper-item ' + setStepState(4)}>
              <div className="stepper-line w-40px"></div>

              <div className="stepper-icon w-40px h-40px">
                <i className="stepper-check fas fa-check"></i>
                <span className="stepper-number">4</span>
              </div>

              <div className="stepper-label">
                <h3 className="stepper-title">{translate.admin.configurer}</h3>
                <div className="stepper-desc">{translate.admin.parametre}</div>
              </div>
            </div>
          </>
        );

        break;
    }
  };
  const setStepState = (step) => {
    if (currentStep > step) return 'completed';
    if (currentStep === step) return 'current';
    if (currentStep < step) return 'pending';
  };

  return (
    <>
      <div className={'stepper-item ' + setStepState(1)}>
        <div className="stepper-line w-40px"></div>

        <div className="stepper-icon w-40px h-40px">
          <i className="stepper-check fas fa-check"></i>
          <span className="stepper-number">1</span>
        </div>

        <div className="stepper-label">
          <h3 className="stepper-title">{translate.admin.chargement}</h3>
          <div className="stepper-desc">{translate.admin.chargezVosDocuments}</div>
        </div>
      </div>

      {handleHideMenuLeft()}
    </>
  );
};

export default StepNavBarLeft;
