import React, {useEffect} from 'react'
import Container from "../../../components/Admin/Container/Container"
import HeaderCard from '../../../components/Admin/Rapport/HeaderCard'
import PlotCard from '../../../components/Admin/Rapport/PlotCard'

/**
 * Page du Menu Rapport
 * @returns Rapport
 */
export default function DashboardAdmin() {

  useEffect(() => {
    let script = document.createElement("script")
    script.src = "./assets/js/scripts.bundle.js"
    document.body.appendChild(script)
  }, [])

    return (
      <Container>
        <HeaderCard />
        <PlotCard />
      </Container>
    )
}
