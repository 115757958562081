import React from 'react';
import './Page404.css';

/**
 * 
 * Cette page est affiché quand le serveur ne prend pas en charge une page
 * @returns Page404
 */
const Page404 = () => {
    return (
        <>
            <div className="content-404">
                <img src="./assets/img/undraw_cancel_re_pkdm.svg" alt="Error page not found"/>
            </div>
            <div className="message-content">
                <p className="message">
                    Page not found <br />
                </p>
                <a href="" className='back-link'>
                    Retour a la page d'accueil
                    <i className="bi bi-arrow-right"></i>
                </a>
            </div>
        </>
    );
};

export default Page404;