import React from 'react'
import { useDispatch } from 'react-redux'
import MainSeulSignModal from '../Modal/MainSignModal/SeulSignataire/MainSeulSignModal';
import MainFaireSignModal from '../Modal/MainSignModal/FaireSigne/MainFaireSignModal';
import MainSignFaireSignModal from '../Modal/MainSignModal/SigneFaireSigne/MainSignFaireSignModal';
import ImportContact from '../Modal/MainSignModal/ImportContact/ImportContact';
import { setSignTypeStep } from '../../services/reduxSlices/SignModalStepSlice';
import { setSignType } from '../../services/reduxSlices/SignModalSlice';
import ProgressBar from './ProgressBar'
import './Toolbar.css';

/**
 * 
 * Ce composant est la bar d'outils il est présent sur toute les pages
 * Situé en bas du NavBar
 * 
 * @returns Toolbar
 */
const Toolbar = () => {
    const dispatch = useDispatch()

    const handleSetSignType = (signType) => {
        dispatch(setSignType(signType))
        dispatch(setSignTypeStep(1))
    }

    return (
        <div className="toolbar py-5 py-lg-15" id="kt_toolbar">
            <div id="kt_toolbar_container" className="container-xxl header-progress">
               <ProgressBar handleSetSignType={handleSetSignType} />
            </div>

            <MainSeulSignModal />
            <MainFaireSignModal />
            <MainSignFaireSignModal />
            <ImportContact />
        </div>        
    )
}

export default Toolbar