import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiTwitterFill } from 'react-icons/ri';
import { ImLinkedin, ImFacebook } from 'react-icons/im';
import { GrFormNextLink, GrClose } from 'react-icons/gr';
import { GiHamburgerMenu } from 'react-icons/gi';
import { toast } from 'react-toastify';
import './styles.css';

import { useTranslateContext } from '../../hooks';

export default function LandingWrapper({ children }) {
  const { translate } = useTranslateContext();
  const [showMenu, setShowMenu] = useState(false);

  // if showMenu disable scroll on body
  if (showMenu) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = 'auto';
  }

  const redirectLogo = () => {
    window.location.href = '/';
  };

  return (
    <div>
      <MenuResponsive showMenu={showMenu} />
      <div className="landing-home__header">
        <img
          src="https://dkbsign.com/logo.png"
          onClick={redirectLogo}
          alt="logo-header_landing"
          className="logo-header_landing"
        />
        <a className="logo-header_menu" href="/">
          {translate.landingPage.accueil}
        </a>
        <a className="logo-header_menu" href="/home-product">
          {translate.landingPage.produits}
        </a>
        <a className="logo-header_menu" href="/home-solution">
          {translate.landingPage.solutions}
        </a>
        <a className="logo-header_menu" href="/home-api">
          {translate.landingPage.api}
        </a>
        <a className="logo-header_menu" href="/home-token">
          {translate.landingPage.token}
        </a>
        <a className="logo-header_menu" href="/home-plateform">
          {translate.landingPage.plateform}
        </a>
        <a className="logo-header_menu" href="/home-tarif">
          {translate.landingPage.tarif}
        </a>
        <a className="logo-header_menu" href="/home-reference">
          {translate.landingPage.reference}
        </a>
        <a className="logo-header_menu" href="/home-apropos">
          {translate.landingPage.propos}
        </a>

        {showMenu ? (
          <GrClose
            onClick={() => setShowMenu(false)}
            className="logo-hamburger"
            color={'black'}
            size={24}
          />
        ) : (
          <GiHamburgerMenu
            onClick={() => setShowMenu(true)}
            className="logo-hamburger"
            color={'black'}
            size={24}
          />
        )}
        <a className="logo-header_tel" href="tel:+2252722464724">
          +225 27 22 46 47 24
        </a>
        <a className="logo-header_menu" href="/">
          {translate.landingPage.fr}
        </a>
        <div className="dropdown-menu-clientspac">
          <a className="logo-header_menu">
            {translate.landingPage.espaceClient}
          </a>
          <div className="dropdown-menu-clientspac-content">
            <a
              className="logo-header_menu"
              target="_blank"
              href="https://dkbsign.com/login"
            >
              {translate.landingPage.connexion}
            </a>
            {/* <a className='logo-header_menu' href="#">Inscription</a> */}
          </div>
        </div>
      </div>
      {children}
      <div className="landing-footer">
        <div
          style={{ flexDirection: 'row', position: 'relative', top: '20%' }}
          className="landing-footer__menu-newsletter"
        >
          <div className="landing-footer__newsletter">
            <h5 className="landing-footer__newsletter_title">
              INSCRIVEZ-VOUS A LA NEWSLETTER
            </h5>
            <div className="__newsletter_input">
              <form
                action="https://dkbsolutions.us13.list-manage.com/subscribe/post?u=9a2953c557d96a708ac281578&amp;id=9545a1f1a4&amp;f_id=00b493e2f0"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();

                  toast.success('Souscription réussite', {
                    position: 'bottom-left',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }}
                target="_blank"
                className="form_newsletter"
                novalidate
              >
                <input
                  className="landing-footer__newsletter_input"
                  name="EMAIL"
                  type="email"
                  placeholder="Votre adresse email"
                />

                <button
                  type="submit"
                  style={{ backgroundColor: 'white' }}
                  value="send"
                >
                  <GrFormNextLink
                    type="submit"
                    className="__newsletter_input_next"
                    color="white"
                    size={25}
                  />
                </button>
              </form>
            </div>
          </div>
          <div className="social-network-newsletter">
            <a
              className="landing-footer__menu_items"
              target="_blank"
              href="https://twitter.com/dkbscertafrique"
            >
              <RiTwitterFill
                style={{ marginRight: 12 }}
                color="white"
                size={25}
              />
              Twitter
            </a>
            <a
              className="landing-footer__menu_items"
              target="_blank"
              href="https://www.linkedin.com/company/dkbs-certafrique/mycompany"
            >
              <ImLinkedin style={{ marginRight: 12 }} color="white" size={25} />
              Linkedin
            </a>
            <a
              className="landing-footer__menu_items"
              target="_blank"
              href="https://www.facebook.com/DKBSCertAfrique/?locale=fr_FR"
            >
              <ImFacebook style={{ marginRight: 12 }} color="white" size={25} />
              Facebook
            </a>
          </div>
        </div>
        <div className="landing-footer__content">
          <div
            style={{ position: 'relative', left: '9%' }}
            className="landing-footer__menu"
          >
            <a className="landing-footer__menu_items" href="/">
              {translate.landingPage.accueil}
            </a>
            <a className="landing-footer__menu_items" href="/home-product">
              {translate.landingPage.produits}
            </a>
            <a className="landing-footer__menu_items" href="/home-solution">
              {translate.landingPage.solutions}
            </a>
            <a className="landing-footer__menu_items" href="/home-api">
              {translate.landingPage.api}
            </a>
            <a className="landing-footer__menu_items" href="/home-token">
              {translate.landingPage.token}
            </a>
            <a className="landing-footer__menu_items" href="/home-plateform">
              {translate.landingPage.plateform}
            </a>
          </div>
          <div
            style={{ position: 'relative', bottom: '11%' }}
            className="landing-footer__menu"
          >
            <a className="landing-footer__menu_items" href="/home-tarif">
              {translate.landingPage.tarif}
            </a>
            <a className="landing-footer__menu_items" href="/home-reference">
              {translate.landingPage.reference}
            </a>
            <a className="landing-footer__menu_items" href="/home-apropos">
              {translate.landingPage.propos}
            </a>

          </div>
          <div className="landing-footer__img-cont">
            <img
              className="landing-footer__img"
              src="https://dkbsign.com/assets/media/home_icon/Logo-DKBSign-et-DKBSolutions.png"
              alt="logo"
            />
            <p className="landing-footer__img-cont-txt">
              {translate.landingPage.descriptionFooter}
            </p>
          </div>
        </div>

        <p className="landing-footer_copyrigth">
          Copyrigth © DKBSign 2017 | All Rigths Reserved by DKB Solutions
        </p>
      </div>
    </div>
  );
}

const MenuResponsive = ({ showMenu }) => {
  return (
    <div
      className={
        showMenu
          ? 'landing-home__header__menu-responsive-display'
          : 'landing-home__header__menu-responsive'
      }
    >
      <Link className="logo-header_menu-responsive" to="/">
        ACCEUIL
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-product">
        PRODUITS
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-solution">
        SOLUTIONS
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-api">
        API
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-token">
        TOKEN
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-plateform">
        PLATEFORM
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-tarif">
        TARIF
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-reference">
        REFERENCE
      </Link>
      <Link className="logo-header_menu-responsive" to="/home-apropos">
        A PROPOS
      </Link>

      <Link className="logo-header_menu-responsive" to="/login">
        ESPACE CLIENT
      </Link>
    </div>
  );
};
