import React from 'react'
import Container from '../../../components/Admin/Container/Container'
import Add from '../../../components/Admin/AddCertificat/Add'

export default function AddCertificat() {
  return (
    <Container>
        <Add/>
    </Container>
  )
}
