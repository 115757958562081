import { Routes, Route } from 'react-router-dom';
import Login from '../pages/login/Login';
import Dashbord from '../pages/dashboard/Dashboard';
import Page404 from '../pages/404/Page404';
import ViewPDF from '../pages/viewPDF/ViewPDF';
import ViewDocPdf from '../pages/viewPDF/ViewDocPdf';
import Home from '../pages/home/Home';
import ContactPage from '../pages/Contact/ContactPage';
import Rapport from '../pages/Rapport/Rapport';
import AllDocs from '../pages/AllDocument/AllDocs';
import SignDocs from '../pages/SignDocument/SignDocs';
import WaitingDocs from '../pages/WaitingDocument/WaitingDocs';
import SignInAdmin from '../pages/Admin/SignInAdmin/SignInAdmin';
import AddUser from '../pages/Admin/AddUser/AddUser';
import AddCompany from '../pages/Admin/AddCompany/AddCompany';
import AddCertificat from '../pages/Admin/AddCertificat/AddCertificat';
import DashboardAdmin from '../pages/Admin/Dashboard/DashboardAdmin';
import PrivateRoute from './PrivateRoute';
import VerifyConnected from './VerifyConnected';
import Notification from '../pages/Admin/Notification/Notification';
import ViewSignDocs from '../pages/ViewSignDocs/ViewSignDocs';
import SignatairesViewDocument from '../pages/SignatairesViewDocument/SignatairesViewDocument';
import PreviewDoc from '../pages/SignatairesViewDocument/PreviewDoc';
import InProgressDocument from '../pages/InProgressDocument/InProgressDocument';
import NotSignedDocument from '../pages/NotSignedDocument/NotSignedDocument';
import LandingHome from '../pages/Landing/LandingHome';
import ResetAuthCode from '../pages/ResetAuthCode/ResetAuthCode';
import LandingProduct from '../pages/Landing/LandingProduct';
import LandingSolution from '../pages/Landing/LandingSolution';
import LandingTarif from '../pages/Landing/LandingTarif';
import LandingReference from '../pages/Landing/LandingReference';
import LandingAPropos from '../pages/Landing/LandingAPropos';
import LandingApi from '../pages/Landing/LandingApi';
import LandingToken from '../pages/Landing/LandingToken';
import LandingPlateform from '../pages/Landing/LandingPlateform';
import LoginManage from '../pages/Landing/Admin/LoginManage';
import Actualite from '../pages/Landing/Admin/Main/Actualite';
import Client from '../pages/Landing/Admin/Main/Client';
import Images from '../pages/Landing/Admin/Main/Images';
import Referencement from '../pages/Landing/Admin/Main/Referencement';
import Devis from '../pages/Landing/Admin/Main/Devis';
import Contact from '../pages/Landing/Admin/Main/Contact';
import PrivateManageRoute from './PrivateManageRoute';
import VerifyConnectedManage from './VerifyConnectedManage';
import Actuality from '../pages/Landing/Actuality';

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/previewdoc" element={<PreviewDoc />} />

      {/** Public route */}
      <Route
        exact
        path="/login"
        element={
          <VerifyConnected>
            <Login />
          </VerifyConnected>
        }
      />

      <Route exact path="/signataires" element={<SignatairesViewDocument />} />

      <Route exact path="/code_authentification" element={<ResetAuthCode />} />

      {/** User route */}
      <Route
        exact
        path="/home"
        element={
          <PrivateRoute authRoles={'user'}>
            <Home />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/alldocument"
        element={
          <PrivateRoute authRoles={'user'}>
            <Dashbord />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/notsigneddoc"
        element={
          <PrivateRoute authRoles={'user'}>
            <NotSignedDocument />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/inprogressdoc"
        element={
          <PrivateRoute authRoles={'user'}>
            <InProgressDocument />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/viewdocument"
        element={
          <PrivateRoute authRoles={'user'}>
            {window.innerWidth < 700 ? <ViewDocPdf /> : <ViewPDF />}
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/contact"
        element={
          <PrivateRoute authRoles={'user'}>
            <ContactPage />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/viewdocsign"
        element={
          <PrivateRoute authRoles={'user'}>
            <ViewSignDocs />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/rapport"
        element={
          <PrivateRoute authRoles={'user'}>
            <Rapport />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/allDocs"
        element={
          <PrivateRoute authRoles={'user'}>
            <AllDocs />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/signDocs"
        element={
          <PrivateRoute authRoles={'user'}>
            <SignDocs />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/waitingDocs"
        element={
          <PrivateRoute authRoles={'user'}>
            <WaitingDocs />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/signinadmin"
        element={
          <PrivateRoute authRoles={'user'}>
            <SignInAdmin />
          </PrivateRoute>
        }
      />

      {/** Admin route */}
      <Route
        exact
        path="/adduser"
        element={
          <PrivateRoute authRoles={'admin'}>
            <AddUser />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/addcompany"
        element={
          <PrivateRoute authRoles={'admin'}>
            <AddCompany />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/certificat"
        element={
          <PrivateRoute authRoles={'admin'}>
            <AddCertificat />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/dashboard"
        element={
          <PrivateRoute authRoles={'admin'}>
            <DashboardAdmin />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/notification"
        element={
          <PrivateRoute authRoles={'admin'}>
            <Notification />
          </PrivateRoute>
        }
      />

      {/** Page not found route */}
      <Route exact path="*" element={<Page404 />} />

      {/** Landing route */}
      <Route exact path="/" element={<LandingHome />} />
      <Route exact path="/home-product" element={<LandingProduct />} />
      <Route exact path="/home-solution" element={<LandingSolution />} />
      <Route exact path="/home-tarif" element={<LandingTarif />} />
      <Route exact path="/home-reference" element={<LandingReference />} />
      <Route exact path="/home-apropos" element={<LandingAPropos />} />
      <Route exact path="/home-api" element={<LandingApi />} />
      <Route exact path="/home-token" element={<LandingToken />} />
      <Route exact path="/home-plateform" element={<LandingPlateform />} />
      <Route exact path="/actuality" element={<Actuality />} />
      <Route exact path="/actuality/:id" element={<Actuality />} />

      <Route
        exact
        path="/home/admin"
        element={
          <VerifyConnectedManage>
            <LoginManage />
          </VerifyConnectedManage>
        }
      />

      <Route
        exact
        path="/home/admin/actualite"
        element={
          <PrivateManageRoute>
            <Actualite />
          </PrivateManageRoute>
        }
      />

      <Route
        exact
        path="/home/admin/client"
        element={
          <PrivateManageRoute>
            <Client />
          </PrivateManageRoute>
        }
      />

      <Route
        exact
        path="/home/admin/image"
        element={
          <PrivateManageRoute>
            <Images />
          </PrivateManageRoute>
        }
      />

      <Route
        exact
        path="/home/admin/devis"
        element={
          <PrivateManageRoute>
            <Devis />
          </PrivateManageRoute>
        }
      />

      <Route
        exact
        path="/home/admin/contact"
        element={
          <PrivateManageRoute>
            <Contact />
          </PrivateManageRoute>
        }
      />

      <Route
        exact
        path="/home/admin/referencement"
        element={
          <PrivateManageRoute>
            <Referencement />
          </PrivateManageRoute>
        }
      />
    </Routes>
  );
};

export default Routing;
