import React, { useEffect, useState } from 'react'
import Container from '../../../../components/AdminLanding/Container/Container'
import ClientTable from '../../../../components/AdminLanding/Client/ClientTable'
import { GetClient } from './services'

export default function Client() {

    const [data, setData] = useState(null)

    useEffect(() => {
        GetClient().then(source => setData(source))
    }, [])
    
    return (
        <Container>
            <ClientTable data={data} />
        </Container>
    )
}
