import React, { useState } from 'react';
import './MainSeulSignModal.css';

/**
 * Composant principal de la fenêtre modale seul signataire
 */
const ShowMotifModal = ({ motif }) => {
    return (
        <>
            <div className="modal fade" id="MotifModal" tabIndex="-1" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
                <div className="modal-dialog modal-dialog-centered mw-500px modal-xl">
                    <div className="modal-content w-100">
                        <div className="modal-body py-lg-10 px-lg-10">
                            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
                                <div className="flex-row-fluid py-lg-5 px-lg-15">
                                    <div className="btn btn-sm btn-icon btn-active-color-primary show-motif-close" data-bs-dismiss="modal"><span className="svg-icon svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect><rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect></svg></span></div>
                                    <form className="form" noValidate="novalidate" id="kt_modal_create_app_form">
                                        <div className={"current"} data-kt-stepper-element="content">
                                            Motif: {motif}
                                        </div>

                                        <div className="d-flex flex-stack pt-10">
                                            <div>
                        
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ShowMotifModal;