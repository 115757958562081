import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    currentDoc: ''
}

export const CurrentDocSlice = createSlice({
    name: 'currentdoc',
    initialState,
    reducers: {
        setCurrentDoc: (state, action) => { 
            state.currentDoc = action.payload 
        },
        clearDoc: () => initialState
    }
})

export const {
    setCurrentDoc,
    clearDoc
} = CurrentDocSlice.actions

export const CurrentDocSelector = state => state.currentdoc
