import React from "react";
import { Bar } from "react-chartjs-2";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

/**
 *
 * Component General Description:
 * This a custom bar chart for the dashboard page.
*/
function CustomBar({ data, options }) {
  return (
    <React.Fragment>
      {data?.labels?.length === 0 ? (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Typography variant="h5">Aucunes données trouvées</Typography>
        </div>
      ) : (
        <Bar data={data} options={options} />
      )}
    </React.Fragment>
  );
}

CustomBar.defaultProps = {
  labels: [],
  datasets: [],
};

export default CustomBar;
