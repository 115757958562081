import React, { useState } from 'react'
import { toast } from "react-toastify";
import { Formik, Form, ErrorMessage } from 'formik';
import ToolTip from './ToolTip'
import axios from 'axios'
import * as Yup from "yup";
import './modal.css'

export default function DemanderDevisModal({ showModal, setShowModal }) {
    const [initialValues] = useState({
        fullname: '',
        company: '',
        email: '',
        contact: '',
        activitysector: '',
        signvolume: '',
        description: ''
    })

    const FormSchema = Yup.object().shape({
        fullname: Yup.string()
            .required('Champs requis'),

        company: Yup.string()
            .required('Champs requis!'),

        email: Yup.string()
            .required('Champs requis!').email('Le format du mail est invalide'),

        contact: Yup.string()
            .required('Champs requis!'),

        description: Yup.string()
            .required('Champs requis!'),

        signvolume: Yup.string()
            .required('Champs requis!'),

        activitysector: Yup.string()
            .required('Champs requis!')
    });

    const sendData = (data) => {
        const { fullname, company, email, contact, activitysector, signvolume, description } = data;

        const formData = new FormData();
        formData.append('fullname', fullname);
        formData.append('company', company);
        formData.append('email', email);
        formData.append('contact', contact);
        formData.append('activitysector', activitysector);
        formData.append('signvolume', signvolume);
        formData.append('description', description);


        const url = `https://dkbsign.com/dkbsign_web_api/public/api/demandedevis`;

        axios.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log(response.data);
                toast.success("Formulaire envoyé veuillez consulter vos mails", {
                    position: "bottom-left",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

                setShowModal(false)
            })
            .catch(error => {
                console.error('Error posting data:', error);
            });
    };

    return (
        <div id="myModal" style={{ display: showModal ? 'block' : 'none' }} className="modal">
            <div className="modal-content-try">
                <h1 className='form-title'>Formulaire de demande de devis</h1>

                <Formik
                    initialValues={initialValues}

                    validationSchema={FormSchema}

                    onSubmit={async (values) => {

                        const dataSend = {
                            fullname: values.fullname,
                            company: values.company,
                            email: values.email,
                            contact: values.contact,
                            activitysector: values.activitysector,
                            signvolume: values.signvolume,
                            description: values.description
                        }

                        sendData(dataSend)
                    }}
                >

                    {({ handleChange, handleBlur, handleSubmit, values }) => (
                        <Form>
                            <div className="form-try">
                                <div className="row-try">
                                    <Input label={'Nom Complet: '} name={'fullname'} type={'text'} onChange={handleChange} value={values.fullname} onBlur={handleBlur} />
                                    <Input label={'Email: '} name={'email'} type={'text'} onChange={handleChange} value={values.email} onBlur={handleBlur} />
                                </div>
                                <div className="row-try">
                                    <Input label={'Nom de l\'entreprise: '} name={'company'} type={'text'} onChange={handleChange} value={values.company} onBlur={handleBlur} />
                                    <Input label={'Secteur d\'activite: '} name={'activitysector'} type={'text'} onChange={handleChange} value={values.activitysector} onBlur={handleBlur} />
                                </div>

                                <Input label={'Contact: '} name={'contact'} type={'text'} onChange={handleChange} value={values.contact} onBlur={handleBlur} />

                                <Select label={'Volume de signature: '} name={'signvolume'} type={'text'} onChange={handleChange} value={values.signvolume} onBlur={handleBlur} />

                                <TextArea label={'Description du besoin:'} name={'description'} onChange={handleChange} value={values.description} onBlur={handleBlur} />
                            </div>

                            <div className="form-button-container">
                                <button className='valid-btn-try' onClick={handleSubmit}>Envoyer le formulaire</button>
                                <button className='valid-btn-try-cancel' onClick={() => setShowModal(false)}>Fermer</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

const Input = ({ label, type, name, onChange, onBlur, value, min }) => {
    return (
        <div className="input-group-try">
            <label className="label-try">{label}</label>

            <input
                autoComplete="off"
                onBlur={onBlur}
                min={min}
                onChange={onChange}
                value={value}
                name={name}
                id={name}
                className="input-try"
                type={type}
            />
            <div />
            <ErrorMessage className="error-msg" name={name} component={'div'} />
        </div>
    )
}

const TextArea = ({ label, name, onChange, onBlur, value }) => {
    return (
        <div className="input-group-try">
            <label className="label-try">{label}</label>
            <textarea
                autoComplete="off"
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                id={name}
                className="input-try"
                rows={8}
                cols={22}
            />
            <div />
            <ErrorMessage className="error-msg" name={name} component={'div'} />
        </div>
    )
}

const Select = ({ label, type, name, onChange, onBlur, value, min }) => {
    return (
        <div className="input-group-try">
            <label className="label-try">{label} <ToolTip /></label>

            <select name={name} onChange={onChange} onBlur={onBlur} className="input-try">
                <option value="">Choisissez votre volume</option>
                <option value="pack_starter">Pack starter de (100 à 3000 signatures)</option>
                <option value="pack_pro">Pack pro (3001 à 10000 signatures)</option>
                <option value="pack">pack (10001 à 50000 signatures)</option>
                <option value="pack_premium">Pack premium plus (50001 à 100000 signatures)</option>
                <option value="pack_entreprise">Pack entreprise 100000 et plus</option>
            </select>

            <div />
            <ErrorMessage className="error-msg" name={name} component={'div'} />
        </div>
    )
}