import React from "react";
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

/**
 * 
 * Une courbe de variation Personnalisable
 * 
 * @param {data, options} param0 
 * @returns CustomLine
 */
export default function CustomLine({ data, options }) {
    return (
        <Line data={data} options={options} />
    );
}